import { createApi } from '@reduxjs/toolkit/query/react'
import createBaseQuery from '../../../utilities/createBaseQuery'
import {
    changePasswordResponseType,
    changePasswordRequestType,
    UpdateProfileImageResponseType,
    UpdateProfileImageRequestType,
    DeleteProfileImageResponseType,
    UserProfileResponseType,
    UpdateProfileResponseType,
    UpdateProfileRequestType,
    changeEmailResponseType,
    changeEmailRequestType,
    submitOtpRespondType,
    submitOtpRequestType
} from '../interfaces'

const profileChange = createApi({
    reducerPath: 'profileChange',
    baseQuery: createBaseQuery(),
    endpoints: (builder) => ({
        passwordChange: builder.mutation<
            changePasswordResponseType,
            changePasswordRequestType
        >({
            query: (body) => ({
                url: 'changePassword',
                payload: body,
                method: 'POST'
            })
        }),
        updateProfileImage: builder.mutation<
            UpdateProfileImageResponseType,
            UpdateProfileImageRequestType
        >({
            query: (data) => ({
                url: 'updateProfileImage',
                payload: data,
                method: 'POST'
            })
        }),
        deleteProfileImage: builder.mutation<
            DeleteProfileImageResponseType,
            void
        >({
            query: (data) => ({
                url: 'deleteProfileImage',
                payload: data,
                method: 'POST'
            })
        }),
        userProfile: builder.query<UserProfileResponseType, void>({
            query: (data) => ({
                url: 'userProfile',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.0000001
        }),
        updateProfile: builder.mutation<
            UpdateProfileResponseType,
            UpdateProfileRequestType
        >({
            query: (data) => ({
                url: 'updateProfile',
                payload: data,
                method: 'POST'
            })
        }),
        changeEmail: builder.mutation<
            changeEmailResponseType,
            changeEmailRequestType
        >({
            query: (data) => ({
                url: 'changeEmail',
                payload: data,
                method: 'POST'
            })
        }),
        deleteEmail: builder.mutation<DeleteProfileImageResponseType, void>({
            query: (data) => ({
                url: 'deleteEmail',
                payload: data,
                method: 'POST'
            })
        }),
        sendOtp: builder.mutation<DeleteProfileImageResponseType, void>({
            query: (data) => ({
                url: 'sendResendEmailChangeOTP',
                payload: data,
                method: 'POST'
            })
        }),
        submitOtp: builder.mutation<submitOtpRespondType, submitOtpRequestType>(
            {
                query: (data) => ({
                    url: 'verifyEmailChangeOtp',
                    payload: data,
                    method: 'POST'
                })
            }
        )
    })
})

export default profileChange
export const {
    usePasswordChangeMutation,
    useUpdateProfileImageMutation,
    useDeleteProfileImageMutation,
    useUserProfileQuery,
    useUpdateProfileMutation,
    useChangeEmailMutation,
    useDeleteEmailMutation,
    useSendOtpMutation,
    useSubmitOtpMutation
} = profileChange
