/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
import React, { SyntheticEvent, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    Wrap,
    WrapItem,
    Text,
    Box,
    Avatar,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Button,
    useDisclosure,
    Link as Anchor
} from '@chakra-ui/react'
import useAvailSw from '../../utilities/hooks/useAvailSw'
import { RootState, persistStorage } from '../../../store/store'
import { updateLogoutStatus } from '../../authentication/slices'
import { useUserProfileQuery } from '../../profile/queries/profileQuery'

const CssWrapper = styled.div`
    .nav-collapse .bottom-menu {
        visibility: hidden;
        display: none;
    }
    .logout_pop ul {
        display: block;
    }
    .logout_pop li {
        height: 38px;
        line-height: 38px;
        display: block;
    }
    .logout_pop li a {
        padding: 0 18px;
        font-size: 1.3rem;
        color: ;
        color: ${(props) =>
            props.color === 'true'
                ? 'white !important'
                : 'var(--chakra-colors-menuElipseDropdownFontColor) !important'};
        font-weight: 600;
        display: block;
    }
    .logout_pop li:hover a {
        background: ${(props) =>
            props.color === 'true'
                ? 'var(--chakra-colors-darkModeBoxBackgroundColor)'
                : 'var(--chakra-colors-lfgBlueGreyColor)'};
    }
    .logout_pop li:hover a {
        color: var(--chakra-colors-lfgMainBlue);
        text-decoration: none;
    }
    .nav-collapse li.nav-item a[aria-current='page'] {
        border-radius: 0px 8px 8px 0px;
    }
`

const AsideProfileMenu: React.FC = () => {
    const { REACT_APP_VERSION, REACT_APP_API_BASE_URL } = process.env
    const dispatch = useDispatch()
    const { SWIController } = useAvailSw()
    const [imgSrc, setImgSrc] = useState('')
    const [swICInstance, setSwICInstance] = useState<ServiceWorker | null>(null)
    const { isOpen, onToggle, onClose } = useDisclosure()
    const globalState = useSelector((state: RootState) => state.GlobalLoader)
    const navigate = useNavigate()
    const { userName, token, roleName } = useSelector(
        (state: RootState) => state.auth
    )
    const { data, isFetching } = useUserProfileQuery()

    useEffect(() => {
        if (SWIController !== null) {
            setSwICInstance(SWIController)
        }
    }, [SWIController])

    useEffect(() => {
        if (data && isFetching === false) {
            setImgSrc(
                data.response.raws.data.dataset.profile_image_path
                    ? `${
                          data.response.raws.data.dataset.profile_image_path
                      }?V=${Date.now()}`
                    : ''
            )
        }
    }, [data, isFetching])

    const handleLogout = (e: SyntheticEvent) => {
        e.preventDefault()
        onClose()
        const params = new FormData()
        params.append('token', `Bearer ${token}`)
        params.append('appversion', REACT_APP_VERSION as string)
        params.append(
            'time_zone',
            Intl.DateTimeFormat().resolvedOptions().timeZone
        )
        navigator.sendBeacon(`${REACT_APP_API_BASE_URL}logout`, params)
        setTimeout(() => {
            dispatch(updateLogoutStatus())
            persistStorage.flush().then(() => {
                persistStorage.purge().then(() => {
                    localStorage.clear()
                    sessionStorage.clear()
                    swICInstance?.postMessage({ type: 'loggedOut' })
                })
            })
        })
    }

    return (
        <CssWrapper color={globalState.darkMode.toString()}>
            <Popover placement='top-start' isOpen={isOpen} onClose={onClose}>
                <PopoverTrigger>
                    <Button
                        bg='transparent'
                        _hover={{ outline: 'none' }}
                        _focus={{ outline: 'none' }}
                        _active={{
                            outline: 'none',
                            bg: 'none'
                        }}
                        p='0'
                        sx={{
                            '& > *': {
                                textTransform: 'capitalize',
                                textAlign: 'left'
                            }
                        }}
                        onClick={onToggle}
                    >
                        <Box
                            display='flex'
                            flex-direction='row'
                            alignItems='center'
                            alignSelf='flex-end'
                        >
                            <Box>
                                <Avatar
                                    name={userName}
                                    src={imgSrc}
                                    alignSelf='center'
                                    borderRadius='100%'
                                    w={{ base: '35px', lg: '45px' }}
                                    h={{ base: '35px', lg: '45px' }}
                                    border='5px solid'
                                    borderColor='profileImageStrokeColor'
                                    transition='all 0.2s ease-in-out'
                                    _hover={{
                                        border: '2px solid',
                                        borderColor: 'profileImageStrokeColor'
                                    }}
                                    color='profileImageStrokeColor'
                                    fontWeight='600'
                                />
                                {/* <Image
                                    src='/images/admin-image.png'
                                    alt='admin image'
                                    alignSelf='center'
                                    borderRadius='100%'
                                    w='45px'
                                    h='45px'
                                    border='5px solid'
                                    borderColor='profileImageStrokeColor'
                                    transition='all 0.2s ease-in-out'
                                    _hover={{
                                        border: '2px solid',
                                        borderColor: 'profileImageStrokeColor'
                                    }}
                                /> */}
                            </Box>
                            <Box
                                display={{ base: 'none', md: 'block' }}
                                as='span'
                                fontSize='1.6rem'
                                p='10px'
                                lineHeight='1.3rem'
                                className='bottom-menu'
                                pos='absolute'
                                left='45px'
                                w='200px'
                                opacity='1'
                                transition='opacity 0.4s ease'
                                title={userName}
                            >
                                <Text
                                    as='strong'
                                    display='block'
                                    color='lfgMainBlue'
                                    fontWeight='700'
                                    fontSize='1.5rem'
                                    lineHeight='1.3rem'
                                    whiteSpace='nowrap'
                                    width='160px'
                                    height='15px'
                                    textOverflow='ellipsis'
                                >
                                    {userName}
                                </Text>
                                <Text
                                    as='span'
                                    color={
                                        !globalState.darkMode
                                            ? 'veryDarkBlueColor'
                                            : 'white'
                                    }
                                    fontSize='1rem'
                                    lineHeight='1.3rem'
                                    textTransform='capitalize'
                                    fontWeight='400'
                                >
                                    {roleName}
                                </Text>
                            </Box>
                        </Box>
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    bg={
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeBackgroundColor)'
                            : 'white'
                    }
                    mb='22px !important'
                    border={`1px solid ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeLightBackgroundColor) !important'
                            : 'var(--chakra-colors-topFilterSecBorderColor) !important'
                    }`}
                    boxShadow='0px 0px 8px var(--chakra-colors-boxShadowLightDeepBlackColor)'
                    borderRadius='5px'
                    _focus={{ outline: 'none' }}
                >
                    <PopoverBody p='0'>
                        <Wrap
                            className='logout_pop'
                            flexDirection='column'
                            display='block'
                            sx={{
                                '& > li > a': {
                                    padding: '0 18px',
                                    fontSize: '1.3rem',
                                    display: 'block',
                                    color: 'menuElipseDropdownFontColor',
                                    fontWeight: '600'
                                },
                                '& > li:hover > a': {
                                    bg: 'profileMenuHoverColor',
                                    textDecoration: 'none',
                                    color: 'lfgMainBlue'
                                }
                            }}
                        >
                            <WrapItem
                                height='38px'
                                lineHeight='3.8rem'
                                display='block'
                            >
                                <Link
                                    to='/profile'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        onClose()
                                        navigate('/profile')
                                    }}
                                >
                                    Profile
                                </Link>
                            </WrapItem>
                            <WrapItem
                                height='38px'
                                lineHeight='3.8rem'
                                display='block'
                            >
                                <Link
                                    to='/'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        onClose()
                                        navigate('/')
                                    }}
                                >
                                    Manage Notification
                                </Link>
                            </WrapItem>
                            <WrapItem
                                height='38px'
                                lineHeight='3.8rem'
                                display='block'
                            >
                                <Anchor onClick={handleLogout}>Logout</Anchor>
                            </WrapItem>
                        </Wrap>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </CssWrapper>
    )
}

export default AsideProfileMenu
