import { createApi } from '@reduxjs/toolkit/dist/query/react'
import createBaseQuery from '../../../utilities/createBaseQuery'
import {
    AddClientRequestType,
    AddClientResponseType,
    ClientListRequestType,
    ClientListResponseType,
    deleteClientDocumentRequestType,
    deleteClientDocumentResponseType,
    DeleteClientRequestType,
    DeleteClientResponseType,
    DownloadClientAuthRequestType,
    DownloadClientAuthResponseType,
    downloadClientDocumentRequestType,
    downloadClientDocumentResponseType,
    EditClientNoteRequestType,
    EditClientNoteResponseType,
    EditClientRequestType,
    EditClientResponseType,
    filterFirmsRequestType,
    filterFirmsResponseType,
    getClientDocumentsRequestType,
    getClientDocumentsResponseType,
    GetGeneratingClientAuthURLRequestType,
    GetGeneratingClientAuthURLResponseType,
    ViewClientRequestType,
    ViewClientResponseType,
    ToggleAuthStatusResponseType,
    ToggleAuthStatusRequestType
} from '../interfaces'

const clientsApi = createApi({
    reducerPath: 'clients',
    baseQuery: createBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: ['clientList', 'clientDocumentsList'],
    endpoints: (builder) => ({
        getClients: builder.query<
            ClientListResponseType,
            ClientListRequestType
        >({
            query: (data) => ({
                url: 'client/list',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.0001,
            providesTags: ['clientList']
        }),
        addClients: builder.mutation<
            AddClientResponseType,
            AddClientRequestType
        >({
            query: (data) => ({
                url: 'client/add',
                payload: data,
                method: 'POST'
            })
        }),
        viewClients: builder.query<
            ViewClientResponseType,
            ViewClientRequestType
        >({
            query: (data) => ({
                url: 'client/view',
                payload: data,
                method: 'POST'
            })
        }),
        editClients: builder.mutation<
            EditClientResponseType,
            EditClientRequestType
        >({
            query: (data) => ({
                url: 'client/edit',
                payload: data,
                method: 'POST'
            })
        }),
        deleteClients: builder.mutation<
            DeleteClientResponseType,
            DeleteClientRequestType
        >({
            query: (data) => ({
                url: 'client/delete',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['clientList']
        }),
        filterFirms: builder.query<
            filterFirmsResponseType,
            filterFirmsRequestType
        >({
            query: (data) => ({
                url: 'firm/filterFirm',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.0001
        }),
        getClientsDocuments: builder.query<
            getClientDocumentsResponseType,
            getClientDocumentsRequestType
        >({
            query: (data) => ({
                url: 'client/documentList',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.0001,
            providesTags: ['clientDocumentsList']
        }),
        deleteDocument: builder.mutation<
            deleteClientDocumentResponseType,
            deleteClientDocumentRequestType
        >({
            query: (data) => ({
                url: 'client/deleteDocument',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['clientDocumentsList']
        }),
        downloadDocument: builder.mutation<
            downloadClientDocumentResponseType,
            downloadClientDocumentRequestType
        >({
            query: (data) => ({
                url: 'client/downloadDocument',
                payload: data,
                method: 'POST'
            })
        }),
        editClientsNote: builder.mutation<
            EditClientNoteResponseType,
            EditClientNoteRequestType
        >({
            query: (data) => ({
                url: 'client/editNote',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['clientList']
        }),
        downloadClientAuth: builder.mutation<
            DownloadClientAuthResponseType,
            DownloadClientAuthRequestType
        >({
            query: (data) => ({
                url: 'client/downloadClientAuth',
                payload: data,
                method: 'POST'
            })
        }),
        getGeneratingClientAuthURL: builder.mutation<
            GetGeneratingClientAuthURLResponseType,
            GetGeneratingClientAuthURLRequestType
        >({
            query: (data) => ({
                url: 'client/getGeneratingClientAuthPresignedURL',
                payload: data,
                method: 'POST'
            })
        }),
        toggleAuthStatus: builder.mutation<
            ToggleAuthStatusResponseType,
            ToggleAuthStatusRequestType
        >({
            query: (data) => ({
                url: 'client/documentStatusChange',
                payload: data,
                method: 'POST'
            })
        })
    })
})

export default clientsApi
export const {
    useGetClientsQuery,
    useAddClientsMutation,
    useViewClientsQuery,
    useEditClientsMutation,
    useDeleteClientsMutation,
    useFilterFirmsQuery,
    useGetClientsDocumentsQuery,
    useDeleteDocumentMutation,
    useDownloadDocumentMutation,
    useEditClientsNoteMutation,
    useDownloadClientAuthMutation,
    useGetGeneratingClientAuthURLMutation,
    useToggleAuthStatusMutation
} = clientsApi
