import React, { useEffect, useState } from 'react'
import history from 'history/browser'
import { useLocation, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../store/store'
// import Storage from '../utilities/storage'

interface conditionProps {
    children: React.ReactElement
}

const ConditionalRouting = (props: conditionProps) => {
    const { children } = props
    const [redirect] = useState('/dashboard')
    const { userEmail, loggedIn } = useSelector(
        (state: RootState) => state.auth
    )

    // this is where we will put all the logical verifications and can set redirection accordingly
    const blackListedUrlsAfterLogin = [
        '/',
        '/login',
        '/forgot-password',
        '/verify-otp',
        '/reset-password'
    ]
    const activeUrlsIfUserIDNotPresent = [
        '/update-email',
        '/confirm-email',
        '/verify-email',
        '/change-password'
    ]

    const location = useLocation()
    const { referrer } =
        (location.state as {
            referrer: string
        }) || {}

    const [isLoggedIn, setIsLoggedIn] = useState(loggedIn)

    useEffect(() => {
        setIsLoggedIn(loggedIn)
    }, [])

    if (isLoggedIn) {
        if (!blackListedUrlsAfterLogin.includes(location.pathname)) {
            if (userEmail) {
                if (!activeUrlsIfUserIDNotPresent.includes(location.pathname)) {
                    return children
                }
                return (
                    <Navigate
                        to='/dashboard'
                        replace
                        state={{ from: location }}
                    />
                )
            }
            if (activeUrlsIfUserIDNotPresent.includes(location.pathname)) {
                if (location.pathname.includes('confirm-email')) {
                    if (
                        location.state &&
                        referrer &&
                        referrer.includes('update-email')
                    ) {
                        history.replace('/confirm-email', null)
                    } else {
                        return <Navigate to='/update-email' replace />
                    }
                } else if (location.pathname.includes('verify-email')) {
                    if (
                        location.state &&
                        referrer &&
                        referrer.includes('confirm-email')
                    ) {
                        history.replace('/verify-email', null)
                    } else {
                        return <Navigate to='/update-email' replace />
                    }
                } else if (location.pathname.includes('change-password')) {
                    if (
                        location.state &&
                        referrer &&
                        referrer.includes('verify-email')
                    ) {
                        history.replace('/change-password', null)
                    } else {
                        return <Navigate to='/update-email' replace />
                    }
                }
                return children
            }
            return (
                <Navigate
                    to='/update-email'
                    replace
                    state={{ from: location }}
                />
            )
        }
        return <Navigate to={redirect} replace state={{ from: location }} />
    }

    // if not logged in
    if (location.pathname.includes('reset-password')) {
        if (location.state && referrer && referrer.includes('verify-otp')) {
            history.replace('/reset-password', null)
        } else {
            return <Navigate to='/' replace />
        }
    }
    if (location.pathname.includes('verify-otp')) {
        if (
            location.state &&
            referrer &&
            referrer.includes('forgot-password')
        ) {
            history.replace('/verify-otp', null)
        } else {
            return <Navigate to='/' replace />
        }
    }
    return children
}

export default ConditionalRouting
