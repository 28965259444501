import React from 'react'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import { Global } from '@emotion/react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'

const GlobalCss = () => {
    const globalState = useSelector((state: RootState) => state.GlobalLoader)

    return (
        <Global
            styles={`
                html, body{
                    /* https://github.com/JakeSidSmith/react-fastclick */
                }
                html{
                    overflow-x: hidden;
                    overflow-y:scroll;
                    background:white;
                    scroll-behavior: smooth;
                    font-family: 'Montserrat', sans-serif;
                    user-select: none; 
                    -webkit-user-select: none;
                    scroll-behavior: smooth;
                }
                body, body.chakra-ui-light {
                    background:white;
                    scroll-behavior: smooth;
                    font-family: 'Montserrat', sans-serif;
                    margin-right:0 !important;
                    padding:0 !important;
                    -webkit-user-select: none; 
                    -ms-user-select: none; 
                    user-select: none; 
                }
                a,
                a:hover, button, button:hover, input[type="submit"],input[type="submit"]:hover] {
                    transition: all 0.4s ease-in-out;
                }
                a.chakra-link:focus {
                    box-shadow:none;
                }
                h1, h2, h3, h4, h5, h6 {
                    font-family: 'Montserrat', sans-serif;
                }
                .p-ripple.purple .p-ink {
                    background: var(--chakra-colors-rippleWhiteColor);
                }
                .chakra-form-control .chakra-form__error-message {
                    font-size: 1.2rem;
                    position: absolute;
                    left: 0;
                    margin-top: 2px;
                    line-height: normal;
                    font-weight: 600;
                    color:var(--chakra-colors-lfgMainRedColor);
                }
                input.chakra-input[aria-invalid=true], input.chakra-input[data-invalid], input.chakra-input.invalid-email {
                    border-color: var(--chakra-colors-lfgMainRedColor);
                    box-shadow: 0 0 0 1px var(--chakra-colors-lfgMainRedColor);
                }
                input.chakra-input, textarea, select.chakra-select {
                    font-size: 1.3rem;
                    color:var(--chakra-colors-inputplaceholderColor);
                    border: ${
                        globalState.darkMode
                            ? ' 1px solid var(--chakra-colors-darkModeBordercolor)'
                            : ' 1px solid var(--chakra-colors-inputStrokeColor)'
                    };
                    height:40px;      select.chakra-select 
                    line-height:40px;
                    font-weight:500;
                    font-family: 'Montserrat', sans-serif;
                }
                input.chakra-input:hover, textarea:hover, select.chakra-select:hover {
                    border-color: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-darkModeBordercolor) !important'
                            : 'var(--chakra-colors-inputStrokeColor)'
                    };
                }
                select.chakra-select {
                    background:url(/images/select_dropdown_arrow.svg) no-repeat right center !important;
                    background-position: right 10px center !important;
                    -webkit-appearance: none;
                    background-color:   ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeBackgroundColor)'
                            : 'white'
                    };
                }
                .p-autocomplete .p-autocomplete-dropdown {
                    cursor:default;
                }
                .p-autocomplete.load-complete .p-autocomplete-dropdown, .p-autocomplete .p-autocomplete-dropdown {
                    background:url(/images/select_dropdown_arrow.svg) no-repeat right center;
                    background-position: right 3px center;
                    border-radius:0 3px 3px 0;
                    position:absolute;
                    right:38px;
                }
                .p-autocomplete.load-complete .p-autocomplete-dropdown {
                    cursor:pointer;
                }
                .p-autocomplete.load-complete .p-autocomplete-dropdown:hover {
                    background:url(/images/select_dropdown_arrow.svg) no-repeat right center;
                    background-position: right 3px center;
                    color:transparent;
                }
                .p-autocomplete .p-autocomplete-dropdown:hover {
                    background:transparent;
                }
                .p-autocomplete.p-inputwrapper input {
                    flex: 0 0 85%;
                }
                .p-autocomplete.p-inputwrapper button {
                    flex: 0 0 30px;
                    align-self:flex-end;
                }
                .p-autocomplete.no-data-found .p-autocomplete-dropdown:after {
                    content:'!';
                    right: 0;
                    position:absolute;
                    background:var(--chakra-colors-lfgMainRedColor);
                    width:18px;
                    height:18px;
                    line-height:18px;
                    border-radius:100%;
                    color: white;
                    font-size:1.4rem;
                    font-weight:bold;
                    pointer-events:none;
                    z-index:100;
                }
                .p-autocomplete .p-autocomplete-loader, .p-autocomplete .p-autocomplete-dropdown >span:before {
                    display:none;
                }
                .chakra-select__wrapper .chakra-select__icon-wrapper {
                    display:none;
                }
                input.chakra-input:focus, textarea:focus, select.chakra-select:focus, .p-inputtext:enabled:focus, .p-autocomplete:focus, .p-autocomplete .p-autocomplete-dropdown:focus {
                    border:1px solid;
                    border-color: var(--chakra-colors-skyBlueColor);
                    box-shadow: 0 0 0 1px var(--chakra-colors-skyBlueColor);
                }
                textarea.chakra-textarea {
                    font-size: 1.3rem;
                    color:var(--chakra-colors-inputplaceholderColor);
                    border: ${
                        globalState.darkMode
                            ? ' 1px solid var(--chakra-colors-darkModeBordercolor)'
                            : ' 1px solid var(--chakra-colors-inputStrokeColor)'
                    };
                    font-weight:500;
                    box-shadow: ${
                        globalState.darkMode
                            ? 'none'
                            : '0px 2px 5px var(--chakra-colors-boxShadowLightBlueColor)'
                    }
                }
                button.chakra-button, button.login-button {
                    font-size: 1.5rem;
                    font-weight: 700;
                    text-transform:uppercase;
                }
                ::-webkit-input-placeholder { 
                    font-size: 1.3rem;
                    color:var(--chakra-colors-inputplaceholderColor);
                }
                :-ms-input-placeholder { 
                    font-size: 1.3rem;
                    color:var(--chakra-colors-inputplaceholderColor);
                }
                ::placeholder {
                    font-size: 1.3rem;
                    color:var(--chakra-colors-inputplaceholderColor);
                }
                button.chakra-button.save_btn {
                    background:var(--chakra-colors-lfgMainBlue);
                    height:40px;
                    line-height:40px;
                    border-radius:5px;
                    color:white;
                    padding:0 20px;
                    box-shadow: 0px 3px 4px var(--chakra-colors-boxShadowBlackColor);
                    border:1px solid var(--chakra-colors-skyBlueColor);
                }
                button.chakra-button.save_btn:hover {
                    background:var(--chakra-colors-lfgMainBlueHover);
                }
                button.chakra-button.btn_general {
                    background: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeLightBackgroundColor)'
                            : 'white'
                    };
                    height:40px;
                    line-height:40px;
                    border-radius:5px;
                    color:var(--chakra-colors-inputplaceholderColor);
                    padding:0 10px;
                    text-transform: capitalize;
                }
                button.chakra-button.btn_general.active{
                    background:${
                        globalState.darkMode
                            ? 'var(--chakra-colors-lfgMainLightBlue)'
                            : 'var(--chakra-colors-lfgMainBlue)'
                    };
                    color: white;
                }
                button.chakra-button.btn_general:hover {
                    background:var(--chakra-colors-lfgMainBlueHover);
                    color: white;
                }

                button.chakra-button.save_btn_sm {
                    background:var(--chakra-colors-lfgMainBlue);
                    height:28px;
                    line-height:19px;
                    border-radius:5px;
                    font-weight: 500;
                    font-size: 14px;
                    color:white;
                    padding: 10px 15px;
                    box-shadow: ${
                        globalState.darkMode
                            ? 'none'
                            : '0px 3px 4px var(--chakra-colors-boxShadowBlackColorSecondary)'
                    };
                    border:1px solid var(--chakra-colors-skyBlueColor);
                }
                button.chakra-button.save_btn_sm:hover {
                    background:var(--chakra-colors-lfgMainBlueHover);
                }

                button.chakra-button.upload_btn {
                    background:var(--chakra-colors-veryDarkBlueColor);
                    height:40px;
                    line-height:40px;
                    border-radius:5px;
                    color:white;
                    padding:0 20px;
                    box-shadow: ${
                        globalState.darkMode
                            ? 'none'
                            : '0px 3px 4px var(--chakra-colors-boxShadowBlackColorSecondary)'
                    };
                    border:1px solid var(--chakra-colors-skyBlueColor);
                    font-size:1.5rem;
                    font-weight:700;
                    padding:12px 18px;
                }
                button.chakra-button.upload_btn:hover {
                    background:var(--chakra-colors-lfgMainBlueHover);
                }
                button.chakra-button.upload_botton {
                    background: var(--chakra-colors-darkBlueColor);
                    height:40px;
                    line-height:40px;
                    border-radius:5px;
                    color: white;
                    padding:0 20px;
                    border: ${
                        globalState.darkMode
                            ? '1px solid var(--chakra-colors-darkModeBordercolor) !important'
                            : '1px solid var(--chakra-colors-cancelButtonStrokeColor) !important'
                    };
                    box-shadow: ${
                        globalState.darkMode
                            ? 'none'
                            : '0px 3px 4px var(--chakra-colors-boxShadowBlackColorSecondary)'
                    };
                }
                button.chakra-button.upload_botton:hover {
                    background: var(--chakra-colors-veryDarkBlueColor);
                }
                button.chakra-button.cancel_btn {
                    background:var(--chakra-colors-buttonDisableColor);
                    height:40px;
                    line-height:40px;
                    border-radius:5px;
                    color:var(--chakra-colors-bodyButtonFontColor);
                    padding:0 20px;
                    border:1px solid var(--chakra-colors-cancelButtonStrokeColor);
                    box-shadow: ${
                        globalState.darkMode
                            ? 'none'
                            : '0px 3px 4px var(--chakra-colors-boxShadowBlackColorSecondary)'
                    };
                }
                button.chakra-button.cancel_btn:hover {
                    background:var(--chakra-colors-cancelButtonHoverColor);
                }
                button.chakra-button.delete_btn {
                    background:var(--chakra-colors-lfgMainRedColor);
                    height:40px;
                    line-height:40px;
                    border-radius:5px;
                    color:white;
                    padding:0 20px;
                    border:1px solid var(--chakra-colors-lfgMainRedColor);
                    box-shadow: ${
                        globalState.darkMode
                            ? 'none'
                            : '0px 3px 4px var(--chakra-colors-boxShadowBlackColorSecondary)'
                    };
                }
                button.chakra-button.delete_btn:hover {
                    background:var(--chakra-colors-lfgMainRedColorHover);
                }
                button.chakra-button.disable_btn {
                    background:rgba(208, 208, 225, 1);
                    height:40px;
                    line-height:40px;
                    border-radius:5px;
                    color:var(--chakra-colors-bodyButtonFontColor);
                    padding:0 20px;
                    border:1px solid var(--chakra-colors-cancelButtonStrokeColor);
                    box-shadow: ${
                        globalState.darkMode
                            ? 'none'
                            : '0px 3px 4px var(--chakra-colors-boxShadowBlackColorSecondary)'
                    };
                }
                button.chakra-button.disable_btn:hover {
                    background:rgba(208, 208, 225, 1);
                }
                .p-checkbox .p-checkbox-box{
                    border: ${
                        globalState.darkMode
                            ? ' 2px solid var(--chakra-colors-darkModeBordercolor)'
                            : ' 2px solid var(--chakra-colors-inputStrokeColor)'
                    };
                    background: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeBackgroundColor)'
                            : 'white'
                    };
                }
                .p-checkbox .p-checkbox-box .p-checkbox-icon{
                    pointer-events: none;
                }
                .chakra-checkbox {
                    font-weight: 500;
                    font-size: 13px;
                    font-size:1.3rem;
                    color: var(--chakra-colors-lfgGrayColor);
                }
                .chakra-checkbox .chakra-checkbox__control {
                    width: 16px;
                    height: 16px;
                    border-radius:3px;
                }
                .chakra-checkbox .chakra-checkbox__control[data-checked] {
                    background:var(--chakra-colors-red) !important;
                    border-color:var(--chakra-colors-lfgMainBlue) !important;
                }
                .chakra-checkbox .chakra-checkbox__control[data-disabled] {
                    background:${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeLightBackgroundColor) !important'
                            : 'var(--chakra-colors-gray-100) !important'
                    };
                    border-color:${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeLightBackgroundColor) !important'
                            : 'var(--chakra-colors-gray-100) !important'
                    };
                }
                .chakra-checkbox__label {
                    padding-left:6px;
                    color: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-inputplaceholderColor)'
                            : 'var(--chakra-colors-menuElipseDropdownFontColor)'
                    };
                }
                .chakra-checkbox .chakra-checkbox__control:focus, .chakra-checkbox .chakra-checkbox__control[data-focus] {
                    box-shadow:0 0 0 1px var(--chakra-colors-skyBlueColor);
                    border-color: var(--chakra-colors-skyBlueColor);
                    outline:none;
                }
                .chakra-checkbox .chakra-checkbox__control[data-checked]:hover, .chakra-checkbox .chakra-checkbox__control[data-checked][data-hover] {
                    border:none;
                }
                input[type="file"].chakra-input {
                    padding:0;
                }
                .p-multiselect-item > span {
                    color: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-inputplaceholderColor)'
                            : 'var(--chakra-colors-menuElipseDropdownFontColor)'
                    };
                    font-size: 1.3rem;
                    font-weight: 500;
                    margin-left: 8px;
                font-family:'Montserrat', sans-serif;
                }
                .p-multiselect-panel{
                    width: 300px;
                }
                .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover{
                    background: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-darkModeBoxBackgroundColor)'
                            : '#e9ecef;'
                    };
                }
                .p-checkbox  {
                    width: 16px;
                    height:16px;
                }
                .p-checkbox .p-checkbox-box {
                    width: 16px;
                    height:16px;
                    border-radius:2px;
                }
                .p-checkbox .p-checkbox-box.p-highlight, .p-checkbox .p-checkbox-box.p-highlight:hover, .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
                    background:var(--chakra-colors-lfgMainBlue);
                    border-color:var(--chakra-colors-lfgMainBlue);
                }
                .p-checkbox .p-checkbox-box .p-checkbox-icon:before {
                    font-size:1rem;
                    line-height: 16px;
                    font-weight: bold;
                }
                .p-checkbox .p-checkbox-box:hover, .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus, .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
                    border-color:var(--chakra-colors-lfgMainBlue);
                }
                .p-multiselect-footer {
                    border-top:${
                        globalState.darkMode
                            ? ' 1px solid var(--chakra-colors-darkModeBordercolor)'
                            : ' 1px solid rgba(208, 208, 225, 1)'
                    };
                    padding:14px 0;
                    text-align:center;
                    background:${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeBackgroundColor)'
                            : 'white'
                    };
                }
                .p-multiselect-panel .p-multiselect-header {
                    background:${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeBackgroundColor)'
                            : 'white'
                    };
                    border:none;
                }
                .p-multiselect-panel .p-multiselect-items {
                    padding:0;
                }
                .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
                    height:35px;
                    margin:0 10px;
                    width:96%;
                    border-radius:5px;
                    border-color:${
                        globalState.darkMode
                            ? '(--chakra-colors-darkModeBordercolor)'
                            : '(--chakra-colors-profileMenuHoverColor)'
                    };
                    color:var(--chakra-colors-lfgGreycolor);
                    font-weight:400;
                    font-size:1.4rem;
                    font-family:'Montserrat', sans-serif;
                }
                .p-multiselect-close-icon{
                    color:var(--chakra-colors-iconFillColor);
                    font-size:1.3rem;
                    font-weight:bold;
                }
                .p-multiselect-filter-icon {
                    font-size:1.3rem;
                    color:var(--chakra-colors-iconFillColor);
                }
                .p-component-overlay {
                    background-color: ${
                        globalState.darkMode
                            ? '#6c6b7640 !important'
                            : 'var(--chakra-colors-boxShadowLightBlueColor)'
                    };
                }
                .p-autocomplete-items li {
                    font-size:1.3rem;
                    color:var(--chakra-colors-inputplaceholderColor);
                    font-family:'Montserrat', sans-serif;
                }
                .chakra-container button:focus {
                    outline:none;
                    box-shadow:none;
                }
                .p-datatable-wrapper{
                    padding-top:15px;
                }
                .p-datatable-wrapper thead::before {
                    content: "";
                    display: block;
                    width: calc(100% + 20px);
                    height: 62px;
                    background: white;
                    position: -webkit-sticky;
                    position: absolute;
                    top: -15px;
                    left: -5px;
                    right: 0;
                    z-index: -1;
                    mix-blend-mode: multiply;
                }
                .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead tr th:focus, .p-multiselect:not(.p-disabled).p-focus {
                    box-shadow:none;
                }
                .p-datascroller.p-datascroller-inline .p-datascroller-list > li {
                    border:none;
                    margin-bottom:10px;
                }
                .p-datatable.p-datatable-scrollable .p-datatable-tbody tr.p-datatable-emptymessage {
                    box-shadow:none;
                    border:none;
                    text-align:center;
                    align-items: center;
                    justify-content: center;
                }
                .p-datatable.p-datatable-scrollable .p-datatable-tbody tr.p-datatable-emptymessage td {
                    text-align:center;
                    align-items: center;
                    justify-content: center;
                }
                .p-multiselect .p-multiselect-label.p-placeholder {
                    color:var(--chakra-colors-inputplaceholderColor);
                }
                .p-inputtext {
                    font-size: 1.3rem;
                    color:var(--chakra-colors-inputplaceholderColor);
                    border: ${
                        globalState.darkMode
                            ? ' 1px solid var(--chakra-colors-darkModeBordercolor)'
                            : ' 1px solid var(--chakra-colors-inputStrokeColor)'
                    };
                    height:40px;
                    line-height:40px;
                    font-weight:500;
                    font-family: 'Montserrat', sans-serif;
                    width:45%;
                    border-radius:4px;
                    background:${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeBackgroundColor)'
                            : 'transparent'
                    };
                }
                .p-inputtext:enabled:hover {
                    border-color:${
                        globalState.darkMode
                            ? 'var(--chakra-colors-darkModeBordercolor)'
                            : 'var(--chakra-colors-gray-300)'
                    }
                }
                
                .p-multiselect-items-wrapper {
                    padding-right: 9px;
                    overflow-y: scroll;
                    background:${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeBackgroundColor)'
                            : 'white'
                    };
                }
                .p-multiselect-items-wrapper::-webkit-scrollbar {
                    width: 8px;
                    background: var(--chakra-colors-gray-200);
                }
                .p-multiselect-items-wrapper:hover::-webkit-scrollbar-thumb {
                    background: var(--chakra-colors-deepGreyColor);
                    border-radius: 4px;
                    transition: all 0.3s ease-in-out;
                }
                div.react-ripples {
                    padding:5px 0;
                }
                .p-tooltip.p-component .p-tooltip-text {
                    font-size:1.2rem;
                    font-family:'Montserrat', sans-serif;
                    font-weight:500;
                    line-height:1.8rem;
                }
                .p-datepicker{
                    color: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-inputplaceholderColor)'
                            : 'var(--chakra-colors-leftPanelFontColor)'
                    };
                }
                .p-datepicker-calendar-container .p-datepicker-calendar, .p-datepicker-header {
                    font-size:1.2rem;
                    font-family:'Montserrat', sans-serif;
                    font-weight:600;
                }
                .p-datepicker:not(.p-datepicker-inline){
                    background: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeDeepBackgroundColor)'
                            : 'white'
                    };
                }
                .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header{
                    background: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeDeepBackgroundColor)'
                            : 'white'
                    };
                }
                body div.p-autocomplete-panel.p-component.p-connected-overlay-enter-done{
                    z-index:2222 !important;
                }
                input.chakra-input:disabled {
                    background:${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeLightBackgroundColor)'
                            : 'var(--chakra-colors-inputDisabledColor)'
                    };
                    border-color:${
                        globalState.darkMode
                            ? 'var(--chakra-colors-darkModeBordercolor)'
                            : 'var(--chakra-colors-inputDisabledStrokeColor)'
                    };
                    opacity:1;
                    height: 40px;
                }
                .p-disabled, .p-component:disabled {
                    background: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeLightBackgroundColor)'
                            : 'var(--chakra-colors-inputDisabledColor)'
                    };
                    border-color: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-darkModeBordercolor)'
                            : 'var(--chakra-colors-inputDisabledStrokeColor)'
                    };
                    opacity: 1;
                    height: 40px;
                }
                select.chakra-select:disabled {
                    background: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeLightBackgroundColor) url(/images/select_dropdown_arrow.svg) no-repeat right 7px center !important'
                            : 'var(--chakra-colors-inputDisabledColor) url(/images/select_dropdown_arrow.svg) no-repeat right 7px center !important'
                    };
                    border-color: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-darkModeBordercolor)'
                            : 'var(--chakra-colors-inputDisabledStrokeColor)'
                    };
                    opacity:1;
                }
                .chakra-checkbox .chakra-checkbox__control[data-checked], .chakra-checkbox .chakra-checkbox__control[aria-checked=true] {
                    background:var(--chakra-colors-lfgMainBlue);
                    border-color:var(--chakra-colors-lfgMainBlue);
                    color: var(--chakra-colors-lfgMainBlue);
                }
                .p-datepicker .p-datepicker-header{
                    border-bottom: ${
                        globalState.darkMode
                            ? ' 1px solid var(--chakra-colors-darkModeBordercolor)'
                            : ' 1px solid #dee2e6'
                    };
                }
                .p-datepicker .p-datepicker-header .p-datepicker-title select {
                    padding:5px 30px 5px 8px;
                    font-weight:600;
                    -webkit-appearance:none;
                    color: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-inputplaceholderColor)'
                            : '#495057'
                    };
                    background: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeDeepBackgroundColor) url(/images/select_dropdown_arrow.svg) no-repeat right center;'
                            : 'white url(/images/select_dropdown_arrow.svg) no-repeat right center;'
                    }; 
                    background-position: right 10px center;
                }
                .p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
                    border-color: var(--chakra-colors-skyBlueColor);
                    box-shadow: 0 0 0 1px var(--chakra-colors-skyBlueColor);
                }
                .p-datepicker table td > span.p-highlight{
                    color:${
                        globalState.darkMode ? '#c1c1c1' : '#4338CA'
                    } !important;
                    background: ${
                        globalState.darkMode ? '#2a2a3c' : '#EEF2FF'
                    } !important;
                }
                .p-button:enabled:active, .p-button:not(button):not(a):not(.p-disabled):active, .p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
                    border-color:transparent;
                    color:var(--chakra-colors-iconFillColor);
                }
                a:focus-visible {
                    outline:none;
                }
                .p-autocomplete.load-complete .p-autocomplete-dropdown:focus-visible {
                    outline:none;
                }
                // .p-autocomplete .p-autocomplete-dropdown.p-disabled {
                //     background:#F3F3F3 url(/images/select_dropdown_arrow.svg) no-repeat right center;
                //     background-position: right 30px center;
                // }
                .p-autocomplete .p-autocomplete-dropdown:active {
                    box-shadow:none;
                }
                span.p-inputwrapper.p-calendar input {
                    height:40px;
                }
                .p-autocomplete.p-component {
                    background: ${
                        globalState.darkMode
                            ? 'url(/images/keyboard-icon-dark.svg) no-repeat right 10px center'
                            : 'url(/images/keyboard-icon-light.svg) no-repeat right 10px center'
                    }
                }
                button.chakra-button.request_active_btn {
                    background-color: rgba(255, 205, 41, 1);
                    padding: 0px;
                    height: 27px;
                    margin-left: 10px;
                    color: ${
                        globalState.darkMode ? 'white' : 'rgba(59, 69, 75, 1)'
                    };
                    border: 1px solid;
                    border-color: rgba(222, 172, 9, 1);
                    box-shadow: 0px 3px 4px var(--chakra-colors-boxShadowBlackColor);
                }
                button.chakra-button.request_active_btn:hover{
                    background-color: #F3F3F3;
                    background-color: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-buttonBgColor)'
                            : '#F3F3F3'
                    };
                    border-color: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-buttonBgColor)'
                            : '#BCC5D6'
                    };
                }
                button.chakra-button.request_active_btn:hover i{
                    color: var(--chakra-colors-lfgGrayColor);
                }
                button.chakra-button.request_active_btn span{
                    border: none;
                    color: rgba(59, 69, 75, 1);
                }
                button.chakra-button.request_active_btn i{
                    color: var(--chakra-colors-white);
                }
                button.chakra-button.request_active_btn:hover span{
                    color: var(--chakra-colors-lfgGrayColor);
                }

                button.chakra-button.request_inactive_btn{
                    background-color: ;
                    background-color: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-buttonBgColor)'
                            : '#F3F3F3'
                    };
                    padding: 0px;
                    margin-left: 10px;
                    color: var(--chakra-colors-white);
                    border: 1px solid;
                    height: 27px;
                    border-color: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-buttonBgColor)'
                            : '#BCC5D6'
                    };
                    box-shadow: 0px 3px 4px var(--chakra-colors-boxShadowBlackColor);
                }
                button.chakra-button.request_inactive_btn:hover{
                    background-color: rgba(255, 205, 41, 1);
                    border-color: rgba(222, 172, 9, 1);
                }
                button.chakra-button.request_inactive_btn:hover i{
                    color: var(--chakra-colors-white);
                }
                button.chakra-button.request_inactive_btn span{
                    border: none;
                    color: var(--chakra-colors-lfgGrayColor);
                }
                button.chakra-button.request_inactive_btn i{
                    color: var(--chakra-colors-lfgGrayColor);
                }
                button.chakra-button.request_inactive_btn:hover span{
                    color: rgba(59, 69, 75, 1);
                }
                button.chakra-button.request_active_btn:disabled, button.chakra-button.request_inactive_btn:disabled {
                    pointer-events:none;
                }
                button.chakra-button.request_notes_active{
                    background-color : var(--chakra-colors-lfgTicketBlue);
                    border-color :  var(--chakra-colors-lfgMainBlue);
                }
                button.chakra-button.request_notes_inactive{
                    background-color : var(--chakra-colors-deepGreyColor);
                    border-color :  var(--chakra-colors-darkBlueColor);
                }

                button.chakra-button.request_notes_active:hover{
                    background-color : var(--chakra-colors-lfgTicketBlueHover);
                }

                button.chakra-button.request_notes_inactive:hover{
                    background-color : var(--chakra-colors-lfgGrayBlueColor);
                }
                .chakra-menu__menu-list a span.chakra-menu__menuitem{
                    transition : 0.3s ease all;
                    padding: 10px 10px;
                    border-radius: 8px;
                }
                span.chakra-menu__menuitem{
                    transition : 0.3s ease all;
                    padding: 10px 15px;
                    border-radius: 8px;
                }
                span.chakra-menu__menuitem:hover{
                    padding: 10px 15px;
                    border-radius: 8px;
                    background-color: var(--chakra-colors-formHeadingBgColor);
                }
                input.p-inputmask{
                    line-height: 1.8rem;
                }
                .p-datatable.p-datatable-scrollable .p-datatable-tbody tr.p-datatable-emptymessage td{
                    text-transform: capitalize !important;
                    border: none;
                }
                .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message{
                    font-size: 1.5rem;
                    font-weight: 500;
                }
                .chakra-tabs__tablist{
                    border-color: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-darkModeBordercolor) !important'
                            : 'var(--chakra-colors-gray-200) !important'
                    };
                }
                .chakra-tabs__tab-panel .chakra-accordion__icon{
                    color: ${
                        globalState.darkMode
                            ? 'white'
                            : 'var(--chakra-colors-leftPanelFontColor)'
                    }
                }
                .chakra-accordion__item{
                    border-color: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-darkModeBordercolor) !important'
                            : 'var(--chakra-colors-gray-200) !important'
                    };
                }
                .chakra-tabs__tab-panel .chakra-accordion__item:first-of-type{
                    border-top-width: 0px !important;
                }
                .chakra-tabs__tab-panel .chakra-accordion__item .chakra-accordion__item{
                    border-bottom-width: 0px !important;
                }
                .chakra-tabs__tab-panel .chakra-accordion__item .chakra-accordion__item{
                    border-top-width: 0px !important;
                }
                .p-autocomplete-panel{
                    background: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeDeepBackgroundColor)'
                            : 'white'
                    }
                }
                .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item{
                    color: ${globalState.darkMode ? 'white' : ''}
                }
                .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover{
                    background: ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeBackgroundColor)'
                            : 'white'
                    };
                    color: ${globalState.darkMode ? 'white' : ''}
                }
                .chakra-switch__track[data-checked]{
                    background: ${
                        globalState.darkMode
                            ? 'black !important'
                            : 'var(--chakra-colors-lfgMainBlue) !important'
                    }
                input.p-inputmask {
                    line-height: 1.8rem;
                }
                    

                // media Query globally
                @media only screen and (max-width: 1023px) {
                    .chakra-container{
                        overflow: overlay;
                    }
                    button.chakra-button.save_btn {
                        font-size: 1.2rem;
                    }
                    button.chakra-button.disable_btn {
                        font-size: 1.2rem;
                    }
                    button.chakra-button.cancel_btn{
                        font-size: 1.2rem;
                    }
                    button.chakra-button.upload_botton{
                        font-size: 1.2rem;
                    }
                
                    button.chakra-button.delete_btn{
                        font-size: 1.2rem;
                    }
                    button.chakra-button.responsive_Btn{
                        height: 30px;
                        width: 30px;
                        padding: 0;
                    }
                    button.chakra-button.upload_btn{
                        width: 100%;
                    }

                }
                @media (min-width: 768px) and (max-width: 1023px) {
                    button.chakra-button.save_btn {
                        font-size: 1.5rem;
                    }
                    button.chakra-button.cancel_btn{
                        font-size: 1.5rem;
                    }
                    button.chakra-button.upload_botton{
                        font-size: 1.5rem;
                    }
                }
                @media (min-width: 980px) and (max-width: 1023px) {
                }


            `}
        />
    )
}

export default GlobalCss
