import { combineReducers } from '@reduxjs/toolkit'
// import HomeReducer from '../modules/dashboard/slices/index'
import GlobalLoader from '../modules/global/slices'
import userAuthentication from '../modules/authentication/queries/loginLogoutQuery'
import AuthSlice from '../modules/authentication/slices/index'
import casesApi from '../modules/cases/queries/casesQuery'
import clientsApi from '../modules/clients/queries/clientsQuery'
import legalfirmsApi from '../modules/legalfirms/queries/legalfirmsQuery'
import othersApi from '../modules/others/queries/othersQuery'
import copyservicesApi from '../modules/copyservices/queries/copyServicesQuery'
import providersApi from '../modules/providers/queries/providersQuery'
import LegalSlice from '../modules/legalfirms/slices'
import pageSlice from '../modules/legalfirms/slices/pdfSlices'
import ProviderSlice from '../modules/providers/slices'
import requestsApi from '../modules/requests/queries/requestsQuery'
import staffApi from '../modules/staff/query/staffListQuery'
import agentAuthApi from '../modules/agentAuth/queries'
import clientAuthApi from '../modules/clientAuth/queries'
import profileApi from '../modules/profile/queries/profileQuery'
import activityApi from '../modules/activity/queries/activityQuery'
import RequestSlice from '../modules/requests/slices'

// redux slices

const slices = {
    auth: AuthSlice.reducer,
    GlobalLoader: GlobalLoader.reducer,
    legal: LegalSlice.reducer,
    pageWiseData: pageSlice,
    provider: ProviderSlice.reducer,
    request: RequestSlice.reducer
}
const rtkQueries = {
    [userAuthentication.reducerPath]: userAuthentication.reducer,
    [casesApi.reducerPath]: casesApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [legalfirmsApi.reducerPath]: legalfirmsApi.reducer,
    [othersApi.reducerPath]: othersApi.reducer,
    [copyservicesApi.reducerPath]: copyservicesApi.reducer,
    [providersApi.reducerPath]: providersApi.reducer,
    [requestsApi.reducerPath]: requestsApi.reducer,
    [staffApi.reducerPath]: staffApi.reducer,
    [agentAuthApi.reducerPath]: agentAuthApi.reducer,
    [clientAuthApi.reducerPath]: clientAuthApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [activityApi.reducerPath]: activityApi.reducer
}

const combinedReducers = combineReducers({
    ...slices,
    ...rtkQueries
})

export default combinedReducers
export { rtkQueries }
