import { BaseQueryFn } from '@reduxjs/toolkit/query'
import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import Store from '../store/store'

interface ApiMemorySignature {
    [key: string]: {
        calledAt: number
        keys?: string[]
        values?: unknown[]
        response: { data: object }
    }
}

const BlackListed = [
    'login',
    'logout',
    'addFirm',
    'editFirm',
    'firm/agentAuthAdd',
    'firm/deleteAgentAuth',
    'firm/downloadAgentAuth',
    'firm/clientAuthAdd',
    'firm/deleteClientAuth',
    'firm/activateClientAuth',
    'firm/downloadClientAuth',
    'copy-service/add',
    'copy-service/edit',
    'payment-type/add',
    'payment-type/edit',
    'charge-type/add',
    'charge-type/edit',
    'charge-status/add',
    'charge-status/edit',
    'firm/addFirm',
    'firm/editFirm',
    'firm/addStaff',
    'firm/editStaff',
    'firm/addEditConfiguration',
    'firm/addLocation',
    'firm/editLocation',
    'firm/deleteLocation',
    'firm/setDefaultLocation',
    'client/add',
    'client/edit',
    'client/editNote',
    'client/delete',
    'provider/delete',
    'provider/editNote',
    'provider/add',
    'provider/edit',
    'provider/addParentRelationship',
    'provider/editParentRelationship',
    'provider/editRelationshipNote',
    'provider/addChildRelationship',
    'provider/editChildRelationship',
    'firm/locationList',
    'firm/agentAuthList',
    'firm/clientAuthList',
    'client/list',
    'provider/list',
    'firm/filterFirm',
    'case/filterClient',
    'case/createCaseRecordLink',
    'case/viewCaseRecordLink',
    'request/createDocumentRecordLink',
    'request/createRecordLink',
    'request/viewRecordLink',
    'request/removeLink',
    'case/removeCaseLink',
    'resetPassword',
    'sendOTP',
    'otpVerification',
    'sendResendOTP',
    'verifyOtp',
    'request/viewDocumentRecordLink'
]

const ApiMemory = {} as ApiMemorySignature

const createBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = {
            baseUrl: process.env.REACT_APP_API_BASE_URL || ''
        }
    ): BaseQueryFn<
        {
            url?: string
            method?: AxiosRequestConfig['method']
            payload?: AxiosRequestConfig['data']
        },
        unknown,
        unknown,
        object,
        object
    > =>
    async ({ url = '/', method = 'POST', payload }, { signal }) => {
        const { REACT_APP_VERSION } = process.env
        // console.log(
        //     BlackListed.includes(url) === false,
        //     ApiMemory,
        //     ApiMemory[url],
        //     url,
        //     payload,
        //     'ApiMemory0'
        // )

        if (BlackListed.includes(url) === false) {
            // console.log(
            //     Object.keys(ApiMemory).includes(url),
            //     ApiMemory,
            //     ApiMemory[url],
            //     url,
            //     'ONgoing-1111'
            // )
            if (Object.keys(ApiMemory).includes(url)) {
                const { calledAt, keys, values } = ApiMemory[url]
                const currentTime = Date.now()
                // console.log(
                //     currentTime - calledAt <
                //         parseInt(
                //             process.env.REACT_APP_API_DEBOUNCE_TIME || '0',
                //             10
                //         ),
                //     'Debounce1'
                // )
                if (
                    currentTime - calledAt <
                    parseInt(process.env.REACT_APP_API_DEBOUNCE_TIME || '0', 10)
                ) {
                    ApiMemory[url].calledAt = Date.now()
                    // console.log(payload, 'Debounce2')
                    if (payload) {
                        const currentKeys = Object.keys(payload)
                        const currentValues = Object.values(payload)
                        // console.log(
                        //     keys &&
                        //         values &&
                        //         keys.length === currentKeys.length &&
                        //         currentKeys.sort().join('-') ===
                        //             keys.sort().join('-') &&
                        //         currentValues.sort().join('-') ===
                        //             values.sort().join('-'),
                        //     'Debounce3'
                        // )

                        if (
                            keys &&
                            values &&
                            keys.length === currentKeys.length &&
                            currentKeys.sort().join('-') ===
                                keys.sort().join('-') &&
                            currentValues.sort().join('-') ===
                                values.sort().join('-')
                        ) {
                            // console.log(ApiMemory[url].response, 'Debounce4')
                            return ApiMemory[url].response
                            // return Promise.reject()
                        }
                    } else {
                        return ApiMemory[url].response
                    }
                }
                ApiMemory[url].calledAt = Date.now()
            }
        }
        if (!ApiMemory[url]) {
            ApiMemory[url] = {
                calledAt: Date.now(),
                response: { data: { response: { raws: {} } } }
            }
        }
        // console.log('after-return!!!', url)
        if (payload) {
            ApiMemory[url].keys = Object.keys(payload)
            ApiMemory[url].values = Object.values(payload)
        }
        // console.log(ApiMemory, ApiMemory[url], url, 'ApiMemory1')

        const {
            auth: { token, staffFirmId, staffLocationId }
        } = Store.getState()
        // console.log(Store.getState(), '4444')
        try {
            const result = await axios({
                url: baseUrl + url,
                method,
                data: {
                    ...payload,
                    staff_firm_id: staffFirmId,
                    staff_location_id: staffLocationId,
                    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
                },
                signal,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    authorization: `Bearer ${token}`,
                    appversion: REACT_APP_VERSION as string
                }
            })

            if (BlackListed.includes(url) === false) {
                ApiMemory[url].response = { data: result.data }
                // console.log(
                //     ApiMemory,
                //     ApiMemory[url],
                //     url,
                //     payload,
                //     'ApiMemory21111'
                // )
            }
            // console.log(result.data, 'DS1')
            return { data: result.data }
        } catch (axiosError) {
            // console.log('ERRORING', axiosError)
            const err = axiosError as AxiosError
            // console.log(err.response?.status, err.response?.data, 'ERR1')
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data
                }
            }
        }
    }
export default createBaseQuery
