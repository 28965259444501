/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    configureStore,
    ThunkAction,
    Action,
    AnyAction,
    isAsyncThunkAction
} from '@reduxjs/toolkit'

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt'

import storage from 'redux-persist/lib/storage'

import combinedReducers, { rtkQueries } from './combinedReducer'
import ignoredActions, {
    ignoredActionPaths,
    ignoredPaths
} from './ignoredSeriazations'
import userAuthentication from '../modules/authentication/queries/loginLogoutQuery'
import casesApi from '../modules/cases/queries/casesQuery'
import clientsApi from '../modules/clients/queries/clientsQuery'
import legalfirmsApi from '../modules/legalfirms/queries/legalfirmsQuery'
import othersApi from '../modules/others/queries/othersQuery'
import copyservicesApi from '../modules/copyservices/queries/copyServicesQuery'
import providersApi from '../modules/providers/queries/providersQuery'
import requestsApi from '../modules/requests/queries/requestsQuery'
import staffApi from '../modules/staff/query/staffListQuery'
import agentAuthApi from '../modules/agentAuth/queries'
import clientAuthApi from '../modules/clientAuth/queries'
import profileApi from '../modules/profile/queries/profileQuery'
import { setGlobalLoader } from '../modules/global/slices'
import activityApi from '../modules/activity/queries/activityQuery'
import { updateAfterResponse } from '../modules/authentication/slices'

const { REACT_APP_ENABLE_ENCRYPTION, REACT_APP_ENCRYPTION_SALT } = process.env

// middlewares
const CostOfRedux = (store: any) => (next: any) => (action: AnyAction) => {
    if (process.env.NODE_ENV === 'development') {
        const size = new TextEncoder().encode(
            JSON.stringify(store.getState())
        ).length
        const kiloBytes = size / 1024
        // const megaBytes = kiloBytes / 1024
        // eslint-disable-next-line no-console
        console.log(
            `%c The cost of stored data in redux store at this moment is ${kiloBytes} kb.`,
            'color: #16782f'
        )
    }
    return next(action)
}

const queryCatcher =
    (store: any) =>
    (next: any) =>
    (action: {
        type: string
        payload?: {
            status: number
            data: any
        }
        meta: {
            requestStatus: string
            arg: {
                endpointName: string
                type: string
            }
        }
    }) => {
        interface apiCallsInterface {
            [key: string]: string
        }
        if (!store.apiCalls) {
            store.apiCalls = {}
        }

        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
        if (store.newPath !== window.location.pathname) {
            store.newPath = window.location.pathname
            store.apiCalls = {}
            store.dispatch(setGlobalLoader(false))
        }

        if (isAsyncThunkAction(action)) {
            // store.newPath = window.location.pathname

            /**
             * Global 400 handling for Queries
             */
            if (action.meta.arg.type === 'query') {
                if (action.payload && action.payload.status === 400) {
                    store.dispatch(
                        updateAfterResponse({
                            value: true,
                            message:
                                action.payload.data.response.raws.error_message
                        })
                    )
                } else {
                    store.dispatch(
                        updateAfterResponse({
                            value: false,
                            message: ''
                        })
                    )
                }
            }
            store.dispatch(
                updateAfterResponse({
                    value: false,
                    message: ''
                })
            )

            if (store.oldPath !== store.newPath) {
                store.dispatch(setGlobalLoader(true))
                if (
                    action.meta.requestStatus === 'pending' ||
                    action.meta.requestStatus === 'rejected' ||
                    action.meta.requestStatus === 'fulfilled'
                ) {
                    store.apiCalls[action.meta.arg.endpointName] =
                        action.meta.requestStatus
                }
                if (store.timeout) {
                    clearTimeout(store.timeout)
                }
                store.timeout = setTimeout(() => {
                    if (
                        !Object.values(store.apiCalls).includes(
                            'pending' || 'rejected'
                        )
                    ) {
                        store.dispatch(setGlobalLoader(false))
                        store.apiCalls = {}
                        store.oldPath = window.location.pathname
                    }
                }, 1000)
            }
        }

        return next(action)
    }

const persistConfigEncrypted = {
    key: 'root',
    storage,
    blacklist: [...Object.keys(rtkQueries)], // like ... pokemonApi.reducerPath
    transforms: [
        encryptTransform({
            secretKey: REACT_APP_ENCRYPTION_SALT as string,
            onError(error) {
                console.log(error)
                // Handle the error.
            }
        })
    ]
}

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [...Object.keys(rtkQueries)] // like ... pokemonApi.reducerPath
}
let persistCombinedReducer
if (REACT_APP_ENABLE_ENCRYPTION === 'true') {
    persistCombinedReducer = persistReducer(
        persistConfigEncrypted,
        combinedReducers
    )
} else {
    persistCombinedReducer = persistReducer(persistConfig, combinedReducers)
}

const Store = configureStore({
    reducer: persistCombinedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: [
                    ...ignoredActions,
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER
                ],
                // Ignore these field paths in all actions
                ignoredActionPaths,
                // Ignore these paths in the state
                ignoredPaths
            }
        }).concat([
            queryCatcher,
            CostOfRedux,
            userAuthentication.middleware,
            casesApi.middleware,
            clientsApi.middleware,
            legalfirmsApi.middleware,
            othersApi.middleware,
            copyservicesApi.middleware,
            providersApi.middleware,
            requestsApi.middleware,
            staffApi.middleware,
            agentAuthApi.middleware,
            clientAuthApi.middleware,
            profileApi.middleware,
            activityApi.middleware
        ])
})

const persistStorage = persistStore(Store)

export default Store
export { persistStorage }
export type AppDispatch = typeof Store.dispatch
export type RootState = ReturnType<typeof Store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action
>
