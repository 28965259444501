import { createSlice } from '@reduxjs/toolkit'
import { updateLogoutStatus } from '../../authentication/slices'

const initialState = {
    configuration: false
}

const LegalSlice = createSlice({
    name: 'legalReducer',
    initialState,
    reducers: {
        updateConfiguration: (state, action) => {
            state.configuration = action.payload.isConfiguration
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateLogoutStatus, () => initialState)
    }
})

export const { updateConfiguration } = LegalSlice.actions
export default LegalSlice
