import React, { FC } from 'react'
import { Button } from '@chakra-ui/react'
import BeatLoader from 'react-spinners/BeatLoader'
import { ButtonLoaderProps } from '../interfaces'

const ButtonLoader: FC<ButtonLoaderProps> = ({
    isLoading,
    onClickFnc,
    disabled,
    children,
    buttonCLass
}) => (
    <Button
        isLoading={isLoading}
        spinner={<BeatLoader size={8} color='white' />}
        disabled={disabled}
        type='submit'
        onClick={onClickFnc}
        className={buttonCLass}
    >
        {children}
    </Button>
)

export default ButtonLoader
