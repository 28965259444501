import { createApi } from '@reduxjs/toolkit/dist/query/react'
import createBaseQuery from '../../../utilities/createBaseQuery'
import {
    AddCopyServiceRequestType,
    AddCopyServiceResponseType,
    CopyServiceEditRequestType,
    CopyServiceEditResponseType,
    CopyServicesRequestType,
    CopyServicesResponseType,
    GetFilterStaffRequestType,
    GetFilterStaffResponseType,
    ViewCopyServiceRequestType,
    ViewCopyServiceResponseType
} from '../interfaces'

const copyservicesApi = createApi({
    reducerPath: 'copyservices',
    baseQuery: createBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: ['copyServicesList'],
    endpoints: (builder) => ({
        getCopyServicesList: builder.query<
            CopyServicesResponseType,
            CopyServicesRequestType
        >({
            query: (data) => ({
                url: 'copy-service/list',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['copyServicesList']
        }),
        addCopyService: builder.mutation<
            AddCopyServiceResponseType,
            AddCopyServiceRequestType
        >({
            query: (data) => ({
                url: 'copy-service/add',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['copyServicesList']
        }),
        editCopyService: builder.mutation<
            CopyServiceEditResponseType,
            CopyServiceEditRequestType
        >({
            query: (data) => ({
                url: 'copy-service/edit',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['copyServicesList']
        }),
        viewCopyService: builder.query<
            ViewCopyServiceResponseType,
            ViewCopyServiceRequestType
        >({
            query: (data) => ({
                url: 'copy-service/view',
                payload: data,
                method: 'POST'
            })
        }),
        getFilterStaff: builder.query<
            GetFilterStaffResponseType,
            GetFilterStaffRequestType
        >({
            query: (data) => ({
                url: 'copy-service/filterStaff',
                payload: data,
                method: 'POST'
            })
        })
    })
})

export default copyservicesApi
export const {
    useGetCopyServicesListQuery,
    useAddCopyServiceMutation,
    useEditCopyServiceMutation,
    useViewCopyServiceQuery,
    useGetFilterStaffQuery
} = copyservicesApi
