import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setGloalBreadcrumb } from '../slices'

interface moduleSignature {
    module: string | undefined
    action: string | undefined
    id: string | undefined
}

interface additionalModuleSignature {
    subModule: string | undefined
    subAction: string | undefined
    subId: string | undefined
}
interface navigationSignature {
    name: string
    link: string
}

const useBreadcrumbSegments = () => {
    const { pathname, state } = useLocation()
    const { action, id, subModule, subAction, subId } = useParams()
    const params = useParams()
    const [mainModule, setMainModule] = useState(() => ({} as moduleSignature))
    const [additionalModule, setAdditionalModule] = useState(
        () => ({} as additionalModuleSignature)
    )

    const [navigations, setNavigation] = useState<navigationSignature[]>(
        () => []
    )

    const dispatch = useDispatch()

    useEffect(() => {
        const chkMainSub = new Set([action, id, subModule, subAction, subId])

        const checkOnlyModule = pathname.split('/')
        const [, consideredModule] = checkOnlyModule
        let consideredAction = action !== undefined ? action : ''
        if (
            chkMainSub.size === 1 &&
            chkMainSub.has(undefined) &&
            checkOnlyModule.length === 3
        ) {
            ;[, , consideredAction] = checkOnlyModule
        }

        setMainModule({
            module: consideredModule,
            action: consideredAction,
            id
        })
        setAdditionalModule({
            subModule,
            subAction,
            subId
        })
    }, [pathname, params, action, id, subModule, subAction, subId])

    useEffect(() => {
        const chkMainSub = new Set([action, id, subModule, subAction, subId])
        const checkOnlyModule =
            chkMainSub.size === 1 && chkMainSub.has(undefined)

        const tempNav: navigationSignature[] = []

        if (checkOnlyModule === false) {
            const mainLink = `${mainModule.module}`

            if (additionalModule.subModule) {
                if (mainModule.action) {
                    if (mainModule.id) {
                        if (additionalModule.subAction) {
                            tempNav.push(
                                {
                                    name: mainModule.module as string,
                                    link: mainLink
                                },
                                {
                                    name: `${subModule}`,
                                    link: `${mainModule.module}/${subModule}/${mainModule.action}/${mainModule.id}`
                                }
                            )
                        } else {
                            tempNav.push(
                                {
                                    name: mainModule.module as string,
                                    link: mainLink
                                },
                                {
                                    name: `${mainModule.action}-${subModule}`,
                                    link: `${mainModule.module}/${subModule}/${mainModule.action}/${mainModule.id}`
                                }
                            )
                        }
                    } else {
                        tempNav.push(
                            {
                                name: mainModule.module as string,
                                link: mainLink
                            },
                            {
                                name: `${subModule}-${mainModule.action}`,
                                link: `${mainModule.module}/${mainModule.action}`
                            }
                        )
                    }
                } else if (subModule) {
                    tempNav.push({
                        name: `${subModule}`,
                        link: `${mainModule.module}/${subModule}`
                    })
                } else {
                    tempNav.push({
                        name: mainModule.module as string,
                        link: mainLink
                    })
                }
            }

            if (additionalModule.subAction) {
                if (additionalModule.subId) {
                    tempNav.push({
                        name: subAction as string,
                        link: `${mainLink}/${additionalModule.subModule}/${action}/${id}/${subAction}/${subId}`
                    })
                } else if (subAction) {
                    tempNav.push({
                        name: subAction as string,
                        link: `${mainLink}/${additionalModule.subModule}/${action}/${id}/${subAction}`
                    })
                }
            }
            if (!additionalModule.subAction && !additionalModule.subModule) {
                if (mainModule.action) {
                    if (state) {
                        tempNav.push(
                            {
                                name: mainModule.module as string,
                                link: ''
                            },
                            {
                                name: mainModule.action,
                                link: ''
                            }
                        )
                    } else {
                        tempNav.push(
                            {
                                name: mainModule.module as string,
                                link: `/${mainModule.module}`
                            },
                            {
                                name: mainModule.action,
                                link: `${mainModule.module}/${mainModule.action}/${mainModule.id}`
                            }
                        )
                    }
                } else {
                    tempNav.push({
                        name: mainModule.module as string,
                        link: `/${mainModule.module}`
                    })
                }
            }
        } else if (mainModule.action) {
            tempNav.push(
                {
                    name: mainModule.module as string,
                    link: `/${mainModule.module}`
                },
                {
                    name: mainModule.action,
                    link: `${mainModule.module}/${mainModule.action}`
                }
            )
        } else {
            tempNav.push({
                name: mainModule.module as string,
                link: `/${mainModule.module}`
            })
        }
        setNavigation(tempNav)
    }, [mainModule, additionalModule, subModule, action, id, subAction, subId])

    useEffect(() => {
        dispatch(setGloalBreadcrumb(navigations))
    }, [navigations, dispatch])

    return navigations
}

export default useBreadcrumbSegments
