// import generateApi from '../../global/slices/index'

import { createSlice } from '@reduxjs/toolkit'
import {
    authReducerSignature,
    UpdateTokenTimeoutPayloadType
} from '../interfaces/index'

const initialState: authReducerSignature = {
    loggedIn: false,
    profileImage: '',
    staffFirmId: null,
    staffLocationId: null,
    token: '',
    userId: '',
    userName: '',
    roleName: '',
    roleId: 0,
    legalFirmName: '',
    refreshDuration: 0,
    logoutTime: 0,
    isTokenRefresh: Date.now(),
    initializationTime: 0,
    isQueryError: false,
    errorMessageFromQuery: '',
    proposedEmail: '',
    userEmail: '',
    isEmailVerified: false,
    lastUpdatedEmailValue: '',
    brodCastFlagVal: Date.now()
}

const AuthSlice = createSlice({
    name: 'authReducer',
    initialState,
    reducers: {
        updateLoginStatus: (
            state: authReducerSignature,
            action: { payload: authReducerSignature }
        ) => {
            if (action.payload.token && action.payload.userId) {
                state.loggedIn = action.payload.loggedIn
                state.profileImage = action.payload.profileImage
                state.staffFirmId = action.payload.staffFirmId
                state.staffLocationId = action.payload.staffLocationId
                state.token = action.payload.token
                state.userId = action.payload.userId
                state.userName = action.payload.userName
                state.roleName = action.payload.roleName
                state.legalFirmName = action.payload.legalFirmName
                state.refreshDuration = action.payload.refreshDuration
                state.proposedEmail = action.payload.proposedEmail
                state.userEmail = action.payload.userEmail
                state.isEmailVerified = action.payload.isEmailVerified
                state.lastUpdatedEmailValue =
                    action.payload.lastUpdatedEmailValue
                state.roleId = action.payload.roleId
            } else if (action.payload.token && !action.payload.userId) {
                state.loggedIn = action.payload.loggedIn
                state.token = action.payload.token
            }
        },
        updateLogoutStatus: (state: authReducerSignature) => {
            state.loggedIn = initialState.loggedIn
            state.userName = initialState.userName
            state.roleName = initialState.roleName
            state.userId = initialState.userId
            state.profileImage = initialState.profileImage
            state.token = initialState.token
            state.staffFirmId = initialState.staffFirmId
            state.staffLocationId = initialState.staffLocationId
            state.legalFirmName = initialState.legalFirmName
            state.refreshDuration = initialState.refreshDuration
            state.logoutTime = initialState.logoutTime
            state.isTokenRefresh = initialState.isTokenRefresh
            state.initializationTime = initialState.initializationTime
            state.isQueryError = initialState.isQueryError
            state.errorMessageFromQuery = initialState.errorMessageFromQuery
            state.proposedEmail = initialState.proposedEmail
            state.userEmail = initialState.userEmail
            state.isEmailVerified = initialState.isEmailVerified
            state.lastUpdatedEmailValue = initialState.lastUpdatedEmailValue
            state.roleId = initialState.roleId
        },
        updateTokenTimeout: (
            state: authReducerSignature,
            action: { payload: UpdateTokenTimeoutPayloadType }
        ) => {
            state.logoutTime = action.payload.logoutTime
            state.isTokenRefresh = action.payload.isTokenRefresh
            state.initializationTime = action.payload.initializationTime
            state.brodCastFlagVal = action.payload.brodCastFlagVal
        },
        updateAfterResponse: (
            state: authReducerSignature,
            action: { payload: { value: boolean; message: string } }
        ) => {
            state.isQueryError = action.payload.value
            state.errorMessageFromQuery = action.payload.message
        }
    }
})

export const {
    updateLoginStatus,
    updateLogoutStatus,
    updateTokenTimeout,
    updateAfterResponse
} = AuthSlice.actions
export default AuthSlice
