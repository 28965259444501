// Ignore these action types
const ignoredActions = ['']
// Ignore these field paths in all actions

const ignoredActionPaths = [
    // 'meta.arg.originalArgs.client_authorization_template_file_name'
    ''
]
// Ignore these paths in the state
const ignoredPaths = ['']

export default ignoredActions
export { ignoredActionPaths, ignoredPaths }
