import { extendTheme } from '@chakra-ui/react'

// Checkout the explanation and code sample in https://chakra-ui.com/docs/theming/customize-theme

const ExtendedTheme = extendTheme({
    // style object for base or default style
    baseStyle: {
        fonts: {
            heading: '"Montserrat", sans-serif',
            body: '"Montserrat", sans-serif'
        }
    },
    // styles for different sizes ("sm", "md", "lg")
    sizes: {},
    // styles for different visual variants ("outline", "solid")
    variants: {},
    // default values for `size` and `variant`
    defaultProps: {
        size: '',
        variant: ''
    },

    styles: {
        global: {
            html: {
                fontSize: '62.5%',
                fontFamily: 'Montserrat, sans-serif'
            },
            body: {
                fontSize: '1.5rem',
                fontFamily: 'Montserrat, sans-serif',
                lineHeight: '2.1rem',
                fontWeight: '400',
                marginRight: '0 !important'
            },
            p: {
                fontSize: '1.5rem',
                lineHeight: '2.1rem',
                fontWeight: '400',
                fontFamily: 'Montserrat, sans-serif'
            },
            img: {
                maxWidth: '100%',
                height: 'auto'
            },
            a: {
                transition: 'all 0.4s ease-in-out',
                _hover: {
                    transition: 'all 0.4s ease-in-out'
                }
            },
            h1: {
                fontSize: '2rem'
            },
            h2: {
                fontSize: '1.5rem'
            }
        }
    },
    colors: {
        lfgMainBlue: 'rgba(16,83,128,1)',
        lfgMainLightBlue: 'rgba(16, 101, 158, 1)',
        lfgMainBlueHover: 'rgba(18, 100, 156, 1)',
        lfgLightBlue: 'rgba(241,247,254,1)',
        lfgTicketBlue: 'rgba(12,116,129,1)',
        darkLfgTicketBlueColor: 'rgba(0, 89, 100, 1)',
        lfgTicketBlueHover: 'rgba(12,149,166,1)',
        bodyFontColor: 'rgba(80,90,98,1)',
        leftPanelFontColor: 'rgba(59,69,75,1)',
        listStrokeColor: 'rgba(216,223,236,1)',
        checkGreenColor: 'rgba(143,189,86,1)',
        lfgMainRedColor: 'rgba(238,58,60,1)',
        lightGreenColor: 'rgba(143, 189, 86, 1)',
        lfgMainRedColorHover: 'rgba(215, 22, 24, 1)',
        statusActiveColor: 'rgba(57,193,126,1)',
        statusInactiveColor: 'rgba(187,194,205,1)',
        statusActiveStrokeColor: 'rgba(215,251,244,1)',
        statusInactiveStrokeColor: 'rgba(234,236,239,1)',
        inputplaceholderColor: 'rgba(114,126,151,1)',
        inputStrokeColor: 'rgba(182,193,216,1)',
        buttonDisableColor: 'rgba(236,236,236,1)',
        buttonDisableStrokeColor: 'rgba(185, 185, 185, 1)',
        formHeadingBgColor: 'rgba(241,245,250,1)',
        iconFillColor: 'rgba(131,131,131,1)',
        breadCrumbTextColor: 'rgba(124,140,150,1)',
        buttonHoverColor: 'rgba(16,83,128,0.8)',
        errorRedColor: 'rgba(233,9,12,1)',
        pswrdOrangeColor: 'rgba(255,171,0,1)',
        darkOrangeColor: 'rgba(223, 131, 46, 1)',
        pswrdLightGreenColor: 'rgba(155,193,87,1)',
        pswrdDeepGreenColor: 'rgba(2,181,4,1)',
        dataTableRowBorder: 'rgba(216,223,236,1)',
        deepGreyColor: 'rgba(128, 145, 156, 1)',
        bodyButtonFontColor: 'rgba(55,68,88,1)',
        disableButtonStrokeColor: 'rgba(55,68,88,0.43)',
        disableButtonFontColor: 'rgba(165, 165, 166, 1)',
        menuElipseDropdownFontColor: 'rgba(55, 63, 70, 1)',
        profileImageStrokeColor: 'rgba(211, 225, 222, 1)',
        profileMenuHoverColor: 'rgba(230,237,239,1)',
        editIconBgColor: 'rgba(193, 208, 218, 1)',
        cancelButtonStrokeColor: 'rgba(221, 221, 221, 1)',
        cancelButtonHoverColor: 'rgba(222, 221, 221, 1)',
        topFilterSecBorderColor: 'rgba(220, 227, 242, 1)',
        expiryRedColor: 'rgba(238, 58, 60, 1)',
        lightRedColor: 'rgba(238,58,60,0.7)',
        expiryGreenColor: 'rgba(57, 193, 126, 1)',
        expiryOrangeColor: 'rgba(232, 132, 41, 1)',
        paginationBorderColor: 'rgba(201, 212, 215, 1)',
        successMessageColor: 'rgba(63, 122, 46, 1)',
        providerDefaultColor: 'rgba(223,138,60,1)',
        providerDefaultStrokeColor: 'rgba(201, 116, 38, 1)',
        inputDisabledColor: 'rgba(243, 243, 243, 1)',
        inputDisabledStrokeColor: 'rgba(188, 197, 214, 1)',
        tabListSeperator: 'rgba(223, 232, 242, 1)',
        caseTicketColor: 'rgba(157, 182, 200, 1)',
        veryLightBlueColor: 'rgba(226,232,240,1)',
        boxShadowLightBlackColor: 'rgba(0, 0, 0, 0.1)',
        boxShadowLightDeepBlackColor: 'rgba(0, 0, 0, 0.2)',
        lfgBlueGreyColor: 'rgba(225, 233, 238, 1)',
        editIconBgColorLight: 'rgba(230, 231, 233, 1)',
        boxShadowDeepBlackColor: 'rgba(0, 0, 0, 0.7)',
        boxShadowBlackColor: 'rgba(0, 0, 0, 0.15)',
        lightWhiteColor: 'rgba(255,255,255, 0.5)',
        deepGrayColor: 'rgba(187, 194, 205, 0.93)',
        lightGrayColor: 'rgba(3, 23, 36, 0.24)',
        veryLightGrayColor: 'rgba(8, 49, 131, 0.15)',
        darkGrayColor: 'rgba(75,82,91,1)',
        deepDarkGrayColor: 'rgba(31, 31, 31, 1)',
        lightdarkgreenColor: 'rgba(104, 143, 56, 1)',
        navDropdownBgColor: 'rgba(232, 241, 251, 1)',
        blueGrayColor: 'rgba(178, 197, 209, 1)',
        greenLightColor: 'rgba(57, 193, 126, 0.54)',
        lfgOrangeStatus: 'rgba(228, 124, 25, 1)',

        // LightMode color palette
        mainLightModeBackgroundColor: 'rgba(255,255,255,1)',
        // DarkMode color palette
        mainDarkModeBackgroundColor: 'rgba(34,36,51,1)',
        mainDarkModePurpleColor: 'rgb(48, 30, 103)',
        mainDarkModeDeepBackgroundColor: 'rgba(26, 28, 41, 1)',
        darkModeBoxBackgroundColor: 'rgba(42, 43, 61, 1)',
        mainDarkModeLightBackgroundColor: 'rgba(55, 56, 74, 1)',
        darkModeBordercolor: 'rgba(55, 56, 74, 1)',
        tableHeadDarkModeBgColor: 'rgba(28, 30, 42, 1)',
        breadcamDarkModeBgColor: 'rgba(33, 33, 44, 1)',
        buttonBgColor: 'rgba(67, 68, 87, 1)',
        darkModeResponsiveScreensHeadingTextXColor: 'rgb(210 219 237)',
        lfgDarkTicketBlue: 'rgba(12,116,129,1)',
        stockGreenColor: 'rgba(104, 143, 56,1)',
        iconBlackColor: 'rgba(54, 69, 79, 1)',
        boxShadowBlackColorSecondary: 'rgba(211, 211, 211, 0.54)',
        rippleWhiteColor: 'rgba(255,255,255, 0.5)',
        boxShadowLightBlueColor: 'rgba(255,255,255, 0.5)',
        boxShadowLightGreenColor: 'rgba(57, 193, 126, 0.54)',
        boxShadowGrayColor: 'rgba(187, 194, 205, 0.93)',
        darkGreenColor: 'rgba(121, 166, 65, 1)',
        veryDarkGreenColor: 'rgba(104, 143, 56, 1)',
        lightBlueColor: 'rgba(178, 197, 209, 1)',
        skyBlueColor: 'rgba(28, 125, 191, 1)',
        darkRedColor: 'rgba(225, 45, 47, 1)',
        darkBlueColor: 'rgba(59, 69, 75, 1)',
        veryDarkBlueColor: 'rgba(34, 42, 47, 1)',
        lfgGrayColor: 'rgba(114, 126, 151, 1)',
        lifgtSkyBlueColor: 'rgba(215, 229, 244, 1)',
        lfgYellow: 'rgba(252, 206, 77, 1)',
        lfgDarkYellow: 'rgba(186, 151, 52, 1)',
        lfgLightYellow: 'rgba(255, 226, 143, 1)',
        lfgGrayBlueColor: 'rgba(177,192,203,1)',
        lfgBgGrayColor: 'rgba(241, 241, 241, 1)',
        lfgDarkWhite: 'rgba(252, 252, 252, 1)',
        lfgGreycolor: 'rgba(131, 131, 131, 1)',
        lfgLightPurpleColor: 'rgba(208, 208, 225, 1)',
        lfgPurpleColor: 'rgba(39, 44, 80, 1)',
        lfgPurpleBlueColor: 'rgba(37, 43, 167, 1)',
        lfglightGreyColor: 'rgba(175, 175, 175, 1)',
        lfgPurpleGreyColor: 'rgba(130, 141, 163, 1)',
        textareacolor: 'rgba(65, 75, 95, 1)',
        textareaDarkmode: 'rgba(137, 150, 175,1)',
        lightDarkcolor: 'rgba(70, 74, 82,1)',
        listingDarkmodeColor: 'rgba(78, 88, 109, 1)'
    },
    textStyles: {
        h1: {
            // you can also use responsive styles
            fontSize: '2rem',
            fontWeight: 'bold'
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 'semibold'
        },
        button: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '1.5rem',
            transition: 'all 0.4s ease-in-out',
            border: '1px solid transparent',
            _hover: {
                transition: 'all 0.4s ease-in-out'
            }
        },
        input: {
            fontSize: '1.3rem'
        },
        label: {
            fontSize: '1.3rem'
        }
    }
})

export default ExtendedTheme
