import React, { StrictMode } from 'react'
import { render } from 'react-dom'
import PrimeReact from 'primereact/api'
import { ChakraProvider } from '@chakra-ui/react'
import detect from 'webp-avif-browsers-support-detection'
import { register } from './serviceWorkerRegistration'

import RouteHolder from './router/routeHolder'
import ExtendedTheme from './modules/global/css/chakraExtendTheme'

import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'

register({
    onSuccess: (sw) => {
        // console.log(sw, 'onSuccess!!')
    },
    onUpdate: (sw) => {
        // console.log(sw, 'onUpdate!!')
    }
})

detect()

if (process.env.REACT_APP_WEB_VITALS === 'true') {
    import('./utilities/webVitals').then(({ reportWebVitals, reportHandler }) =>
        reportWebVitals(reportHandler)
    )
}

// detect().then((resp: { avif: boolean; webp: boolean }) => {
//     // will print the detils (ex. {avif:true, webp:false})
//     console.warn(resp)
// })
// console.log(ExtendedTheme, 'ExtendedTheme')
PrimeReact.ripple = true

const WrapedWithRouter = () => (
    // <StrictMode>
    <ChakraProvider theme={ExtendedTheme} resetCSS>
        <RouteHolder />
    </ChakraProvider>
    // </StrictMode>
)

const rootElement = document.getElementById('root')
render(<WrapedWithRouter />, rootElement)
