import axios from 'axios'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import Store from '../store/store'
import {
    updateLoginStatus,
    updateTokenTimeout
} from '../modules/authentication/slices'

interface Signature {
    logMeOut: (path?: string) => void
    toast: any
    authSliceData: any
}

// eslint-disable-next-line import/no-mutable-exports
let axiosInterceptor: unknown

const responseHandler = (opt: Signature) => {
    const { dispatch } = Store
    const { REACT_APP_TIMEOUT } = process.env
    const { logMeOut, toast, authSliceData } = opt

    axiosInterceptor = axios.interceptors.response.use(
        (resp) => {
            const { auth } = Store.getState()

            if (resp.status === 204 && window.navigator.vibrate) {
                window.navigator.vibrate(300)
            }

            if (!!resp.data.response && !!resp.data.response.raws.data.token) {
                const { token, refresh_duration: refreshDuration } =
                    resp.data.response.raws.data

                if (resp.data.response.raws.data.user) {
                    const {
                        full_name: userName,
                        login_user_id: userId,
                        profile_image: profileImage,
                        staff_location_id: staffLocationId,
                        staff_firm_id: staffFirmId,
                        legal_firm_name: legalFirmName,
                        proposed_email: proposedEmail,
                        user_email: userEmail
                    } = resp.data.response.raws.data.user
                    dispatch(
                        updateLoginStatus({
                            loggedIn: !!token,
                            userName,
                            userId,
                            profileImage,
                            token,
                            staffFirmId,
                            staffLocationId,
                            legalFirmName,
                            refreshDuration,
                            proposedEmail,
                            userEmail
                        })
                    )
                } else {
                    dispatch(
                        updateLoginStatus({
                            loggedIn: !!token,
                            token,
                            refreshDuration: auth.refreshDuration
                        })
                    )
                }
                /**
                 * @description - Auto log out user if token is expired
                 */
                // const { token, refresh_duration: refreshDuration } =
                //     resp.data.response.raws.data
                const { auth: authAfterUpdate } = Store.getState()
                const { iat }: { exp: number; iat: number } = jwt_decode(token)
                let logoutTime = 0
                if (refreshDuration) {
                    // logoutTime =
                    //     (exp - iat + parseInt(refreshDuration, 10) * 60) * 1000
                    logoutTime = parseInt(refreshDuration, 10) * 60 * 1000
                } else {
                    logoutTime =
                        parseInt(authAfterUpdate.refreshDuration, 10) *
                        60 *
                        1000
                }

                if (
                    process.env.REACT_APP_AUTO_LOGOUT_ON_TOKEN_EXPIRE === 'true'
                ) {
                    dispatch(
                        updateTokenTimeout({
                            logoutTime,
                            isTokenRefresh: Date.now(),
                            initializationTime: iat,
                            brodCastFlagVal: Date.now()
                        })
                    )
                }
            }

            return resp
        },
        (err) => {
            if (window.navigator.vibrate) {
                window.navigator.vibrate(300)
            }
            // Do something with response error
            const error = err.toJSON()
            if (
                error.status === 401 &&
                error.config &&
                // eslint-disable-next-line no-underscore-dangle
                !error.config.__isRetryRequest
            ) {
                logMeOut()
            }

            if (error.status === 409) {
                window.location.reload()
            }

            if (error.status === 500) {
                toast({
                    description: 'Something went wrong',
                    position: 'top',
                    status: 'error',
                    duration: REACT_APP_TIMEOUT,
                    isClosable: false
                })
            }
            return Promise.reject(err)
        }
    )
}

export { axiosInterceptor }

export default responseHandler
