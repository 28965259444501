import { createApi } from '@reduxjs/toolkit/dist/query/react'
import createBaseQuery from '../../../utilities/createBaseQuery'
import {
    RequestListRequestType,
    RequestListResponseType
} from '../../requests/interfaces'
import {
    ChargesListByCaseResponseType,
    ChargesListByCaseRequestType,
    ChargeSummaryByCaseResponseType,
    ChargeSummaryByCaseRequestType,
    GenerateReportResponseType,
    GenerateReportRequestType,
    AddCaseRequestType,
    AddCaseResponseType,
    CaseAddRequestResponseType,
    CaseAddRequestType,
    EditCaseRequestType,
    EditCaseResponseType,
    FilterCaseTypeRequestType,
    FilterCaseTypeResponseType,
    FilterClientRequestType,
    FilterClientResponseType,
    GetCaseListRequestType,
    GetCaseListResponseType,
    UpdateNoteRequestType,
    UpdateNoteResponseType,
    ViewCaseRequestType,
    ViewCaseResponseType,
    ClientDocumentListResponseType,
    ClientDocumentListRequestType,
    downloadClientDocumentResponseType,
    ViewClientReleaseResponseType,
    ViewClientReleaseRequestType,
    RequestListByCaseIdRequestType,
    RequestListByCaseIdResponseType,
    downloadClientDocumentRequestType,
    CreateCaseRecordLinkRequestType,
    CreateCaseRecordLinkResponseType,
    RemoveCaseLinkRequestType,
    RemoveCaseLinkResponseType,
    ViewCaseRecordLinkRequestType,
    ViewCaseRecordLinkResponseType,
    CaseCountDetailsRequestType,
    CaseCountDetailsResponseType,
    RushPriorityListResponseType,
    RemoveRushResponseType,
    RemoveRushRequestType,
    GetCaseStatusResponseType,
    GetCaseStatusRequestType
} from '../interfaces'

const casesApi = createApi({
    reducerPath: 'cases',
    baseQuery: createBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: [
        'CaseList',
        'CaseDocumentList',
        'CaseRequestList',
        'chargeListByCase',
        'chargeSummaryByCase'
    ],

    endpoints: (builder) => ({
        chargeListByCase: builder.query<
            ChargesListByCaseResponseType,
            ChargesListByCaseRequestType
        >({
            query: (data) => ({
                url: 'case/chargeListByCase',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['chargeListByCase'],
            keepUnusedDataFor: 0.00001
        }),
        chargeSummaryByCase: builder.query<
            ChargeSummaryByCaseResponseType,
            ChargeSummaryByCaseRequestType
        >({
            query: (data) => ({
                url: 'case/chargeSummaryByCase',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['chargeSummaryByCase'],
            keepUnusedDataFor: 0.00001
        }),
        generateXlsChargeReportByCase: builder.mutation<
            GenerateReportResponseType,
            GenerateReportRequestType
        >({
            query: (data) => ({
                url: 'case/generateXlsChargeReportByCase',
                payload: data,
                method: 'POST'
            })
        }),

        generatePdfChargeReportByCase: builder.mutation<
            GenerateReportResponseType,
            GenerateReportRequestType
        >({
            query: (data) => ({
                url: 'case/generatePdfChargeReportByCase',
                payload: data,
                method: 'POST'
            })
        }),
        getCases: builder.query<
            GetCaseListResponseType,
            GetCaseListRequestType
        >({
            query: (data) => ({
                url: 'case/list',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['CaseList'],
            keepUnusedDataFor: 0.0001
        }),
        filterCaseType: builder.query<
            FilterCaseTypeResponseType,
            FilterCaseTypeRequestType
        >({
            query: (data) => ({
                url: 'case/filterCaseType',
                payload: data,
                method: 'POST'
            })
        }),
        updateNote: builder.mutation<
            UpdateNoteResponseType,
            UpdateNoteRequestType
        >({
            query: (data) => ({
                url: 'case/updateNote',
                payload: data,
                method: 'POST'
            })
            // invalidatesTags: ['CaseList']
        }),
        addCase: builder.mutation<AddCaseResponseType, AddCaseRequestType>({
            query: (data) => ({
                url: 'case/add',
                payload: data,
                method: 'POST'
            })
        }),
        editCase: builder.mutation<EditCaseResponseType, EditCaseRequestType>({
            query: (data) => ({
                url: 'case/edit',
                payload: data,
                method: 'POST'
            })
        }),
        viewCase: builder.query<ViewCaseResponseType, ViewCaseRequestType>({
            query: (data) => ({
                url: 'case/view',
                payload: data,
                method: 'POST'
            })
        }),
        removeRushFromList: builder.mutation<
            RemoveRushResponseType,
            RemoveRushRequestType
        >({
            query: (data) => ({
                url: 'case/removeRush',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['CaseList']
        }),
        filterClient: builder.query<
            FilterClientResponseType,
            FilterClientRequestType
        >({
            query: (data) => ({
                url: 'case/filterClient',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.0001
        }),
        getCaseRequests: builder.query<
            RequestListResponseType,
            RequestListRequestType
        >({
            query: (data) => ({
                url: 'case/CaseRequestList',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.0001,
            providesTags: ['CaseList']
        }),
        caseAddRequest: builder.mutation<
            CaseAddRequestResponseType,
            CaseAddRequestType
        >({
            query: (data) => ({
                url: 'case/CaseAddRequest',
                payload: data,
                method: 'POST'
            })
        }),
        rushPriorityList: builder.query<RushPriorityListResponseType, void>({
            query: (data) => ({
                url: 'case/filterRushPriority',
                payload: data,
                method: 'POST'
            })
        }),
        ClientDocumentList: builder.query<
            ClientDocumentListResponseType,
            ClientDocumentListRequestType
        >({
            query: (data) => ({
                url: 'case/caseDocumentList',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.0001,
            providesTags: ['CaseList']
        }),
        downloadClientDocument: builder.mutation<
            downloadClientDocumentResponseType,
            { id: string }
        >({
            query: (data) => ({
                url: 'case/downloadClientDocument',
                payload: data,
                method: 'POST'
            })
        }),
        generateCaseRequestReport: builder.mutation<
            downloadClientDocumentResponseType,
            downloadClientDocumentRequestType
        >({
            query: (data) => ({
                url: 'case/generateCaseRequestReport',
                payload: data,
                method: 'POST'
            })
        }),
        viewClientRelease: builder.mutation<
            ViewClientReleaseResponseType,
            ViewClientReleaseRequestType
        >({
            query: (data) => ({
                url: 'case/viewClientRelease',
                payload: data,
                method: 'POST'
            })
        }),
        getRequestListByCaseId: builder.query<
            RequestListByCaseIdResponseType,
            RequestListByCaseIdRequestType
        >({
            query: (data) => ({
                url: 'case/requestListByCaseId',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['CaseRequestList'],
            keepUnusedDataFor: 0.0001
        }),
        getCaseStatus: builder.query<
            GetCaseStatusResponseType,
            GetCaseStatusRequestType
        >({
            query: (data) => ({
                url: 'case/getCaseStatus',
                payload: data,
                method: 'POST'
            })
        }),
        createCaseRecordLink: builder.mutation<
            CreateCaseRecordLinkResponseType,
            CreateCaseRecordLinkRequestType
        >({
            query: (data) => ({
                url: 'case/createCaseRecordLink',
                payload: data,
                method: 'POST'
            })
        }),
        viewCaseRecordLink: builder.query<
            ViewCaseRecordLinkResponseType,
            ViewCaseRecordLinkRequestType
        >({
            query: (data) => ({
                url: 'case/viewCaseRecordLink',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.00001
        }),
        removeCaseLink: builder.mutation<
            RemoveCaseLinkResponseType,
            RemoveCaseLinkRequestType
        >({
            query: (data) => ({
                url: 'case/removeCaseLink',
                payload: data,
                method: 'POST'
            })
        }),
        caseCountDetails: builder.query<
            CaseCountDetailsResponseType,
            CaseCountDetailsRequestType
        >({
            query: (data) => ({
                url: 'case/caseCountDetails',
                payload: data,
                method: 'POST'
            })
        })
    })
})

// ClientAuthorizationResponseType
export default casesApi
export const {
    useChargeListByCaseQuery,
    useChargeSummaryByCaseQuery,
    useGeneratePdfChargeReportByCaseMutation,
    useGenerateXlsChargeReportByCaseMutation,
    useGetCasesQuery,
    useFilterCaseTypeQuery,
    useUpdateNoteMutation,
    useAddCaseMutation,
    useEditCaseMutation,
    useViewCaseQuery,
    useRushPriorityListQuery,
    useFilterClientQuery,
    useGetCaseRequestsQuery,
    useCaseAddRequestMutation,
    useClientDocumentListQuery,
    useDownloadClientDocumentMutation,
    useGenerateCaseRequestReportMutation,
    useViewClientReleaseMutation,
    useGetRequestListByCaseIdQuery,
    useGetCaseStatusQuery,
    useCreateCaseRecordLinkMutation,
    useViewCaseRecordLinkQuery,
    useRemoveCaseLinkMutation,
    useCaseCountDetailsQuery,
    useRemoveRushFromListMutation
} = casesApi
