import { createApi } from '@reduxjs/toolkit/dist/query/react'
import createBaseQuery from '../../../utilities/createBaseQuery'

import {
    StaffListResponseType,
    StaffListRequestType,
    AddStaffResponseType,
    AddStaffRequestType,
    EditStaffResponseType,
    EditStaffRequestType,
    ViewStaffResponseType,
    ViewStaffRequestType,
    GetLocationDropdownResponseTye,
    GetLocationDropdownRequestType,
    ChangeStuffStatusRequestType,
    ChangeStuffStatusResponseType
} from '../interfaces'

const staffApi = createApi({
    reducerPath: 'staffList',
    baseQuery: createBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: ['staffList'],
    endpoints: (builder) => ({
        getStaffList: builder.query<
            StaffListResponseType,
            StaffListRequestType
        >({
            query: (data) => ({
                url: `firm/staffList`,
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.001,
            providesTags: ['staffList']
        }),
        addStaff: builder.mutation<AddStaffResponseType, AddStaffRequestType>({
            query: (data) => ({
                url: 'firm/addStaff',
                payload: data,
                method: 'POST'
            })
        }),
        editStaff: builder.mutation<
            EditStaffResponseType,
            EditStaffRequestType
        >({
            query: (data) => ({
                url: 'firm/editStaff',
                payload: data,
                method: 'POST'
            })
        }),
        viewStaff: builder.query<ViewStaffResponseType, ViewStaffRequestType>({
            query: (data) => ({
                url: 'firm/viewStaff',
                payload: data,
                method: 'POST'
            })
        }),
        getLocationDropdownList: builder.query<
            GetLocationDropdownResponseTye,
            GetLocationDropdownRequestType
        >({
            query: (data) => ({
                url: 'firm/filterLocation',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['staffList'],
            keepUnusedDataFor: 0.00001
        }),
        changeStaffStatus: builder.mutation<
            ChangeStuffStatusResponseType,
            ChangeStuffStatusRequestType
        >({
            query: (data) => ({
                url: 'firm/changeStatus',
                payload: data,
                method: 'POST'
            })
            // invalidatesTags: ['staffList']
        })
    })
})

export default staffApi

export const {
    useGetStaffListQuery,
    useAddStaffMutation,
    useEditStaffMutation,
    useViewStaffQuery,
    useGetLocationDropdownListQuery,
    useChangeStaffStatusMutation
} = staffApi
