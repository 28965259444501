import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PageStateSignature } from '../interfaces'
import { updateLogoutStatus } from '../../authentication/slices'


const initialState: PageStateSignature = {
    pageStates: [],
    currentPage: 1,
    totalPages: 1
}

export const pageSlice = createSlice({
    name: 'pageWiseData',
    initialState,
    reducers: {
        setTotalPages: (state, action: PayloadAction<number>) => {
            state.totalPages = action.payload
        },
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        setPageStates: (state, action: PayloadAction<any>) => {
            state.pageStates = action.payload
        },
        unsetPageStates: (state) => {
            state.pageStates = []
        }
       
    },
    extraReducers: (builder) => {
        builder.addCase(updateLogoutStatus, () => initialState)
    }
})

export const { setPageStates, setTotalPages, setPageNumber, unsetPageStates } =
    pageSlice.actions

export default pageSlice.reducer
