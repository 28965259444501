import { createSlice } from '@reduxjs/toolkit'

import { globalLoaderInterface } from '../interfaces'
import { updateLogoutStatus } from '../../authentication/slices'

const initialState: globalLoaderInterface = {
    apiPending: false,
    breadCrumbs: [],
    darkMode: false,
    clientId: '',
    idForMultipleUseList: {},
    isAddRushClicked: {},
    requestIdForAlertUpdate: {},
}

const GlobalLoader = createSlice({
    name: 'GlobalLoaderReducer',
    initialState,
    reducers: {
        setGlobalLoader: (state, action) => {
            state.apiPending = action.payload
            return state
        },
        setGloalBreadcrumb: (state, action) => {
            state.breadCrumbs = []
            state.breadCrumbs = action.payload
            return state
        },
        setDarkMode: (state, action) => {
            state.darkMode = action.payload
            return state
        },
        updateIdForMultipleUseList: (state, action) => {
            state.idForMultipleUseList = {
                ...state.idForMultipleUseList,
                [action.payload.id]: action.payload.value
            }
            return state
        },
        setClientId: (state, action) => {
            state.clientId = action.payload
            return state
        },
        updaterRequestIdForAlertUpdateList: (state, action) => {
            state.requestIdForAlertUpdate = {
                ...state.requestIdForAlertUpdate,
                [action.payload.id]: action.payload.value
            }
            return state
        },
        updateAddRush: (state, action) => {
            state.isAddRushClicked = {
                ...state.isAddRushClicked,
                [action.payload.id]: action.payload.value
            }
            return state
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateLogoutStatus, () => initialState)
    }
})

export const { setGlobalLoader, setGloalBreadcrumb, setDarkMode, setClientId  , updateAddRush , updateIdForMultipleUseList ,    updaterRequestIdForAlertUpdateList} =
    GlobalLoader.actions
export default GlobalLoader
