/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    HStack,
    FormControl,
    Heading,
    FormLabel,
    Input,
    Stack,
    Button,
    Text,
    Flex,
    VStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormErrorMessage,
    InputGroup,
    InputRightElement,
    Tooltip,
    Avatar,
    useToast,
    Spinner,
    PinInputField,
    PinInput,
    ModalHeader
} from '@chakra-ui/react'
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop
} from 'react-image-crop'
import { InputMask } from 'primereact/inputmask'
import 'react-image-crop/dist/ReactCrop.css'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Ripples from 'react-ripples'

import {
    useDeleteProfileImageMutation,
    usePasswordChangeMutation,
    useUserProfileQuery,
    useUpdateProfileMutation,
    useChangeEmailMutation,
    useDeleteEmailMutation,
    useSendOtpMutation,
    useSubmitOtpMutation,
    useUpdateProfileImageMutation
} from '../queries/profileQuery'
import {
    Signature,
    passwordChangeFrom,
    changeUserDetails,
    submitAlternateEmail
} from '../interfaces'
import useBreadcrumbSegments from '../../global/hooks/useBreadcrumbSegments'
import { RootState, persistStorage } from '../../../store/store'
import {
    updateLoginStatus,
    updateLogoutStatus
} from '../../authentication/slices/index'
import ButtonLoader from '../../global/components/buttonLoader'

const Profile: React.FC<Signature> = (props) => {
    const { REACT_APP_TIMEOUT } = process.env
    const dispatch = useDispatch()
    const globalState = useSelector((state: RootState) => state.GlobalLoader)
    const auth = useSelector((state: RootState) => state.auth)
    const [newInputType, setNewInputType] = useState('password')
    const { userName } = useSelector((state: RootState) => state.auth)
    const [strength, setStrength] = useState(0)
    const [confirmInputType, setConfirmInputType] = useState('password')
    const [currentInputType, setCurrentInputType] = useState('password')
    const [changePasswordErrMessage, setChangePasswordErrMessage] = useState('')
    const [changePasswordSucMessage, setChangePasswordSucMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const imageRef = useRef(null)
    const [croppedImageUrl, setCroppedImageUrl] = useState<any>()
    const [isReUploadImage, setIsReUploadImage] = useState(false)
    const [isLoadingChangePassword, setIsLoadingChangePassword] =
        useState(false)
    const [isDisableChangePassword, setIsDisableChangePassword] =
        useState(false)
    const [isNotProfileEdit, setIsNotProfileEdit] = useState(true)
    const timeout: { current: ReturnType<typeof setTimeout> | undefined } =
        useRef()
    const [passwordChange] = usePasswordChangeMutation()
    const [phone, setPhone] = useState({
        contactFaxCountryCode: '+1',
        contactFax: '',
        contactFaxExt: ''
    })
    const [isSubmittingProfileUpdate, setIsSubmittingProfileUpdate] =
        useState(false)
    const [isImageLoading, setIsImageLoading] = useState(false)

    const [alternateEmailSucMessage, setAlternateEmailSucMessage] = useState('')
    const [alternateEmailErrMessage, setAlternateEmailErrMessage] = useState('')
    const [isDisableAlternateEmail, setIsDisableAlternateEmail] =
        useState(false)
    const [isLoadingAlternateEmail, setIsLoadingAlternateEmail] =
        useState(false)
    const [deleteEmailSucMessage, setDeleteEmailSucMessage] = useState('')
    const [deleteEmailErrMessage, setDeleteErrSucMessage] = useState('')
    const [isDeleteEmailLoading, setIsDeleteErrLoading] = useState(false)
    const [isDeleteEmailDisable, setIsDeleteErrDisable] = useState(false)
    const [emailType, setEmailType] = useState('Add')
    const [otp, setOtp] = useState('')
    const [msg, setMsg] = useState('')
    const [resend, setResend] = useState(false)
    const [seconds, setSeconds] = useState(30)

    const toast = useToast()
    const {
        isOpen: isImageOpen,
        onOpen: onImageOpen,
        onClose: onImageClose
    } = useDisclosure()
    const {
        isOpen: isPasswordChangeOpen,
        onOpen: onPasswordChangeOpen,
        onClose: onPasswordChangeClose
    } = useDisclosure()
    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose
    } = useDisclosure()

    const {
        isOpen: isEmailEditOpen,
        onOpen: onEmailEditOpen,
        onClose: onEmailEditClose
    } = useDisclosure()

    const {
        isOpen: isEmailDeleteOpen,
        onOpen: onEmailDeleteOpen,
        onClose: onEmailDeleteClose
    } = useDisclosure()
    const {
        isOpen: isOtpVerifyOpen,
        onOpen: onOtpVerifyOpen,
        onClose: onOtpVerifyClose
    } = useDisclosure()

    const { data, refetch, isFetching } = useUserProfileQuery()
    const [updateProfile] = useUpdateProfileMutation()
    const [deleteProfileImage] = useDeleteProfileImageMutation()
    const [changeEmail] = useChangeEmailMutation()
    const [deleteEmail] = useDeleteEmailMutation()
    const [sendOtp] = useSendOtpMutation()
    const [submitOtp] = useSubmitOtpMutation()
    const [updateProfileImage] = useUpdateProfileImageMutation()

    const userDetailsSchema = yup.object().shape(
        {
            fName: yup.string().required('First Name is required'),
            lName: yup.string().required('Last Name is required'),
            contactFaxCountryCode: yup.string().nullable(),
            contactFaxExt: yup
                .string()
                .nullable()
                .when('contactFax', {
                    is: (val: string) => val !== '',
                    then: yup.string().required('Required')
                    // otherwise: yup.string()
                }),
            contactFax: yup
                .string()
                .nullable()
                .when('contactFaxExt', {
                    is: (val: string) => val !== '',
                    then: yup.string().required('Required')
                    // otherwise: yup.string()
                })
        },
        [['contactFaxExt', 'contactFax']]
    )

    const schema = yup.object().shape({
        currentPassword: yup.string().required('Current Password is required'),
        newPassword: yup
            .string()
            .required('New Password is required')
            .min(8, 'Password must be at least 8 characters in length')
            .max(20, 'Password must be less than 20 characters in length'),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('newPassword'), null], `Password doesn't match`)
    })
    const {
        handleSubmit,
        register,
        formState: { errors },
        watch,
        reset
    } = useForm<passwordChangeFrom>({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        }
    })

    const {
        register: userDetailsRegister,
        formState: { errors: userDetailsErrors },
        handleSubmit: userDetailsHandleSubmit,
        clearErrors,
        trigger,
        setValue
    } = useForm<changeUserDetails>({
        mode: 'onTouched',
        resolver: yupResolver(userDetailsSchema),
        defaultValues: {
            fName: '',
            lName: '',
            contactFaxCountryCode: '+1',
            contactFaxExt: '',
            contactFax: ''
        }
    })

    useBreadcrumbSegments()
    const { globalLayout } = props
    const [crop, setCrop] = useState<Crop | undefined>({
        unit: 'px',
        x: 25,
        y: 25,
        width: 200,
        height: 200
    })
    const [minSize, setMinSize] = useState({
        width: 200,
        height: 200
    })
    const [aspect, setAspect] = useState<number | undefined>(1)

    const [imgSrc, setImgSrc] = useState(
        ''
        // 'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1574&q=80'
    )
    const [previmgSrc, setPrevImgSrc] = useState('')

    const centerAspectCrop = (
        mediaWidth: number,
        mediaHeight: number,
        aspct: number
    ) =>
        centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90
                },
                aspct,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        )

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    const getCroppedImg = (image: any, crop3: any, fileName: any) => {
        const canvas = document.createElement('canvas')
        const pixelRatio = window.devicePixelRatio
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const ctx = canvas.getContext('2d')

        canvas.width = crop3.width * pixelRatio * scaleX
        canvas.height = crop3.height * pixelRatio * scaleY

        ctx?.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
        // ctx.imageSmoothingQuality = 'high'

        ctx?.drawImage(
            image,
            crop3.x * scaleX,
            crop3.y * scaleY,
            crop3.width * scaleX,
            crop3.height * scaleY,
            0,
            0,
            crop3.width * scaleX,
            crop3.height * scaleY
        )

        return canvas.toDataURL('image/jpeg')
    }

    const makeClientCrop = async (crop2: any) => {
        if (imageRef.current && crop2.width && crop2.height) {
            const croppedImageUrls = await getCroppedImg(
                imageRef.current,
                crop2,
                'newFile.jpeg'
            )
            setCroppedImageUrl(croppedImageUrls)
        }
    }
    const onCropComplete = (croped: any) => {
        makeClientCrop(croped)
    }

    const tempImageUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setIsReUploadImage(false)
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                setImgSrc(reader.result?.toString() || '')
            })
            reader.readAsDataURL(e.target.files[0])
        }
    }

    useEffect(() => {
        if (globalLayout) {
            globalLayout(true)
        }
    }, [globalLayout])
    useEffect(() => {
        if (data && isFetching === false) {
            setImgSrc(
                data.response.raws.data.dataset.profile_image_path
                    ? `${
                          data.response.raws.data.dataset.profile_image_path
                      }?V=${Date.now()}`
                    : ''
            )
            setPrevImgSrc(
                data.response.raws.data.dataset.profile_image_path
                    ? `${
                          data.response.raws.data.dataset.profile_image_path
                      }?V=${Date.now()}`
                    : ''
            )
        }
    }, [data, isFetching])

    const handelPasswordChange = (formData: passwordChangeFrom) => {
        setIsLoadingChangePassword(true)
        setIsDisableChangePassword(true)
        passwordChange({
            current_password: formData.currentPassword,
            new_password: formData.newPassword,
            confirm_password: formData.confirmPassword
        })
            .unwrap()
            .then((res) => {
                setChangePasswordSucMessage(res.response.raws.success_message)
                if (REACT_APP_TIMEOUT) {
                    setTimeout(() => {
                        reset()
                        dispatch(updateLogoutStatus())
                        persistStorage.flush().then(() => {
                            persistStorage.purge().then(() => {
                                localStorage.clear()
                                sessionStorage.clear()
                            })
                        })
                        setChangePasswordSucMessage('')
                        setIsDisableChangePassword(false)
                        onPasswordChangeClose()
                    }, parseInt(REACT_APP_TIMEOUT, 10))
                }
            })
            .catch((err) => {
                setIsDisableChangePassword(false)
                setChangePasswordErrMessage(
                    err.data.response.raws.error_message
                )
                if (REACT_APP_TIMEOUT) {
                    timeout.current = setTimeout(() => {
                        setChangePasswordErrMessage('')
                    }, parseInt(REACT_APP_TIMEOUT, 10))
                }
            })
            .finally(() => {
                setIsLoadingChangePassword(false)
            })
    }

    const hasNumber = (value: string) => /[0-9]/.test(value)
    const hasMixed = (value: string) =>
        /[a-zA-Z]/.test(value) &&
        /[0-9]/.test(value) &&
        /[!#@$%^&*\][)}{(+=._-]/.test(value)

    const hasCharacter = (value: string) => /[a-zA-Z]/.test(value)

    const hasSpecial = (value: string) => /[!#@$%^&*\][)}{(+=._-]/.test(value)

    const handleNewInputTypeChange = () => {
        setNewInputType(newInputType === 'password' ? 'text' : 'password')
    }
    const handleCurrentInputTypeChange = () => {
        setCurrentInputType(
            currentInputType === 'password' ? 'text' : 'password'
        )
    }
    const currentPVal = watch('currentPassword')
    const newPVal = watch('newPassword')
    useEffect(() => {
        let newStrength = 0
        if (newPVal.length > 0) {
            if (newPVal.length < 8) {
                newStrength = 0
            } else if (newPVal.length >= 8) {
                if (hasNumber(newPVal)) {
                    newStrength = 1
                } else if (hasCharacter(newPVal)) {
                    newStrength = 1
                } else if (hasSpecial(newPVal)) {
                    newStrength = 1
                }
                if (hasNumber(newPVal) && hasCharacter(newPVal)) {
                    newStrength = 2
                } else if (hasNumber(newPVal) && hasSpecial(newPVal)) {
                    newStrength = 2
                } else if (hasCharacter(newPVal) && hasSpecial(newPVal)) {
                    newStrength = 2
                }
                if (hasMixed(newPVal)) {
                    if (newPVal.length >= 20) {
                        newStrength = 4
                    } else {
                        newStrength = 3
                    }
                }
            }
        } else {
            setNewInputType('password')
            newStrength = 0
        }
        setStrength(newStrength)
    }, [newPVal])

    const confirmPVal = watch('confirmPassword')
    useEffect(() => {
        if (confirmPVal.length === 0) {
            setConfirmInputType('password')
        }
    }, [confirmPVal])

    const handleConfirmInputTypeChange = () => {
        setConfirmInputType(
            confirmInputType === 'password' ? 'text' : 'password'
        )
    }

    const handlePassStrengthColor1 = () =>
        strength === 1
            ? 'errorRedColor'
            : strength === 2
            ? 'pswrdOrangeColor'
            : strength === 3
            ? 'pswrdLightGreenColor'
            : strength === 4
            ? 'pswrdDeepGreenColor'
            : '#ECECEC'
    const handlePassStrengthColor2 = () =>
        strength === 2
            ? 'pswrdOrangeColor'
            : strength === 3
            ? 'pswrdLightGreenColor'
            : strength === 4
            ? 'pswrdDeepGreenColor'
            : '#ECECEC'
    const handlePassStrengthColor3 = () =>
        strength === 3
            ? 'pswrdLightGreenColor'
            : strength === 4
            ? 'pswrdDeepGreenColor'
            : '#ECECEC'
    const handlePassStrengthColor4 = () =>
        strength === 4 ? 'pswrdDeepGreenColor' : '#ECECEC'

    const handlePassStrengthText = () =>
        strength === 1
            ? 'Weak'
            : strength === 2
            ? 'Moderate'
            : strength === 3
            ? 'Strong'
            : strength === 4
            ? 'Very Strong'
            : ''

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhone({
            ...phone,
            [e.target.name]: e.target.value
        })
        if (e.target.name === 'contactFax') {
            // trigger('contactFax')
            trigger('contactFaxExt')
            setValue('contactFax', e.target.value.split('-').join(''))
        } else if (e.target.name === 'contactFaxExt') {
            trigger('contactFax')
            trigger('contactFaxExt')
            setValue('contactFaxExt', e.target.value.split('-').join(''))
        }
    }

    const handelUserDetailsChange = (formData: changeUserDetails) => {
        setIsSubmittingProfileUpdate(true)
        updateProfile({
            first_name: formData.fName,
            last_name: formData.lName,
            phone: formData.contactFax
                ? `${formData.contactFaxCountryCode as string}${
                      formData.contactFaxExt
                  }${formData.contactFax}`
                : ''
        })
            .unwrap()
            .then((res) => {
                refetch()
                toast({
                    description: res.response.raws.success_message,
                    position: 'top',
                    status: 'success',
                    duration: parseInt(REACT_APP_TIMEOUT || '3000', 10),
                    isClosable: false
                })
                setIsNotProfileEdit(true)
                dispatch(
                    updateLoginStatus({
                        ...auth,
                        userName: `${formData.fName} ${formData.lName}`
                    })
                )
            })
            .catch((err) => {
                toast({
                    description: err.data.response.raws.error_message,
                    position: 'top',
                    status: 'error',
                    duration: parseInt(REACT_APP_TIMEOUT || '3000', 10),
                    isClosable: false
                })
            })
            .finally(() => {
                setIsSubmittingProfileUpdate(false)
            })
    }

    useEffect(() => {
        if (data) {
            const totalData = data.response.raws.data.dataset
            const getContactFaxExt =
                totalData.contact_phone_number === null
                    ? ''
                    : totalData.contact_phone_number
                          .split('')
                          .splice(-10, 3)
                          .join('')
                          .toString()
            const getContactFax =
                totalData.contact_phone_number === null
                    ? ''
                    : totalData.contact_phone_number
                          .split('')
                          .splice(-7)
                          .join('')
                          .toString()

            setValue('fName', totalData.first_name)
            setValue('lName', totalData.last_name)
            setValue('contactFax', getContactFax)
            setValue('contactFaxExt', getContactFaxExt)
            setValue('contactFaxCountryCode', '+1')
            setPhone({
                contactFaxCountryCode: '+1',
                contactFax: getContactFax,
                contactFaxExt: getContactFaxExt
            })
        }
    }, [data, isNotProfileEdit])

    const emailRegex = useMemo(
        () => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/i,
        []
    )

    const alternateEmailSchema = yup.object().shape({
        alternateEmail: yup
            .string()
            .required('Email is required')
            .matches(emailRegex, 'Enter Valid Email')
    })

    const {
        register: alternateEmailRegister,
        formState: { errors: alternateEmailErrors },
        handleSubmit: alternateEmailHandleSubmit,
        reset: alternateEmailReset
    } = useForm<submitAlternateEmail>({
        mode: 'onTouched',
        resolver: yupResolver(alternateEmailSchema),
        defaultValues: {
            alternateEmail: ''
        }
    })

    const handelAlternateEmail = (formData: submitAlternateEmail) => {
        setIsLoadingAlternateEmail(true)
        setIsDisableAlternateEmail(true)

        changeEmail({
            email: formData.alternateEmail
        })
            .unwrap()
            .then((res) => {
                setAlternateEmailSucMessage(res.response.raws.success_message)
                alternateEmailReset()
                sendOtp()
                refetch()
                if (REACT_APP_TIMEOUT) {
                    timeout.current = setTimeout(() => {
                        setAlternateEmailSucMessage('')
                        setIsDisableAlternateEmail(false)
                        onEmailEditClose()
                    }, parseInt(REACT_APP_TIMEOUT, 10))
                }
            })
            .catch((err) => {
                setIsDisableAlternateEmail(false)
                setAlternateEmailErrMessage(
                    err.data.response.raws.error_message
                )
                if (REACT_APP_TIMEOUT) {
                    timeout.current = setTimeout(() => {
                        setAlternateEmailErrMessage('')
                    }, parseInt(REACT_APP_TIMEOUT, 10))
                }
            })
            .finally(() => {
                setIsLoadingAlternateEmail(false)
            })
    }

    const handelDeleteEmail = () => {
        setIsDeleteErrDisable(true)
        setIsDeleteErrLoading(true)
        deleteEmail()
            .unwrap()
            .then((res) => {
                setDeleteEmailSucMessage(res.response.raws.success_message)
                refetch()
                setTimeout(() => {
                    onEmailDeleteClose()
                    setDeleteEmailSucMessage('')
                    setIsDeleteErrDisable(false)
                }, parseInt(REACT_APP_TIMEOUT as string, 10))
            })
            .catch((err) => {
                setDeleteErrSucMessage(err.data.response.raws.error_message)
                setTimeout(() => {
                    setDeleteErrSucMessage('')
                    setIsDeleteErrDisable(false)
                }, parseInt(REACT_APP_TIMEOUT as string, 10))
            })
            .finally(() => {
                setIsDeleteErrLoading(false)
            })
    }

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isOtpVerifyOpen) {
            setResend(true)
            const myInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1)
                }
                if (seconds === 0) {
                    clearInterval(myInterval)
                    setResend(false)
                }
            }, 1000)
            return () => {
                clearInterval(myInterval)
            }
        }
    }, [seconds, isOtpVerifyOpen])

    const handleOtpChange = (e: string) => {
        setOtp(e)
        setMsg('')
    }
    const handleResend = () => {
        sendOtp()
        setResend(true)
        setSeconds(30)
    }
    const handleOtpSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault()
        if (otp.length < 4) {
            setMsg('Please enter 4 digit otp')
        } else {
            setIsLoading(true)
            submitOtp({ otp })
                .unwrap()
                .then((res) => {
                    refetch()
                    onOtpVerifyClose()
                })
                .catch((err) => {
                    setMsg(err.data.response.raws.error_message)
                    if (REACT_APP_TIMEOUT) {
                        setTimeout(() => {
                            setMsg('')
                        }, parseInt(REACT_APP_TIMEOUT, 10))
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }
    const onError = () =>
        !!window.navigator.vibrate && window.navigator.vibrate(300)

    return (
        <Box>
            <Box
                bg={
                    globalState.darkMode
                        ? 'darkModeBoxBackgroundColor'
                        : 'white'
                }
                border={
                    globalState.darkMode
                        ? '1px solid var(--chakra-colors-darkModeBordercolor)'
                        : '1px solid var(--chakra-colors-dataTableRowBorder)'
                }
                borderRadius='10px'
                fontFamily='Montserrat,sans-serif'
                boxShadow={
                    globalState.darkMode
                        ? 'none'
                        : '0px 2px 5px var(--chakra-colors-veryLightGrayColor)'
                }
                mb='35px'
            >
                <HStack
                    bg={
                        globalState.darkMode
                            ? 'var(--chakra-colors-mainDarkModeBackgroundColor)'
                            : 'formHeadingBgColor'
                    }
                    justifyContent='space-between'
                    borderRadius='10px 10px 0 0'
                >
                    <Heading
                        p='13px 18px'
                        color={
                            globalState.darkMode
                                ? 'white'
                                : 'leftPanelFontColor'
                        }
                        fontWeight='500'
                        fontSize='1.5rem'
                        fontFamily='Montserrat, sans-serif'
                    >
                        My Profile
                    </Heading>
                </HStack>
                <Box p={{ base: '16px', lg: '24px' }}>
                    <Stack
                        direction={['column', 'row']}
                        flexWrap='wrap'
                        justifyContent='space-between'
                        spacing='0px'
                        mb='24px'
                    >
                        <Box
                            width={{ base: '100%', lg: '24%' }}
                            mb={{ base: '15px', lg: 'unset' }}
                            p='20px 24px'
                            bg={
                                globalState.darkMode
                                    ? 'var(--chakra-colors-mainDarkModeBackgroundColor)'
                                    : 'white'
                            }
                            border={
                                globalState.darkMode
                                    ? '1px solid var(--chakra-colors-darkModeBordercolor)'
                                    : '1px solid var(--chakra-colors-dataTableRowBorder)'
                            }
                            boxShadow={
                                globalState.darkMode
                                    ? 'none'
                                    : '0px 2px 5px var(--chakra-colors-boxShadowLightBlueColor)'
                            }
                            borderRadius='10px'
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                        >
                            <Box
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                            >
                                <Box>
                                    <Avatar
                                        name={userName}
                                        src={previmgSrc}
                                        onLoad={() => setIsImageLoading(false)}
                                        height='132px'
                                        width='132px'
                                        background='#F1F7FE'
                                        border='8px solid'
                                        borderColor='#D3E1DE'
                                        borderRadius='50%'
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                        fontSize='4.2rem !important'
                                        fontWeight='700'
                                        color={
                                            globalState.darkMode
                                                ? 'lfgMainLightBlue'
                                                : 'lfgMainBlue'
                                        }
                                        mb='10px'
                                        pos='relative'
                                    >
                                        {isImageLoading && (
                                            <Spinner
                                                color={
                                                    globalState.darkMode
                                                        ? 'lfgMainLightBlue'
                                                        : 'lfgMainBlue'
                                                }
                                                pos='absolute'
                                                left='0'
                                                top='0'
                                                right='0'
                                                bottom='0'
                                                margin='auto'
                                                height='20px'
                                                width='20px'
                                            />
                                        )}
                                    </Avatar>
                                </Box>
                                <Box display='flex'>
                                    {data?.response.raws.data.dataset
                                        .profile_image_path && (
                                        <>
                                            <Box
                                                background='editIconBgColor'
                                                color='iconBlackColor'
                                                width={{
                                                    base: '45px',
                                                    lg: '32px'
                                                }}
                                                height={{
                                                    base: '45px',
                                                    lg: '32px'
                                                }}
                                                display='flex'
                                                alignItems='center'
                                                justifyContent='center'
                                                borderRadius='50%'
                                                cursor='pointer'
                                                mr='6px'
                                                onClick={() => {
                                                    onImageOpen()
                                                    setIsReUploadImage(true)
                                                }}
                                            >
                                                <svg
                                                    className='icon'
                                                    width='16px'
                                                    height='14px'
                                                >
                                                    <use href='/images/icons.svg#icon-edit' />
                                                </svg>
                                            </Box>

                                            <Box
                                                background='lfgMainRedColor'
                                                color='white'
                                                width={{
                                                    base: '45px',
                                                    lg: '32px'
                                                }}
                                                height={{
                                                    base: '45px',
                                                    lg: '32px'
                                                }}
                                                display='flex'
                                                alignItems='center'
                                                justifyContent='center'
                                                borderRadius='50%'
                                                cursor='pointer'
                                                mr='6px'
                                                onClick={onDeleteOpen}
                                            >
                                                <svg
                                                    className='icon'
                                                    width='16px'
                                                    height='14px'
                                                >
                                                    <use href='/images/icons.svg#icon-delete' />
                                                </svg>
                                            </Box>
                                        </>
                                    )}
                                    {!data?.response.raws.data.dataset
                                        .profile_image_path && (
                                        <Box
                                            background='editIconBgColor'
                                            color='iconBlackColor'
                                            width={{ base: '45px', lg: '32px' }}
                                            height={{
                                                base: '45px',
                                                lg: '32px'
                                            }}
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='center'
                                            borderRadius='50%'
                                            cursor='pointer'
                                            onClick={onImageOpen}
                                        >
                                            <svg
                                                className='icon'
                                                width='16px'
                                                height='14px'
                                            >
                                                <use href='/images/icons.svg#icon-camera' />
                                            </svg>
                                        </Box>
                                    )}

                                    <Modal
                                        isOpen={isImageOpen}
                                        onClose={() => {
                                            onImageClose()
                                            setIsReUploadImage(false)
                                            setImgSrc(previmgSrc)
                                        }}
                                    >
                                        <ModalOverlay />
                                        <ModalContent
                                            minWidth={{
                                                base: '90%',
                                                lg: '550px'
                                            }}
                                            p='40px 0'
                                            borderRadius='10px'
                                            minHeight='auto'
                                            pos='absolute'
                                            left='50%'
                                            top='50%'
                                            transform='translate(-50%, -50%) !important'
                                            overflow='none'
                                            bg={
                                                globalState.darkMode
                                                    ? 'darkModeBoxBackgroundColor'
                                                    : 'white'
                                            }
                                        >
                                            <ModalCloseButton
                                                color={
                                                    globalState.darkMode
                                                        ? 'inputplaceholderColor'
                                                        : 'leftPanelFontColor'
                                                }
                                            />
                                            <ModalBody
                                                w='90%'
                                                m='0 auto'
                                                textAlign='center'
                                            >
                                                <Text
                                                    fontSize='2rem'
                                                    fontWeight='600'
                                                    textAlign='center'
                                                    pb='20px'
                                                    color={
                                                        globalState.darkMode
                                                            ? 'var(--chakra-colors-lfgMainLightBlue)'
                                                            : 'var(--chakra-colors-lfgMainBlue)'
                                                    }
                                                >
                                                    Crop Image
                                                </Text>

                                                {(!imgSrc.toString().length &&
                                                    !isReUploadImage) ||
                                                (!!imgSrc.toString().length &&
                                                    isReUploadImage) ? (
                                                    <Box
                                                        border='1px dashed'
                                                        borderColor='#727E97'
                                                        borderRadius='4px'
                                                        display='flex'
                                                        flexDirection='column'
                                                        alignItems='center'
                                                        p='20px 0px'
                                                    >
                                                        <Box
                                                            mt='18px'
                                                            mb='11px'
                                                        >
                                                            <svg
                                                                className='icon'
                                                                width='32px'
                                                                height='26px'
                                                                color='#727E97'
                                                            >
                                                                <use href='/images/icons.svg#icon-fileUpload' />
                                                            </svg>
                                                        </Box>

                                                        <Box
                                                            as='p'
                                                            fontSize='1.3rem'
                                                            fontWeight='500'
                                                            color='#727E97'
                                                        >
                                                            Please Chose An
                                                            Image
                                                        </Box>

                                                        <FormLabel
                                                            htmlFor='file-input'
                                                            mt='17px'
                                                        >
                                                            <Box
                                                                className='save_btn image-upload'
                                                                background={
                                                                    globalState.darkMode
                                                                        ? 'lfgMainLightBlue'
                                                                        : 'lfgMainBlue'
                                                                }
                                                                color='white'
                                                                width='104px'
                                                                height='32px'
                                                                display='flex'
                                                                alignItems='center'
                                                                justifyContent='center'
                                                                borderRadius='5px'
                                                                cursor='pointer'
                                                                fontSize='1.3rem'
                                                                fontWeight='600'
                                                                sx={{
                                                                    '&>input': {
                                                                        display:
                                                                            'none'
                                                                    }
                                                                }}
                                                            >
                                                                Choose File
                                                                <Input
                                                                    type='file'
                                                                    id='file-input'
                                                                    lineHeight='32px !important'
                                                                    borderRadius='3px'
                                                                    accept='image/png, image/gif, image/jpeg'
                                                                    size='sm'
                                                                    placeholder='Select an image'
                                                                    onChange={
                                                                        tempImageUrl
                                                                    }
                                                                />
                                                            </Box>
                                                        </FormLabel>
                                                    </Box>
                                                ) : (
                                                    <ReactCrop
                                                        crop={crop}
                                                        onChange={(c) =>
                                                            setCrop(c)
                                                        }
                                                        aspect={aspect}
                                                        minWidth={minSize.width}
                                                        minHeight={
                                                            minSize.height
                                                        }
                                                        onComplete={(
                                                            crp: PixelCrop
                                                        ) => {
                                                            onCropComplete(crp)
                                                        }}
                                                    >
                                                        <img
                                                            ref={imageRef}
                                                            src={imgSrc}
                                                            onLoad={onImageLoad}
                                                            alt=''
                                                        />
                                                    </ReactCrop>
                                                )}
                                                <Box
                                                    pos='relative'
                                                    textAlign='center'
                                                    w='100%'
                                                >
                                                    <Text
                                                        fontSize='1.2rem'
                                                        fontWeight='700'
                                                        pos='absolute'
                                                        left='0'
                                                        right='0'
                                                        margin='auto'
                                                        bottom='-25px'
                                                        color='red.500'
                                                    >
                                                        {errorMessage}
                                                    </Text>
                                                    <Text
                                                        fontSize='1.2rem'
                                                        fontWeight='700'
                                                        pos='absolute'
                                                        left='0'
                                                        right='0'
                                                        margin='auto'
                                                        bottom='-25px'
                                                        color='successMessageColor'
                                                    >
                                                        {successMessage}
                                                    </Text>
                                                </Box>
                                            </ModalBody>
                                            {(!imgSrc.toString().length &&
                                                isReUploadImage) ||
                                            (!!imgSrc.toString().length &&
                                                !isReUploadImage) ? (
                                                <ModalFooter
                                                    justifyContent='center'
                                                    p='25px 0 0'
                                                >
                                                    <Stack
                                                        direction='row'
                                                        justifyContent='center'
                                                        spacing='10px'
                                                    >
                                                        <Ripples color='var(--chakra-colors-lightWhiteColor)'>
                                                            <Button
                                                                className='cancel_btn'
                                                                onClick={() => {
                                                                    onImageClose()
                                                                    setIsReUploadImage(
                                                                        false
                                                                    )
                                                                    setImgSrc(
                                                                        previmgSrc
                                                                    )
                                                                }}
                                                            >
                                                                Cancel{' '}
                                                            </Button>
                                                        </Ripples>
                                                        <Ripples color='var(--chakra-colors-lightWhiteColor)'>
                                                            <ButtonLoader
                                                                buttonCLass='save_btn'
                                                                disabled={
                                                                    buttonLoading
                                                                }
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                onClickFnc={() => {
                                                                    setButtonLoading(
                                                                        true
                                                                    )
                                                                    setIsLoading(
                                                                        true
                                                                    )
                                                                    updateProfileImage(
                                                                        {
                                                                            profile_image:
                                                                                croppedImageUrl
                                                                        }
                                                                    )
                                                                        .unwrap()
                                                                        .then(
                                                                            (
                                                                                res
                                                                            ) => {
                                                                                setIsLoading(
                                                                                    false
                                                                                )
                                                                                refetch()
                                                                                setPrevImgSrc(
                                                                                    ''
                                                                                )
                                                                                onImageClose()
                                                                                setButtonLoading(
                                                                                    false
                                                                                )
                                                                                setIsImageLoading(
                                                                                    true
                                                                                )
                                                                            }
                                                                        )
                                                                        .catch(
                                                                            (
                                                                                err
                                                                            ) => {
                                                                                setErrorMessage(
                                                                                    err
                                                                                        .data
                                                                                        .response
                                                                                        .raws
                                                                                        .error_message
                                                                                )
                                                                                setIsLoading(
                                                                                    false
                                                                                )
                                                                                setTimeout(
                                                                                    () => {
                                                                                        setErrorMessage(
                                                                                            ''
                                                                                        )
                                                                                        setButtonLoading(
                                                                                            false
                                                                                        )
                                                                                    },
                                                                                    parseInt(
                                                                                        REACT_APP_TIMEOUT as string,
                                                                                        10
                                                                                    )
                                                                                )
                                                                            }
                                                                        )
                                                                }}
                                                            >
                                                                Save
                                                            </ButtonLoader>
                                                        </Ripples>
                                                    </Stack>
                                                </ModalFooter>
                                            ) : (
                                                ''
                                            )}
                                        </ModalContent>
                                    </Modal>

                                    <Modal
                                        isOpen={isDeleteOpen}
                                        onClose={onDeleteClose}
                                        isCentered
                                        motionPreset='slideInBottom'
                                    >
                                        <ModalOverlay bg='var(--chakra-colors-boxShadowDeepBlackColor)' />
                                        <ModalContent
                                            minWidth={{
                                                base: '90%',
                                                lg: '500px'
                                            }}
                                            p='20px 0 25px'
                                            borderRadius='10px'
                                            bg={
                                                globalState.darkMode
                                                    ? 'darkModeBoxBackgroundColor'
                                                    : 'white'
                                            }
                                        >
                                            <ModalCloseButton
                                                color={
                                                    globalState.darkMode
                                                        ? 'inputplaceholderColor'
                                                        : 'leftPanelFontColor'
                                                }
                                            />
                                            <ModalBody>
                                                <Box
                                                    bg='lfgMainRedColor'
                                                    w='60px'
                                                    h='60px'
                                                    textAlign='center'
                                                    lineHeight='7.3rem'
                                                    borderRadius='100%'
                                                    p='0'
                                                    m='0 auto 25px'
                                                    sx={{
                                                        '& > *': {
                                                            display:
                                                                'inline-block'
                                                        }
                                                    }}
                                                >
                                                    <svg
                                                        className='icon'
                                                        width='21px'
                                                        height='26px'
                                                        color='white'
                                                    >
                                                        <use href='/images/icons.svg#icon-delete' />
                                                    </svg>
                                                </Box>
                                                <Text
                                                    color={
                                                        globalState.darkMode
                                                            ? 'inputplaceholderColor'
                                                            : 'leftPanelFontColor'
                                                    }
                                                    fontWeight='600'
                                                    fontSize='2rem'
                                                    lineHeight='2.8rem'
                                                    textAlign='center'
                                                    w='65%'
                                                    margin='0 auto'
                                                    textTransform='capitalize'
                                                >
                                                    Do you really want to remove
                                                    the profile picture?
                                                </Text>
                                                <Box
                                                    pos='relative'
                                                    textAlign='center'
                                                    w='100%'
                                                >
                                                    <Text
                                                        fontSize='1.2rem'
                                                        fontWeight='700'
                                                        pos='absolute'
                                                        left='0'
                                                        right='0'
                                                        margin='auto'
                                                        bottom='-25px'
                                                        color='red.500'
                                                    >
                                                        {errorMessage}
                                                    </Text>
                                                    <Text
                                                        fontSize='1.2rem'
                                                        fontWeight='700'
                                                        pos='absolute'
                                                        left='0'
                                                        right='0'
                                                        margin='auto'
                                                        bottom='-25px'
                                                        color='successMessageColor'
                                                    >
                                                        {successMessage}
                                                    </Text>
                                                </Box>
                                            </ModalBody>

                                            <ModalFooter
                                                justifyContent='center'
                                                p='25px 0 0'
                                            >
                                                <Stack
                                                    direction='row'
                                                    justifyContent='center'
                                                    spacing='10px'
                                                >
                                                    <Ripples color='var(--chakra-colors-lightWhiteColor)'>
                                                        <Button
                                                            className='cancel_btn'
                                                            onClick={
                                                                onDeleteClose
                                                            }
                                                        >
                                                            Cancel{' '}
                                                        </Button>
                                                    </Ripples>
                                                    <Ripples color='var(--chakra-colors-lightWhiteColor)'>
                                                        <ButtonLoader
                                                            buttonCLass='delete_btn'
                                                            disabled={
                                                                buttonLoading
                                                            }
                                                            isLoading={
                                                                isLoading
                                                            }
                                                            onClickFnc={() => {
                                                                setButtonLoading(
                                                                    true
                                                                )
                                                                setIsLoading(
                                                                    true
                                                                )
                                                                deleteProfileImage()
                                                                    .unwrap()
                                                                    .then(
                                                                        (
                                                                            res
                                                                        ) => {
                                                                            setSuccessMessage(
                                                                                res
                                                                                    .response
                                                                                    .raws
                                                                                    .success_message
                                                                            )
                                                                            setIsLoading(
                                                                                false
                                                                            )
                                                                            refetch()
                                                                            setTimeout(
                                                                                () => {
                                                                                    onDeleteClose()
                                                                                    setSuccessMessage(
                                                                                        ''
                                                                                    )
                                                                                    setButtonLoading(
                                                                                        false
                                                                                    )
                                                                                },
                                                                                parseInt(
                                                                                    REACT_APP_TIMEOUT as string,
                                                                                    10
                                                                                )
                                                                            )
                                                                        }
                                                                    )
                                                                    .catch(
                                                                        (
                                                                            err
                                                                        ) => {
                                                                            setErrorMessage(
                                                                                err
                                                                                    .data
                                                                                    .response
                                                                                    .raws
                                                                                    .error_message
                                                                            )
                                                                            setIsLoading(
                                                                                false
                                                                            )
                                                                            setTimeout(
                                                                                () => {
                                                                                    setErrorMessage(
                                                                                        ''
                                                                                    )
                                                                                    setButtonLoading(
                                                                                        false
                                                                                    )
                                                                                },
                                                                                parseInt(
                                                                                    REACT_APP_TIMEOUT as string,
                                                                                    10
                                                                                )
                                                                            )
                                                                        }
                                                                    )
                                                            }}
                                                        >
                                                            Delete
                                                        </ButtonLoader>
                                                    </Ripples>
                                                </Stack>
                                            </ModalFooter>
                                        </ModalContent>
                                    </Modal>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            width={{ base: '100%', lg: '74%' }}
                            display='flex'
                            bg={
                                globalState.darkMode
                                    ? 'var(--chakra-colors-mainDarkModeBackgroundColor)'
                                    : 'white'
                            }
                            alignItems='center'
                            p='34px 24px'
                            border={
                                globalState.darkMode
                                    ? '1px solid var(--chakra-colors-darkModeBordercolor)'
                                    : '1px solid var(--chakra-colors-dataTableRowBorder)'
                            }
                            boxShadow={
                                globalState.darkMode
                                    ? 'none'
                                    : '0px 2px 5px var(--chakra-colors-boxShadowLightBlueColor)'
                            }
                            borderRadius='10px'
                        >
                            <Box width='100%'>
                                <form
                                    onSubmit={userDetailsHandleSubmit(
                                        handelUserDetailsChange,
                                        onError
                                    )}
                                >
                                    <Stack
                                        direction={['column', 'row']}
                                        flexWrap='wrap'
                                        justifyContent='space-between'
                                        spacing='0px'
                                    >
                                        <FormControl
                                            isInvalid={
                                                !!userDetailsErrors.fName
                                            }
                                            sx={{
                                                '& input.chakra-input': {
                                                    height: '40px',
                                                    background: `${
                                                        globalState.darkMode
                                                            ? 'mainDarkModeBackgroundColor'
                                                            : 'white'
                                                    }`
                                                },
                                                '& input.chakra-input:disabled':
                                                    {
                                                        height: '40px',
                                                        background: `${
                                                            globalState.darkMode
                                                                ? 'mainDarkModeLightBackgroundColor'
                                                                : 'inputDisabledColor'
                                                        }`,
                                                        border: `${
                                                            globalState.darkMode
                                                                ? 'none'
                                                                : '1px solid var(--chakra-colors-inputStrokeColor)'
                                                        }`
                                                    }
                                            }}
                                            width={{ base: '100%', md: '32%' }}
                                            mb={{ base: '15px', lg: 'unset' }}
                                        >
                                            <FormLabel
                                                color={
                                                    globalState.darkMode
                                                        ? 'inputplaceholderColor'
                                                        : 'leftPanelFontColor'
                                                }
                                                fontSize='1.3rem'
                                                fontWeight='600'
                                            >
                                                First Name
                                            </FormLabel>
                                            <Input
                                                {...userDetailsRegister(
                                                    'fName'
                                                )}
                                                id='first-name'
                                                placeholder='Enter First Name'
                                                disabled={isNotProfileEdit}
                                            />
                                            <FormErrorMessage>
                                                {
                                                    userDetailsErrors.fName
                                                        ?.message
                                                }
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl
                                            isInvalid={
                                                !!userDetailsErrors.lName
                                            }
                                            width={{ base: '100%', md: '32%' }}
                                            mr='20px'
                                            mb={{
                                                base: '15px !important',
                                                lg: 'unset'
                                            }}
                                            sx={{
                                                '& input.chakra-input': {
                                                    height: '40px',
                                                    background: `${
                                                        globalState.darkMode
                                                            ? 'mainDarkModeBackgroundColor'
                                                            : 'white'
                                                    }`
                                                },
                                                '& input.chakra-input:disabled':
                                                    {
                                                        height: '40px',
                                                        background: `${
                                                            globalState.darkMode
                                                                ? 'mainDarkModeLightBackgroundColor'
                                                                : 'inputDisabledColor'
                                                        }`,
                                                        border: `${
                                                            globalState.darkMode
                                                                ? 'none'
                                                                : '1px solid var(--chakra-colors-inputStrokeColor)'
                                                        }`
                                                    }
                                            }}
                                        >
                                            <FormLabel
                                                color={
                                                    globalState.darkMode
                                                        ? 'inputplaceholderColor'
                                                        : 'leftPanelFontColor'
                                                }
                                                fontSize='1.3rem'
                                                fontWeight='600'
                                            >
                                                Last Name
                                            </FormLabel>
                                            <Input
                                                {...userDetailsRegister(
                                                    'lName'
                                                )}
                                                placeholder='Enter Last Name'
                                                disabled={isNotProfileEdit}
                                            />
                                            <FormErrorMessage>
                                                {
                                                    userDetailsErrors.lName
                                                        ?.message
                                                }
                                            </FormErrorMessage>
                                        </FormControl>

                                        <Box
                                            width={{ base: '100%', md: '32%' }}
                                        >
                                            <FormLabel
                                                color={
                                                    globalState.darkMode
                                                        ? 'inputplaceholderColor'
                                                        : 'leftPanelFontColor'
                                                }
                                                fontSize='1.3rem'
                                                fontWeight='600'
                                            >
                                                Contact Number
                                            </FormLabel>
                                            <Box
                                                display='flex'
                                                justifyContent={{
                                                    base: 'space-between',
                                                    md: 'flex-start'
                                                }}
                                            >
                                                <FormControl
                                                    width={{
                                                        base: '30%',
                                                        md: '44%',
                                                        lg: '23%'
                                                    }}
                                                    height='40px'
                                                    minW='40px'
                                                    position='unset'
                                                    marginRight={{
                                                        base: '0px',
                                                        md: '10px'
                                                    }}
                                                    sx={{
                                                        '& > input.chakra-input:disabled':
                                                            {
                                                                height: '40px',
                                                                background: `${
                                                                    globalState.darkMode
                                                                        ? 'mainDarkModeLightBackgroundColor'
                                                                        : 'inputDisabledColor'
                                                                }`,
                                                                border: `${
                                                                    globalState.darkMode
                                                                        ? 'none'
                                                                        : '1px solid var(--chakra-colors-inputStrokeColor)'
                                                                }`
                                                            }
                                                    }}
                                                >
                                                    <Input
                                                        {...userDetailsRegister(
                                                            'contactFaxCountryCode'
                                                        )}
                                                        defaultValue='+1'
                                                        placeholder='+1'
                                                        disabled
                                                        maxW={{
                                                            base: '100%',
                                                            md: '34px'
                                                        }}
                                                        bg='var(--chakra-colors-buttonDisableColor)'
                                                        color='var(--chakra-colors-leftPanelFontColor)'
                                                    />
                                                </FormControl>

                                                <FormControl
                                                    isInvalid={
                                                        !!userDetailsErrors.contactFaxExt
                                                    }
                                                    maxW={{
                                                        base: '30%',
                                                        md: '45%',
                                                        lg: '100%'
                                                    }}
                                                    marginRight={{
                                                        base: '0px',
                                                        md: '10px'
                                                    }}
                                                    sx={{
                                                        '& input.p-inputtext': {
                                                            width: '100%'
                                                        },
                                                        '&.error > input': {
                                                            borderColor:
                                                                'var(--chakra-colors-lfgMainRedColor)',
                                                            boxShadow:
                                                                '0 0 0 1px var(--chakra-colors-lfgMainRedColor)'
                                                        },
                                                        '&.p-disabled, .p-component:disabled':
                                                            {
                                                                height: '40px'
                                                            },
                                                        '& > input.chakra-input:disabled':
                                                            {
                                                                height: '40px'
                                                            }
                                                    }}
                                                    className={
                                                        userDetailsErrors.contactFaxExt
                                                            ? 'error'
                                                            : ''
                                                    }
                                                >
                                                    <InputMask
                                                        {...userDetailsRegister(
                                                            'contactFaxExt',
                                                            {
                                                                onChange: (
                                                                    e
                                                                ) => {
                                                                    handleNumberChange(
                                                                        e
                                                                    )
                                                                }
                                                            }
                                                        )}
                                                        placeholder='Area Code'
                                                        mask='999'
                                                        value={
                                                            phone.contactFaxExt
                                                        }
                                                        disabled={
                                                            isNotProfileEdit
                                                        }
                                                        // flex='0 0 30%'
                                                        // margin='0 10px'
                                                    />
                                                    <FormErrorMessage>
                                                        {
                                                            userDetailsErrors
                                                                .contactFaxExt
                                                                ?.message
                                                        }
                                                    </FormErrorMessage>
                                                </FormControl>

                                                <FormControl
                                                    isInvalid={
                                                        !!userDetailsErrors.contactFax
                                                    }
                                                    maxW={{
                                                        base: '30%',
                                                        md: '45%',
                                                        lg: '100%'
                                                    }}
                                                    marginRight={{
                                                        base: '0px',
                                                        md: '0px'
                                                    }}
                                                    sx={{
                                                        '& input.p-inputtext': {
                                                            width: '100%'
                                                        },
                                                        '&.error > input': {
                                                            borderColor:
                                                                'var(--chakra-colors-lfgMainRedColor)',
                                                            boxShadow:
                                                                '0 0 0 1px var(--chakra-colors-lfgMainRedColor)'
                                                        },
                                                        '&.p-disabled, .p-component:disabled':
                                                            {
                                                                height: '40px'
                                                            },
                                                        '& > input.chakra-input:disabled':
                                                            {
                                                                height: '40px'
                                                            }
                                                    }}
                                                    className={
                                                        userDetailsErrors.contactFax
                                                            ? 'error'
                                                            : ''
                                                    }
                                                >
                                                    <InputMask
                                                        {...userDetailsRegister(
                                                            'contactFax',
                                                            {
                                                                onChange: (
                                                                    e
                                                                ) => {
                                                                    handleNumberChange(
                                                                        e
                                                                    )
                                                                }
                                                            }
                                                        )}
                                                        id='phoneext'
                                                        mask='999-9999'
                                                        value={phone.contactFax}
                                                        placeholder='Enter Phone Number'
                                                        disabled={
                                                            isNotProfileEdit
                                                        }
                                                    />
                                                    <FormErrorMessage>
                                                        {
                                                            userDetailsErrors
                                                                .contactFax
                                                                ?.message
                                                        }
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    </Stack>
                                    <Stack
                                        direction='row'
                                        justifyContent='flex-end'
                                        spacing='10px'
                                        p='34px 0px 0'
                                    >
                                        {isNotProfileEdit ? (
                                            <Ripples color='var(--chakra-colors-lightWhiteColor)'>
                                                <Button
                                                    onClick={() => {
                                                        setIsNotProfileEdit(
                                                            false
                                                        )
                                                    }}
                                                    className='cancel_btn'
                                                >
                                                    EDIT
                                                </Button>
                                            </Ripples>
                                        ) : (
                                            <Box>
                                                {' '}
                                                <Ripples color='var(--chakra-colors-lightWhiteColor)'>
                                                    <Button
                                                        mr='10px'
                                                        onClick={() => {
                                                            setIsNotProfileEdit(
                                                                true
                                                            )
                                                            clearErrors()
                                                        }}
                                                        className='cancel_btn'
                                                        disabled={
                                                            isSubmittingProfileUpdate
                                                        }
                                                    >
                                                        CANCEL
                                                    </Button>
                                                </Ripples>
                                                <Ripples color='var(--chakra-colors-lightWhiteColor)'>
                                                    <Button
                                                        type='submit'
                                                        className='save_btn'
                                                        isLoading={
                                                            isSubmittingProfileUpdate
                                                        }
                                                        disabled={
                                                            isSubmittingProfileUpdate
                                                        }
                                                    >
                                                        save
                                                    </Button>
                                                </Ripples>
                                            </Box>
                                        )}
                                    </Stack>
                                </form>
                            </Box>
                        </Box>
                    </Stack>
                    <VStack
                        border={
                            globalState.darkMode
                                ? '1px solid var(--chakra-colors-darkModeBordercolor)'
                                : '1px solid var(--chakra-colors-dataTableRowBorder)'
                        }
                        bg={
                            globalState.darkMode
                                ? 'var(--chakra-colors-mainDarkModeBackgroundColor)'
                                : 'white'
                        }
                        borderRadius='10px'
                        boxShadow={
                            globalState.darkMode
                                ? 'none'
                                : '0px 2px 5px var(--chakra-colors-veryLightGrayColor)'
                        }
                        mb='24px'
                    >
                        <Box
                            width='100%'
                            display='flex'
                            justifyContent='space-between'
                            alignItems={{ base: 'center', lg: 'center' }}
                            p={{ base: '10px', lg: '32px' }}
                            boxShadow={
                                globalState.darkMode
                                    ? 'none'
                                    : '0px 2px 5px var(--chakra-colors-veryLightGrayColor)'
                            }
                        >
                            <Box>
                                <Text
                                    color={
                                        globalState.darkMode
                                            ? 'inputplaceholderColor'
                                            : 'breadCrumbTextColor'
                                    }
                                    fontWeight='600'
                                    fontSize='1.3rem'
                                >
                                    Email Address
                                </Text>

                                <Box
                                    display='flex'
                                    alignItems='center'
                                    flexWrap='wrap'
                                >
                                    <Text
                                        color={
                                            globalState.darkMode
                                                ? 'white'
                                                : 'leftPanelFontColor'
                                        }
                                        fontWeight='500'
                                        fontSize={{
                                            base: '1.4rem',
                                            lg: '1.6rem'
                                        }}
                                        mr='15px'
                                        wordBreak='break-all'
                                        mb={{ base: '10px', lg: 'unset' }}
                                    >
                                        {data &&
                                            data.response.raws.data.dataset
                                                .primary_email}
                                    </Text>
                                    <Text
                                        bg='checkGreenColor'
                                        border='1px solid'
                                        borderColor='stockGreenColor'
                                        borderRadius='3px'
                                        padding='3px 10px'
                                        fontSize='1.2rem'
                                        fontWeight='500'
                                        color='white'
                                    >
                                        Active
                                    </Text>
                                </Box>
                            </Box>
                            <Box>
                                {data &&
                                    !data.response.raws.data.dataset
                                        .proposed_email && (
                                        <Box
                                            bg='editIconBgColorLight'
                                            w='35px'
                                            height='35px'
                                            display='flex'
                                            alignItems={{
                                                base: 'center',
                                                lg: 'center'
                                            }}
                                            justifyContent='center'
                                            borderRadius='50px'
                                            color={
                                                globalState.darkMode
                                                    ? 'lfgMainLightBlue'
                                                    : 'lfgMainBlue'
                                            }
                                            cursor='pointer'
                                            transition='0.3s ease all'
                                            _hover={{
                                                bg: 'lfgMainBlue',
                                                color: 'white'
                                            }}
                                            onClick={() => {
                                                onEmailEditOpen()
                                                setEmailType('Add')
                                                alternateEmailReset()
                                                if (timeout.current) {
                                                    setChangePasswordSucMessage(
                                                        ''
                                                    )
                                                    setChangePasswordErrMessage(
                                                        ''
                                                    )
                                                    setIsDisableChangePassword(
                                                        false
                                                    )
                                                    clearTimeout(
                                                        timeout.current
                                                    )
                                                }
                                            }}
                                        >
                                            <svg
                                                className='icon'
                                                width='16px'
                                                height='14px'
                                            >
                                                <use href='/images/icons.svg#icon-edit' />
                                            </svg>
                                        </Box>
                                    )}
                            </Box>

                            <Modal
                                isOpen={isEmailEditOpen}
                                onClose={() => {
                                    onEmailEditClose()
                                    alternateEmailReset()
                                    if (timeout.current) {
                                        setAlternateEmailSucMessage('')
                                        setAlternateEmailErrMessage('')
                                        setIsDisableAlternateEmail(false)
                                        clearTimeout(timeout.current)
                                    }
                                }}
                            >
                                <ModalOverlay />
                                <ModalContent
                                    minWidth={{ base: '90%', lg: '400px' }}
                                    p='0'
                                    m='0px'
                                    borderRadius='10px'
                                    minHeight='250px'
                                    pos='absolute'
                                    left='50%'
                                    top='50%'
                                    transform='translate(-50%, -50%) !important'
                                    bg={
                                        globalState.darkMode
                                            ? 'darkModeBoxBackgroundColor'
                                            : 'white'
                                    }
                                    overflow='none'
                                >
                                    <ModalHeader>
                                        <Text
                                            fontSize='2rem'
                                            fontWeight='600'
                                            textAlign='center'
                                            pb='14px'
                                            color={
                                                globalState.darkMode
                                                    ? 'lfgMainLightBlue'
                                                    : 'lfgMainBlue'
                                            }
                                            mt='36px'
                                        >
                                            {`${emailType} New Email Address`}
                                        </Text>
                                    </ModalHeader>
                                    <ModalCloseButton
                                        color={
                                            globalState.darkMode
                                                ? 'inputplaceholderColor'
                                                : 'leftPanelFontColor'
                                        }
                                    />

                                    <form
                                        onSubmit={alternateEmailHandleSubmit(
                                            handelAlternateEmail,
                                            onError
                                        )}
                                    >
                                        <ModalBody
                                            px='24px'
                                            width='90%'
                                            m='0px auto 0px'
                                        >
                                            <Stack
                                                direction='column'
                                                spacing='40px'
                                                sx={{
                                                    '& > *:nth-of-type(2)': {
                                                        marginTop: '4px'
                                                    },
                                                    '& > *:nth-of-type(3)': {
                                                        marginTop: '4px'
                                                    }
                                                }}
                                            >
                                                <FormControl
                                                    isInvalid={
                                                        !!alternateEmailErrors.alternateEmail
                                                    }
                                                    mb='16px'
                                                    sx={{
                                                        '& input.chakra-input':
                                                            {
                                                                background: `${
                                                                    globalState.darkMode
                                                                        ? 'mainDarkModeBackgroundColor'
                                                                        : 'white'
                                                                }`
                                                            },
                                                        '& input.chakra-input:disabled':
                                                            {
                                                                background: `${
                                                                    globalState.darkMode
                                                                        ? 'mainDarkModeLightBackgroundColor'
                                                                        : 'white'
                                                                }`,
                                                                borderColor: `${
                                                                    globalState.darkMode
                                                                        ? 'var(--chakra-colors-darkModeBordercolor)'
                                                                        : 'var(--chakra-colors-inputDisabledStrokeColor)'
                                                                }`
                                                            }
                                                    }}
                                                >
                                                    <Input
                                                        id='alternateEmail'
                                                        placeholder='Enter Your Email Address'
                                                        {...alternateEmailRegister(
                                                            'alternateEmail'
                                                        )}
                                                        _placeholder={{
                                                            opacity: 1,
                                                            color: '#727E97'
                                                        }}
                                                        _focus={{
                                                            outline: 'none'
                                                        }}
                                                        w='100%'
                                                        h='40px'
                                                        errorBorderColor='red.100'
                                                        fontSize='13px'
                                                        borderRadius='3px'
                                                        color='#727E97'
                                                        fontWeight='500'
                                                    />
                                                    <FormErrorMessage>
                                                        {
                                                            alternateEmailErrors
                                                                .alternateEmail
                                                                ?.message
                                                        }
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </Stack>
                                        </ModalBody>
                                        <ModalFooter
                                            px='24px'
                                            width='90%'
                                            m='0 auto'
                                        >
                                            <Button
                                                disabled={
                                                    isDisableAlternateEmail
                                                }
                                                colorScheme='blue'
                                                mr={3}
                                                onClick={() => {
                                                    onEmailEditClose()
                                                    alternateEmailReset()
                                                }}
                                                minW='100px'
                                                h='40px'
                                            >
                                                Close
                                            </Button>
                                            <Button
                                                type='submit'
                                                disabled={
                                                    isDisableAlternateEmail
                                                }
                                                isLoading={
                                                    isLoadingAlternateEmail
                                                }
                                                _focus={{
                                                    outline: 'none'
                                                }}
                                                minW='100px'
                                                h='40px'
                                                textTransform='uppercase'
                                                bgColor={
                                                    globalState.darkMode
                                                        ? 'lfgMainLightBlue'
                                                        : 'lfgMainBlue'
                                                }
                                                rounded='5px'
                                                color='white'
                                                _hover={{
                                                    bgColor: 'lfgMainBlueHover',
                                                    color: 'white'
                                                }}
                                                fontWeight='700'
                                                textAlign='center'
                                                boxShadow={
                                                    globalState.darkMode
                                                        ? 'none'
                                                        : '0px 2px 5px var(--chakra-colors-veryLightGrayColor)'
                                                }
                                                className='login-button'
                                            >
                                                Save
                                            </Button>
                                        </ModalFooter>

                                        <FormControl
                                            isInvalid={
                                                !!alternateEmailErrMessage.length
                                            }
                                            pos='relative'
                                            textAlign='center'
                                        >
                                            <Text
                                                fontSize='1.2rem'
                                                fontWeight='700'
                                                pos='absolute'
                                                right='48px'
                                                margin='auto'
                                                bottom='58px'
                                                color='red.500'
                                            >
                                                {alternateEmailErrMessage}
                                            </Text>
                                            <Text
                                                fontSize='1.2rem'
                                                fontWeight='700'
                                                pos='absolute'
                                                right='48px'
                                                margin='auto'
                                                bottom='58px'
                                                color='successMessageColor'
                                            >
                                                {alternateEmailSucMessage}
                                            </Text>
                                        </FormControl>
                                    </form>
                                </ModalContent>{' '}
                            </Modal>
                        </Box>
                        {data &&
                            data.response.raws.data.dataset.proposed_email && (
                                <Flex
                                    w='100%'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    p={{ base: '10px', lg: '32px' }}
                                    m='0'
                                >
                                    <Box
                                        width={{ base: '60%', lg: 'auto' }}
                                        mt={{ base: '10px', lg: '0px' }}
                                        ml={{ base: '0px', lg: 'initial' }}
                                    >
                                        <Text
                                            bg='var(--chakra-colors-lfgLightYellow)'
                                            color={
                                                globalState.darkMode
                                                    ? 'inputplaceholderColor'
                                                    : 'black'
                                            }
                                            fontWeight='500'
                                            fontSize='1.2rem'
                                            maxW='140px'
                                            p='0px'
                                            mb='8px'
                                            borderRadius='3px'
                                        >
                                            Verification Pending
                                        </Text>
                                        <Text
                                            fontWeight='500'
                                            fontSize='1.6rem'
                                            color={
                                                globalState.darkMode
                                                    ? 'inputplaceholderColor'
                                                    : 'leftPanelFontColor'
                                            }
                                        >
                                            {
                                                data.response.raws.data.dataset
                                                    .proposed_email
                                            }
                                        </Text>
                                    </Box>
                                    <HStack
                                        width={{ base: '35%', lg: 'auto' }}
                                        spacing='20px'
                                        flexWrap='wrap'
                                        justifyContent='center'
                                        alignItems='center'
                                    >
                                        <Box
                                            color={
                                                globalState.darkMode
                                                    ? 'lfgMainLightBlue'
                                                    : 'lfgMainBlue'
                                            }
                                            onClick={() => {
                                                onEmailEditOpen()
                                                setEmailType('Edit')
                                                alternateEmailReset()
                                                if (timeout.current) {
                                                    setChangePasswordSucMessage(
                                                        ''
                                                    )
                                                    setChangePasswordErrMessage(
                                                        ''
                                                    )
                                                    setIsDisableChangePassword(
                                                        false
                                                    )
                                                    clearTimeout(
                                                        timeout.current
                                                    )
                                                }
                                            }}
                                        >
                                            <svg
                                                className='icon'
                                                width='16px'
                                                height='14px'
                                                cursor='pointer'
                                            >
                                                <use href='/images/icons.svg#icon-edit' />
                                            </svg>
                                        </Box>

                                        <Box
                                            onClick={() => {
                                                onEmailDeleteOpen()
                                            }}
                                            color='lfgMainRedColor'
                                        >
                                            <svg
                                                className='icon'
                                                width='16px'
                                                height='14px'
                                                cursor='pointer'
                                            >
                                                <use href='/images/icons.svg#icon-delete' />
                                            </svg>
                                        </Box>

                                        <Box
                                            mt='0px !important'
                                            ml={{
                                                base: '20px !important',
                                                lg: '20px !important'
                                            }}
                                            color={
                                                globalState.darkMode
                                                    ? 'lfgMainLightBlue'
                                                    : 'lfgMainBlue'
                                            }
                                            onClick={() => {
                                                onOtpVerifyOpen()
                                                setResend(true)
                                                setSeconds(30)
                                                sendOtp()
                                                setOtp('')
                                                setMsg('')
                                            }}
                                        >
                                            <Text
                                                bg={
                                                    globalState.darkMode
                                                        ? 'lfgMainLightBlue'
                                                        : 'lfgMainBlue'
                                                }
                                                color='white'
                                                fontSize={{
                                                    base: '1rem',
                                                    lg: '1.2rem'
                                                }}
                                                fontWeight='700'
                                                borderRadius='3px'
                                                p={{
                                                    base: '2px 5px',
                                                    lg: '4px 10px'
                                                }}
                                                cursor='pointer'
                                            >
                                                Verify Your Email
                                            </Text>
                                        </Box>
                                    </HStack>
                                </Flex>
                            )}
                        <Modal
                            isOpen={isEmailDeleteOpen}
                            onClose={onEmailDeleteClose}
                            isCentered
                            motionPreset='slideInBottom'
                        >
                            <ModalOverlay bg='var(--chakra-colors-boxShadowDeepBlackColor)' />
                            <ModalContent
                                minWidth={{ base: '90%', lg: '500px' }}
                                bg={
                                    globalState.darkMode
                                        ? 'darkModeBoxBackgroundColor'
                                        : 'white'
                                }
                                p='20px 0 25px'
                                borderRadius='10px'
                            >
                                <ModalCloseButton />
                                <ModalBody
                                    bg={
                                        globalState.darkMode
                                            ? 'darkModeBoxBackgroundColor'
                                            : 'white'
                                    }
                                >
                                    <Box
                                        w='60px'
                                        h='60px'
                                        textAlign='center'
                                        lineHeight='7.3rem'
                                        borderRadius='100%'
                                        p='0'
                                        m='0 auto 25px'
                                        sx={{
                                            '& > *': {
                                                display: 'inline-block'
                                            }
                                        }}
                                    >
                                        <svg
                                            className='icon'
                                            width='21px'
                                            height='26px'
                                            color='white'
                                        >
                                            <use href='/images/icons.svg#icon-delete' />
                                        </svg>
                                    </Box>
                                    <Text
                                        color='leftPanelFontColor'
                                        fontWeight='600'
                                        fontSize='2rem'
                                        lineHeight='2.8rem'
                                        textAlign='center'
                                        w='65%'
                                        margin='0 auto'
                                        textTransform='capitalize'
                                    >
                                        Do you really want to Delete email?
                                    </Text>
                                    <Box
                                        pos='relative'
                                        textAlign='center'
                                        w='100%'
                                    >
                                        <Text
                                            fontSize='1.2rem'
                                            fontWeight='700'
                                            pos='absolute'
                                            left='0'
                                            right='0'
                                            margin='auto'
                                            bottom='-25px'
                                            color='red.500'
                                        >
                                            {deleteEmailErrMessage}
                                        </Text>
                                        <Text
                                            fontSize='1.2rem'
                                            fontWeight='700'
                                            pos='absolute'
                                            left='0'
                                            right='0'
                                            margin='auto'
                                            bottom='-25px'
                                            color='successMessageColor'
                                        >
                                            {deleteEmailSucMessage}
                                        </Text>
                                    </Box>
                                </ModalBody>

                                <ModalFooter
                                    justifyContent='center'
                                    p='25px 0 0'
                                    bg={
                                        globalState.darkMode
                                            ? 'darkModeBoxBackgroundColor'
                                            : 'white'
                                    }
                                >
                                    <Stack
                                        direction='row'
                                        justifyContent='center'
                                        spacing='10px'
                                    >
                                        <Ripples color='var(--chakra-colors-lightWhiteColor)'>
                                            <Button
                                                className='cancel_btn'
                                                onClick={onEmailDeleteClose}
                                                disabled={isDeleteEmailDisable}
                                            >
                                                Cancel{' '}
                                            </Button>
                                        </Ripples>
                                        <Ripples color='var(--chakra-colors-lightWhiteColor)'>
                                            <ButtonLoader
                                                disabled={isDeleteEmailDisable}
                                                isLoading={isDeleteEmailLoading}
                                                buttonCLass='delete_btn'
                                                onClickFnc={handelDeleteEmail}
                                            >
                                                Delete
                                            </ButtonLoader>
                                        </Ripples>
                                    </Stack>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>

                        <Modal
                            isOpen={isOtpVerifyOpen}
                            onClose={() => {
                                onOtpVerifyClose()
                            }}
                        >
                            <ModalOverlay />
                            <ModalContent
                                minWidth={{ base: '90%', lg: '450px' }}
                                p='0px'
                                margin='0px'
                                borderRadius='10px'
                                minHeight='300px'
                                pos='absolute'
                                left='50%'
                                top='50%'
                                transform='translate(-50%, -50%) !important'
                                overflow='none'
                                bg={
                                    globalState.darkMode
                                        ? 'darkModeBoxBackgroundColor'
                                        : 'white'
                                }
                            >
                                <ModalHeader color='var(--chakra-colors-leftPanelFontColor)'>
                                    <Text
                                        fontSize='2rem'
                                        fontWeight='600'
                                        textAlign='center'
                                        pb='20px'
                                        color={
                                            globalState.darkMode
                                                ? 'lfgMainLightBlue'
                                                : 'lfgMainBlue'
                                        }
                                        mt='36px'
                                    >
                                        Verify Your Email
                                    </Text>
                                </ModalHeader>
                                <ModalCloseButton
                                    color={
                                        globalState.darkMode
                                            ? 'inputplaceholderColor'
                                            : 'leftPanelFontColor'
                                    }
                                />

                                <form onSubmit={handleOtpSubmit}>
                                    <Stack
                                        spacing='33px'
                                        sx={{
                                            '&>*:nth-of-type(2)': {
                                                marginTop: '24px'
                                            }
                                        }}
                                    >
                                        <FormControl className='otp-wrapper'>
                                            <Box
                                                w='100%'
                                                display='flex'
                                                justifyContent='center'
                                            >
                                                <PinInput
                                                    otp
                                                    onChange={(e) =>
                                                        handleOtpChange(e)
                                                    }
                                                    value={otp}
                                                >
                                                    <PinInputField
                                                        height='40px'
                                                        w='50px'
                                                        mr='10px'
                                                        border='1px solid #b6c1d8'
                                                        background='#fefefe'
                                                        fontSize='1.3rem'
                                                        fontWeight='600'
                                                    />
                                                    <PinInputField
                                                        height='40px'
                                                        w='50px'
                                                        mr='10px'
                                                        border='1px solid #b6c1d8'
                                                        background='#fefefe'
                                                        fontSize='1.3rem'
                                                        fontWeight='600'
                                                    />
                                                    <PinInputField
                                                        height='40px'
                                                        w='50px'
                                                        mr='10px'
                                                        border='1px solid #b6c1d8'
                                                        background='#fefefe'
                                                        fontSize='1.3rem'
                                                        fontWeight='600'
                                                    />
                                                    <PinInputField
                                                        height='40px'
                                                        w='50px'
                                                        border='1px solid #b6c1d8'
                                                        background='#fefefe'
                                                        fontSize='1.3rem'
                                                        fontWeight='600'
                                                    />
                                                </PinInput>
                                            </Box>
                                        </FormControl>

                                        <Box pos='relative' textAlign='center'>
                                            <Box
                                                fontSize='1.2rem'
                                                fontWeight='700'
                                                pos='absolute'
                                                margin='auto'
                                                bottom='0'
                                                color='red.500'
                                                left='50%'
                                                transform='translateX(-50%)'
                                                minW='300px'
                                            >
                                                {msg}
                                            </Box>
                                        </Box>

                                        <Box
                                            textAlign='center'
                                            mt='0px !important'
                                            sx={{
                                                '&>*:first-of-type': {
                                                    textTransform: 'none',
                                                    fontWeight: '500'
                                                }
                                            }}
                                        >
                                            <Button
                                                onClick={handleResend}
                                                disabled={resend}
                                                color='#AFAFAF'
                                                fontWeight='500'
                                                fontSize='1.4rem'
                                                mr='10px'
                                                mb='6px'
                                                bg='transparent'
                                                _hover={{
                                                    bg: 'transparent',
                                                    outline: 'none'
                                                }}
                                                _focus={{
                                                    outline: 'none',
                                                    bg: 'none'
                                                }}
                                                textTransform='none'
                                                _active={{
                                                    bg: 'transparent'
                                                }}
                                            >
                                                Resend OTP &nbsp;
                                                <svg
                                                    className='icon'
                                                    width='16px'
                                                    height='15px'
                                                >
                                                    <use href='/images/icons.svg#icon-reset-pass' />
                                                </svg>
                                            </Button>
                                            <Box
                                                as='span'
                                                color='#AFAFAF'
                                                fontWeight='400'
                                                fontSize='1.2rem'
                                            >
                                                {seconds > 0
                                                    ? `${seconds} Sec`
                                                    : ''}
                                            </Box>
                                        </Box>
                                    </Stack>

                                    <ModalFooter flexDirection='column'>
                                        <Box>
                                            <Button
                                                disabled={isLoading}
                                                colorScheme='blue'
                                                mr={3}
                                                onClick={() => {
                                                    onOtpVerifyClose()
                                                }}
                                                minW='100px'
                                                h='40px'
                                            >
                                                Close
                                            </Button>

                                            <Button
                                                type='submit'
                                                disabled={isLoading}
                                                isLoading={isLoading}
                                                _focus={{
                                                    outline: 'none'
                                                }}
                                                minW='100px'
                                                h='40px'
                                                textTransform='uppercase'
                                                bgColor={
                                                    globalState.darkMode
                                                        ? 'lfgMainLightBlue'
                                                        : 'lfgMainBlue'
                                                }
                                                rounded='5px'
                                                color='white'
                                                _hover={{
                                                    bgColor: 'lfgMainBlueHover',
                                                    color: 'white'
                                                }}
                                                fontWeight='700'
                                                textAlign='center'
                                                boxShadow={
                                                    globalState.darkMode
                                                        ? 'none'
                                                        : '0px 2px 5px var(--chakra-colors-veryLightGrayColor)'
                                                }
                                                className='login-button'
                                            >
                                                Verify
                                            </Button>
                                        </Box>
                                    </ModalFooter>
                                </form>
                            </ModalContent>
                        </Modal>
                    </VStack>
                    <HStack
                        border={
                            globalState.darkMode
                                ? '1px solid var(--chakra-colors-darkModeBordercolor)'
                                : '1px solid var(--chakra-colors-dataTableRowBorder)'
                        }
                        bg={
                            globalState.darkMode
                                ? 'var(--chakra-colors-mainDarkModeBackgroundColor)'
                                : 'white'
                        }
                        borderRadius='10px'
                        boxShadow={
                            globalState.darkMode
                                ? 'none'
                                : '0px 2px 5px var(--chakra-colors-boxShadowLightBlueColor)'
                        }
                        mb={{ base: '0px', lg: '150px' }}
                        maxH={{ base: '50px', lg: '130px' }}
                        justifyContent='space-between'
                        p={{ base: '42px 10px', lg: '42px 34px' }}
                    >
                        <Box>
                            <Text
                                color={
                                    globalState.darkMode
                                        ? 'inputplaceholderColor'
                                        : 'breadCrumbTextColor'
                                }
                                fontWeight='600'
                                fontSize='1.3rem'
                            >
                                Password
                            </Text>
                            <Text
                                fontWeight='600'
                                fontSize='1.4rem'
                                color='lfgMainRedColor'
                            >
                                Change password
                            </Text>
                        </Box>
                        <Box
                            bg='editIconBgColorLight'
                            w='35px'
                            height='35px'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            borderRadius='50px'
                            color={
                                globalState.darkMode
                                    ? 'lfgMainLightBlue'
                                    : 'lfgMainBlue'
                            }
                            cursor='pointer'
                            transition='0.3s ease all'
                            _hover={{
                                bg: 'lfgMainBlue',
                                color: 'white'
                            }}
                            onClick={() => {
                                onPasswordChangeOpen()
                                reset()
                                if (timeout.current) {
                                    setChangePasswordSucMessage('')
                                    setChangePasswordErrMessage('')
                                    setIsDisableChangePassword(false)
                                    clearTimeout(timeout.current)
                                }
                            }}
                        >
                            <svg className='icon' width='16px' height='14px'>
                                <use href='/images/icons.svg#icon-edit' />
                            </svg>
                        </Box>
                    </HStack>
                </Box>
            </Box>

            <Modal
                isOpen={isPasswordChangeOpen}
                onClose={() => {
                    onPasswordChangeClose()
                }}
            >
                <ModalOverlay />
                <ModalContent
                    minWidth={{ base: '90%', lg: '450px' }}
                    bg={
                        globalState.darkMode
                            ? 'darkModeBoxBackgroundColor'
                            : 'white'
                    }
                    p='0'
                    m='0'
                    borderRadius='10px'
                    minHeight='450px'
                    pos='absolute'
                    left='50%'
                    top='50%'
                    transform='translate(-50%, -50%) !important'
                    overflow='none'
                >
                    <ModalCloseButton
                        color={
                            globalState.darkMode
                                ? 'inputplaceholderColor'
                                : 'leftPanelFontColor'
                        }
                    />
                    <form
                        onSubmit={handleSubmit(handelPasswordChange, onError)}
                    >
                        <ModalHeader
                            bg={
                                globalState.darkMode
                                    ? 'darkModeBoxBackgroundColor'
                                    : 'white'
                            }
                        >
                            <Text
                                fontSize='2rem'
                                fontWeight='600'
                                textAlign='center'
                                pb='20px'
                                color={
                                    globalState.darkMode
                                        ? 'lfgMainLightBlue'
                                        : 'lfgMainBlue'
                                }
                                mt='26px'
                            >
                                Password Change
                            </Text>
                        </ModalHeader>
                        <ModalBody
                            px='24px'
                            width='90%'
                            m='0px auto 0px'
                            bg={
                                globalState.darkMode
                                    ? 'darkModeBoxBackgroundColor'
                                    : 'white'
                            }
                        >
                            <Stack
                                direction='column'
                                spacing='40px'
                                sx={{
                                    '& > *:nth-of-type(2)': {
                                        marginTop: '4px'
                                    },
                                    '& > *:nth-of-type(3)': {
                                        marginTop: '4px'
                                    }
                                }}
                            >
                                <FormControl
                                    isInvalid={!!errors.currentPassword}
                                    mb='18px'
                                >
                                    <Box
                                        as='label'
                                        textStyle='label'
                                        htmlFor='currentPassword'
                                        color={
                                            globalState.darkMode
                                                ? 'inputplaceholderColor'
                                                : 'leftPanelFontColor'
                                        }
                                        fontWeight='600'
                                        fontSize='1.3rem'
                                        display='block'
                                        mb='5px'
                                    >
                                        Current Password
                                    </Box>
                                    <InputGroup
                                        sx={{
                                            '& input.chakra-input': {
                                                background: `${
                                                    globalState.darkMode
                                                        ? 'mainDarkModeBackgroundColor'
                                                        : 'white'
                                                }`
                                            },
                                            '& input.chakra-input:disabled': {
                                                background: `${
                                                    globalState.darkMode
                                                        ? 'mainDarkModeLightBackgroundColor'
                                                        : 'white'
                                                }`,
                                                borderColor: `${
                                                    globalState.darkMode
                                                        ? 'var(--chakra-colors-darkModeBordercolor)'
                                                        : 'var(--chakra-colors-inputDisabledStrokeColor)'
                                                }`
                                            }
                                        }}
                                    >
                                        <Input
                                            id='currentPassword'
                                            type={currentInputType}
                                            placeholder='Enter Current Password'
                                            {...register('currentPassword')}
                                            _placeholder={{
                                                opacity: 1,
                                                color: '#727E97'
                                            }}
                                            _focus={{
                                                outline: 'none'
                                            }}
                                            w='100%'
                                            h='40px'
                                            errorBorderColor='red.100'
                                            fontSize='13px'
                                            bgColor='#FEFEFE'
                                            border='1px solid #B6C1D8'
                                            borderRadius='3px'
                                            color='#727E97'
                                            fontWeight='500'
                                        />

                                        <InputRightElement
                                            onClick={() =>
                                                handleCurrentInputTypeChange()
                                            }
                                            h='40px'
                                            w='40px'
                                        >
                                            {currentPVal.length > 0 ? (
                                                <svg
                                                    className='icon'
                                                    width='16px'
                                                    height='15px'
                                                >
                                                    {currentInputType ===
                                                    'password' ? (
                                                        <use href='/images/icons.svg#icon-view' />
                                                    ) : (
                                                        <use href='/images/icons.svg#icon-view-pass' />
                                                    )}
                                                </svg>
                                            ) : (
                                                ''
                                            )}
                                        </InputRightElement>
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {errors.currentPassword?.message}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    isInvalid={!!errors.newPassword}
                                    sx={{
                                        '& .chakra-form__error-message': {
                                            marginTop: '10px'
                                        }
                                    }}
                                >
                                    <Box
                                        as='label'
                                        textStyle='label'
                                        htmlFor='email'
                                        color={
                                            globalState.darkMode
                                                ? 'inputplaceholderColor'
                                                : 'leftPanelFontColor'
                                        }
                                        fontWeight='600'
                                        display='block'
                                        mb='5px'
                                    >
                                        New Password
                                    </Box>
                                    <InputGroup
                                        sx={{
                                            '& input.chakra-input': {
                                                background: `${
                                                    globalState.darkMode
                                                        ? 'mainDarkModeBackgroundColor'
                                                        : 'white'
                                                }`
                                            },
                                            '& input.chakra-input:disabled': {
                                                background: `${
                                                    globalState.darkMode
                                                        ? 'mainDarkModeLightBackgroundColor'
                                                        : 'white'
                                                }`,
                                                borderColor: `${
                                                    globalState.darkMode
                                                        ? 'var(--chakra-colors-darkModeBordercolor)'
                                                        : 'var(--chakra-colors-inputDisabledStrokeColor)'
                                                }`
                                            }
                                        }}
                                    >
                                        <Input
                                            {...register('newPassword')}
                                            placeholder='Enter New Password'
                                            id='new-password'
                                            errorBorderColor='red.100'
                                            type={newInputType}
                                            fontSize='13px'
                                            bgColor='#FEFEFE'
                                            border='1px solid #B6C1D8'
                                            borderRadius='3px'
                                            color='#727E97'
                                            fontWeight='500'
                                            pl='15px'
                                            w='100%'
                                            h='40px'
                                        />
                                        <InputRightElement
                                            onClick={() =>
                                                handleNewInputTypeChange()
                                            }
                                            // pointerEvents='none'
                                            h='40px'
                                            w='40px'
                                        >
                                            {newPVal.length > 0 ? (
                                                <svg
                                                    className='icon'
                                                    width='16px'
                                                    height='15px'
                                                >
                                                    {newInputType ===
                                                    'password' ? (
                                                        <use href='/images/icons.svg#icon-view' />
                                                    ) : (
                                                        <use href='/images/icons.svg#icon-view-pass' />
                                                    )}
                                                </svg>
                                            ) : (
                                                ''
                                            )}
                                        </InputRightElement>
                                    </InputGroup>
                                    <FormErrorMessage mt='10px'>
                                        {errors.newPassword?.message}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl>
                                    <Flex
                                        flexDirection='row'
                                        justifyContent='space-between'
                                    >
                                        <Box
                                            as='span'
                                            bg={handlePassStrengthColor1()}
                                            h='4px'
                                            flex='0 0 23%'
                                            transition='all 0.4s ease-in-out'
                                        />
                                        <Box
                                            as='span'
                                            bg={handlePassStrengthColor2()}
                                            h='4px'
                                            flex='0 0 23%'
                                            transition='all 0.4s ease-in-out'
                                        />
                                        <Box
                                            as='span'
                                            bg={handlePassStrengthColor3()}
                                            h='4px'
                                            flex='0 0 23%'
                                            transition='all 0.4s ease-in-out'
                                        />
                                        <Box
                                            as='span'
                                            bg={handlePassStrengthColor4()}
                                            h='4px'
                                            flex='0 0 23%'
                                            transition='all 0.4s ease-in-out'
                                        />
                                    </Flex>
                                    <Box textAlign='right'>
                                        <Text
                                            as='span'
                                            color='inputplaceholderColor'
                                            fontWeight='500'
                                            fontSize='1.3rem'
                                            display='inline-block'
                                            verticalAlign='middle'
                                        >
                                            {handlePassStrengthText()}
                                        </Text>
                                        <Text
                                            as='span'
                                            display='inline-block'
                                            verticalAlign='middle'
                                            ml='5px'
                                        >
                                            <Tooltip
                                                label={
                                                    <ul>
                                                        <li>
                                                            Should be minimum 8
                                                            characters in
                                                            length.
                                                        </li>
                                                        <li>
                                                            Should have
                                                            combination of
                                                            alphabets (
                                                            lowercase and
                                                            uppercase ), numbers
                                                            and special
                                                            characters (
                                                            [!#@$%^&*)(+=._-] ).
                                                        </li>
                                                        <li>
                                                            Should have at least
                                                            one uppercase and
                                                            one lowercase
                                                            character.
                                                        </li>
                                                        <li>
                                                            Should have at least
                                                            one number.
                                                        </li>
                                                        <li>
                                                            Should have at least
                                                            one symbol.
                                                        </li>
                                                    </ul>
                                                }
                                                bg='white'
                                                color='leftPanelFontColor'
                                                fontSize='1.3rem'
                                                fontWeight='500'
                                                border='1px'
                                                borderColor='inputStrokeColor'
                                                borderRadius='5px'
                                                placement='bottom-end'
                                                padding='10px 15px'
                                                arrowSize={15}
                                                className='chakra-tooltip'
                                                sx={{
                                                    '& ul': {
                                                        paddingLeft: '15px'
                                                    },
                                                    '& ul li:last-of-type': {
                                                        marginBottom: '0'
                                                    },
                                                    '& li::marker': {
                                                        fontSize: '1.7rem'
                                                    }
                                                }}
                                            >
                                                <svg
                                                    className='icon'
                                                    width='15px'
                                                    height='15px'
                                                    display='inline-block'
                                                >
                                                    <use href='/images/icons.svg#icon-info-pass' />
                                                </svg>
                                            </Tooltip>
                                        </Text>
                                    </Box>
                                </FormControl>

                                <FormControl
                                    isInvalid={!!errors.confirmPassword}
                                    mt='0px !important'
                                    mb='18px'
                                >
                                    <Box
                                        as='label'
                                        textStyle='label'
                                        htmlFor='email'
                                        color={
                                            globalState.darkMode
                                                ? 'inputplaceholderColor'
                                                : 'leftPanelFontColor'
                                        }
                                        fontWeight='600'
                                        display='block'
                                        mb='5px'
                                    >
                                        Confirm Password
                                    </Box>
                                    <InputGroup
                                        sx={{
                                            '& input.chakra-input': {
                                                background: `${
                                                    globalState.darkMode
                                                        ? 'mainDarkModeBackgroundColor'
                                                        : 'white'
                                                }`
                                            },
                                            '& input.chakra-input:disabled': {
                                                background: `${
                                                    globalState.darkMode
                                                        ? 'mainDarkModeLightBackgroundColor'
                                                        : 'white'
                                                }`,
                                                borderColor: `${
                                                    globalState.darkMode
                                                        ? 'var(--chakra-colors-darkModeBordercolor)'
                                                        : 'var(--chakra-colors-inputDisabledStrokeColor)'
                                                }`
                                            }
                                        }}
                                    >
                                        <Input
                                            {...register('confirmPassword')}
                                            placeholder='Enter Confirm Password'
                                            id='confirm-password'
                                            errorBorderColor='red.100'
                                            type={confirmInputType}
                                            fontSize='13px'
                                            bgColor='#FEFEFE'
                                            border='1px solid #B6C1D8'
                                            borderRadius='3px'
                                            color='#727E97'
                                            fontWeight='500'
                                            pl='15px'
                                            w='100%'
                                            h='40px'
                                        />
                                        <InputRightElement
                                            onClick={() =>
                                                handleConfirmInputTypeChange()
                                            }
                                            // pointerEvents='none'
                                            h='40px'
                                            w='40px'
                                        >
                                            {confirmPVal.length > 0 ? (
                                                <svg
                                                    className='icon'
                                                    width='16px'
                                                    height='15px'
                                                >
                                                    {confirmInputType ===
                                                    'password' ? (
                                                        <use href='/images/icons.svg#icon-view' />
                                                    ) : (
                                                        <use href='/images/icons.svg#icon-view-pass' />
                                                    )}
                                                </svg>
                                            ) : (
                                                ''
                                            )}
                                        </InputRightElement>
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {errors.confirmPassword?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Stack>
                        </ModalBody>

                        <ModalFooter
                            width='90%'
                            px='24px'
                            m='16px auto 0px'
                            bg={
                                globalState.darkMode
                                    ? 'darkModeBoxBackgroundColor'
                                    : 'white'
                            }
                        >
                            <Box display='flex'>
                                <Button
                                    disabled={isDisableChangePassword}
                                    colorScheme='blue'
                                    mr={3}
                                    onClick={() => {
                                        onPasswordChangeClose()
                                        reset()
                                    }}
                                    w='100%'
                                    minW='100px'
                                    h='40px'
                                >
                                    Close
                                </Button>
                                <Button
                                    type='submit'
                                    disabled={isDisableChangePassword}
                                    isLoading={isLoadingChangePassword}
                                    _focus={{
                                        outline: 'none'
                                    }}
                                    w='100%'
                                    minW='100px'
                                    h='40px'
                                    textTransform='uppercase'
                                    bgColor={
                                        globalState.darkMode
                                            ? 'lfgMainLightBlue'
                                            : 'lfgMainBlue'
                                    }
                                    rounded='5px'
                                    color='white'
                                    _hover={{
                                        bgColor: 'lfgMainBlueHover',
                                        color: 'white'
                                    }}
                                    fontWeight='700'
                                    textAlign='center'
                                    boxShadow={
                                        globalState.darkMode
                                            ? 'none'
                                            : '0px 3px 4px var(--chakra-colors-lightGrayColor)'
                                    }
                                    className='login-button'
                                >
                                    Save
                                </Button>
                            </Box>
                            <FormControl
                                isInvalid={!!changePasswordErrMessage.length}
                                pos='absolute'
                                textAlign='center'
                            >
                                <Text
                                    fontSize='1.2rem'
                                    fontWeight='700'
                                    pos='absolute'
                                    right='0'
                                    bottom='24px'
                                    color='red.500'
                                >
                                    {changePasswordErrMessage}
                                </Text>
                                <Text
                                    fontSize='1.2rem'
                                    fontWeight='700'
                                    pos='absolute'
                                    right='0'
                                    bottom='24px'
                                    color='successMessageColor'
                                >
                                    {changePasswordSucMessage}
                                </Text>
                            </FormControl>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default Profile
