import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import ConditionalRouting from './conditionalRouting'

import { RootState } from '../store/store'

interface AuthProps {
    redirectTo?: string
    children: React.ReactElement
}
/**
 * @param {AuthProps} props
 * @typedef AuthProps
 * is the interface for the props of the component passed to the AuthenticatedRoute
 * @param props
 * {redirectTo?: string, children: React.ReactElement}
 * @returns
 * It returns ConditionalRouting component with children as props.children or Navigate component with redirectTo as props.redirectTo depending on the logic
 */
const AuthenticatedRoutes = (props: AuthProps) => {
    const { loggedIn: isLoggedIn } = useSelector(
        (state: RootState) => state.auth
    )
    const { redirectTo, children } = props

    const navigate = useNavigate()

    const redirect = redirectTo || '/'

    useEffect(() => {
        if (!isLoggedIn) {
            navigate(redirect, { replace: true })
        }
    })

    if (isLoggedIn) {
        return <ConditionalRouting>{children}</ConditionalRouting>
    }

    return null
}

AuthenticatedRoutes.defaultProps = {
    redirectTo: '/'
}

export default AuthenticatedRoutes
