import React from 'react'
import { Box } from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { PageTransitionSignature, TransitionSignature } from '../interfaces'

const PageTransition: React.FC<PageTransitionSignature> = ({ children }) => (
    <Box
        key='sameKey'
        className='animated-inner-page'
        as={motion.div}
        initial={{
            opacity: 0,
            y: 10
        }}
        animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: 'easeInOut' }
        }}
        exit={{
            opacity: 0,
            y: 10,
            transition: { duration: 0.5, ease: 'easeInOut' }
        }}
    >
        {children}
    </Box>
)

export const Transition: React.FC<TransitionSignature> = ({ children }) => (
    <Box
        className='animated-inner-page'
        as={motion.div}
        initial={{
            opacity: 0,
            y: 10
        }}
        animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.7, ease: 'easeInOut' }
        }}
        exit={{
            opacity: 0,
            y: -10,
            transition: { duration: 0.7, ease: 'easeInOut' }
        }}
    >
        {children}
    </Box>
)
export default PageTransition
