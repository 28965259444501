/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ConditionalRouting from './conditionalRouting'
import { RootState } from '../store/store'

interface Signature {
    children: React.ReactElement
    redirectTo?: string
}

const StrictRoute = ({ children, redirectTo }: Signature) => {
    const { loggedIn: isLoggedIn, userEmail } = useSelector(
        (state: RootState) => state.auth
    )

    const [redirect] = useState(redirectTo || '/dashboard')

    const location = useLocation()

    return isLoggedIn ? (
        userEmail ? (
            <Navigate to={redirect} replace state={{ from: location }} />
        ) : (
            <Navigate to='/update-email' replace state={{ from: location }} />
        )
    ) : (
        <ConditionalRouting>{children}</ConditionalRouting>
    )
}

StrictRoute.defaultProps = {
    redirectTo: '/dashboard'
}

export default StrictRoute
