import React, { useEffect, useState } from 'react'

const useAvailSw = () => {
    const [SWI, setSWI] = useState<ServiceWorkerContainer | null>(null)
    const [SWIController, setSWIController] = useState<ServiceWorker | null>(
        null
    )

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
                //   navigator.serviceWorker.controller.postMessage({
                //     type: 'navigateToAddedStudent'
                //   });
                if (
                    'controller' in navigator.serviceWorker &&
                    navigator.serviceWorker.controller !== null
                ) {
                    setSWIController(navigator.serviceWorker.controller)
                }
            })
            setSWI(navigator.serviceWorker)
        }
    }, [])

    return { SWI, SWIController }
}
export default useAvailSw
