import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { useToast } from '@chakra-ui/react'
import { RootState } from '../store/store'
import useTokenValidator from './hooks/useTokenValidator'
// import useBreadcrumbSegments from '../modules/global/hooks/useBreadcrumbSegments'

const { REACT_APP_PAGE_TITLE_PREFIX } = process.env

interface Signature {
    meta: { name: string; content: string }[]
    breadcrumb?: { label: string; url: string }[]
    title: string
    children: React.ReactNode
}

const MetaWrapper: FC<Signature> = (props) => {
    const { meta: metaInfo, breadcrumb, title, children } = props
    /**
     * Passed Navigate Hook to the axios middleware.
     * Called responseHandler here as it needs useNavigate hook to redirect user
     * to the login page. responseHandler is not a component, so we can not pass useNavigate
     * to itself.
     */
    const { REACT_APP_TIMEOUT } = process.env
    const toast = useToast()
    useTokenValidator()
    const authSliceData = useSelector((state: RootState) => state.auth)

    useEffect(() => {
        if (authSliceData.isQueryError) {
            toast({
                description: authSliceData.errorMessageFromQuery,
                position: 'top',
                status: 'error',
                duration: parseInt(REACT_APP_TIMEOUT as string, 10),
                isClosable: false
            })
        }
    }, [authSliceData.isQueryError])

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charSet='utf-8' />
                    <meta name='screen-orientation' content='portrait' />
                    <meta
                        name='theme-color'
                        content={`#${process.env.REACT_APP_THEME_COLOR}`}
                    />
                    <meta name='mobile-web-app-capable' content='yes' />
                    <meta
                        name='apple-mobile-web-app-title'
                        content={`${
                            REACT_APP_PAGE_TITLE_PREFIX || 'LFG - '
                        }${title}`}
                    />
                    <meta name='apple-mobile-web-app-capable' content='yes' />
                    <meta
                        name='apple-mobile-web-app-status-bar-style'
                        content='default'
                    />
                    {(metaInfo || []).map((mta) => (
                        <meta
                            name={mta.name}
                            content={mta.content}
                            key={mta.content}
                        />
                    ))}

                    <title>{`${
                        REACT_APP_PAGE_TITLE_PREFIX || 'LFG - '
                    }${title}`}</title>
                    <base href='/' />
                </Helmet>
            </HelmetProvider>

            {children}
        </>
    )
}
MetaWrapper.defaultProps = {
    breadcrumb: []
}
export default MetaWrapper
