/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { NavigationSignature } from '../interfaces'
import NavAtom from './navAtom'

const Navigation: React.FC<NavigationSignature> = ({
    menuCollapse,
    setmenuCollapse
}) => {
    const [navigations] = useState([
        {
            name: 'Dashboard',
            link: '/dashboard',
            disabled: false,
            available: true,
            parent: ''
        },
        {
            name: 'Cases',
            link: '/cases',
            disabled: false,
            available: true,
            parent: ''
        },
        {
            name: 'Request',
            link: '/requests',
            disabled: false,
            available: true,
            parent: ''
        },
        {
            name: 'Clients',
            link: '/clients',
            disabled: false,
            available: true,
            parent: ''
        },
        {
            name: 'Recent Activity',
            link: '/recent-activity',
            disabled: false,
            available: true,
            parent: ''
        },

        {
            name: 'Administration',
            disabled: false,
            available: true,
            parent: '',
            sub: [
                {
                    name: 'Legal Firm Details',
                    link: '/legal-firm-details/view',
                    disabled: false,
                    available: true,
                    parent: 'Administration',
                    isWithOutModule: true
                },
                {
                    name: 'Staff Management',
                    link: '/staff-management',
                    disabled: false,
                    available: true,
                    parent: 'Administration'
                },
                {
                    name: 'Agent Auth',
                    link: '/agent-authorization',
                    disabled: false,
                    available: true,
                    parent: 'Administration'
                },
                {
                    name: 'Client Auth',
                    link: '/client-authorization',
                    disabled: false,
                    available: true,
                    parent: 'Administration'
                }
            ]
        }
    ])

    return (
        <>
            {navigations.map(
                ({ name, link, disabled, available, parent, sub }) => (
                    <NavAtom
                        name={name}
                        link={link}
                        disabled={disabled}
                        available={available}
                        parent={parent}
                        menuCollapse={menuCollapse}
                        setmenuCollapse={setmenuCollapse}
                        sub={sub}
                        key={`${name.replace(/\s/g, '-')}-${
                            link ? link.replace(/\\/g, '-') : ''
                        }`}
                    />
                )
            )}
        </>
    )
}

export default Navigation
