/* eslint-disable array-callback-return */
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import createBaseQuery from '../../../utilities/createBaseQuery'
import {
    EditRequestNoteRequestType,
    EditRequestNoteResponseType,
    RequestListRequestType,
    RequestListResponseType,
    UpdateAlertRequestType,
    UpdateAlertResponseType,
    AddRequestType,
    AddResponseType,
    EditRequestType,
    EditResponseType,
    ViewRequestType,
    ViewResponseType,
    FilterFirmLocationResponseType,
    FilterFirmLocationRequestType,
    FilterCaseResponseType,
    FilterCaseRequestType,
    FilterRequestTypeResponse,
    FilterRequestStatusResponse,
    GetProviderRelationshipResponseType,
    GetProviderRelationshipRequestType,
    GetProviderRelationshipWithOptionResponseType,
    GetProviderRelationshipWithOptionRequestType,
    GetRecordsResponseType,
    GetRecordsRequestType,
    GetRequestDocumentTypeListDropdownResponseType,
    GetDocumentsStatusListDropdownResponseType,
    GetProviderRelationshipWithRequestResponseType,
    GetProviderRelationshipWithRequestType,
    RequestHistoryProps,
    GetChargeStatusListResponseType,
    GetChargeStatusListRequestType,
    GetChargeTypeListResponseType,
    GetChargeTypeListRequestType,
    GetPaymentTypeListResponseType,
    GetPaymentTypeListRequestType,
    EditChargeResponseType,
    EditChargeRequestType,
    ViewChargeResponseType,
    ViewChargeRequestType,
    EditInvoiceResponseType,
    EditInvoiceRequestType,
    ViewInvoiceResponseType,
    ViewInvoiceRequestType,
    DownloadResponseDocumentResponseType,
    assingLfgStaffRequestType,
    assingLfgStaffResponseType,
    addStaffResponseType,
    addStaffRequestType,
    viewLfgStaffResponseType,
    FilterAssignStaffRequestType,
    FilterAssignStaffResponseType,
    CreateFollowUpRespondType,
    CreateFollowUpRequestType,
    GetDocumentsStatusListResponseType,
    ChangeRequestStatusResponseType,
    ChangeRequestStatusRequestType,
    FilterRequestStatusRequestType,
    DeleteRequestStatusRequestType,
    DeleteRequestStatusResponseType,
    ReleaseAllRequestResponseType,
    ReleaseAllReqRequestType,
    RecordResponseType,
    RecordResquestType,
    ViewRecordDownloadResponseType,
    ViewRecordDownloadRequestType,
    CreateRecordLinkRequestType,
    CreateRecordLinkResponseType,
    RemoveRecordLinkRequestType,
    RemoveRecordLinkResponseType,
    ViewRecordLinkRequestType,
    ViewRecordLinkResponseType,
    CreateRequestDocLinkRequestType,
    CreateRequestDocLinkResponseType,
    RemoveRequestDocLinkRequestType,
    RemoveRequestDocLinkResponseType,
    ViewRequestDocLinkRequestType,
    ViewRequestDocLinkResponseType,
    DownloadRequestDocumentRequestType,
    FilterActiveClientByActiveCaseIdRequestType,
    FilterActiveClientByActiveCaseIdResponseType,
    UpdateRushNoteResponseType,
    UpdateRushNoteRequestType,
    GenerateRequestsReportResponseType,
    GenerateRequestsReportRequestType,
    FilterRequestStaffResponse,
    FilterRequestStaffRequest
} from '../interfaces'

import {
    RemoveRushRequestType,
    RemoveRushResponseType
} from '../../cases/interfaces'

const requestsApi = createApi({
    reducerPath: 'requests',
    baseQuery: createBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: ['requestList', 'recordList'],
    endpoints: (builder) => ({
        getRequests: builder.query<
            RequestListResponseType,
            RequestListRequestType
        >({
            query: (data) => ({
                url: 'request/list',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.0001,
            providesTags: ['requestList']
        }),

        editRequestNote: builder.mutation<
            EditRequestNoteResponseType,
            EditRequestNoteRequestType
        >({
            query: (data) => ({
                url: 'request/updateNote',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['requestList']
        }),
        updateAlert: builder.mutation<
            UpdateAlertResponseType,
            UpdateAlertRequestType
        >({
            query: (data) => ({
                url: 'request/updateGeneralPaperworkAlert',
                payload: data,
                method: 'POST'
            })
        }),
        addRequest: builder.mutation<AddResponseType, AddRequestType>({
            query: (data) => ({
                url: 'request/add',
                payload: data,
                method: 'POST'
            })
        }),
        editRequest: builder.mutation<EditResponseType, EditRequestType>({
            query: (data) => ({
                url: 'request/edit',
                payload: data,
                method: 'POST'
            })
        }),
        viewRequest: builder.query<ViewResponseType, ViewRequestType>({
            query: (data) => ({
                url: 'request/view',
                payload: data,
                method: 'POST'
            })
        }),
        filterFirmLocation: builder.query<
            FilterFirmLocationResponseType,
            FilterFirmLocationRequestType
        >({
            query: (data) => ({
                url: 'firm/filterFirmLocation',
                payload: data,
                method: 'POST'
            })
        }),
        updateRushNote: builder.mutation<
            UpdateRushNoteResponseType,
            UpdateRushNoteRequestType
        >({
            query: (data) => ({
                url: 'request/updateRushNote',
                payload: data,
                method: 'POST'
            })
        }),
        filterCase: builder.query<
            FilterCaseResponseType,
            FilterCaseRequestType
        >({
            query: (data) => ({
                url: 'case/filterCase',
                payload: data,
                method: 'POST'
            })
        }),
        filterRequestType: builder.query<FilterRequestTypeResponse, void>({
            query: (data) => ({
                url: 'request/requestTypeList',
                payload: data,
                method: 'POST'
            })
        }),
        filterRequestStatus: builder.query<FilterRequestStatusResponse, void>({
            query: (data) => ({
                url: 'request/requestStatusList',
                payload: data,
                method: 'POST'
            })
        }),
        filterRequestStaff : builder.query<FilterRequestStaffResponse, FilterRequestStaffRequest>({
            query: (data) => ({
                url: 'firm/filterStaff',
                payload: data,
                method: 'POST'
            })

        }),
        removeRush: builder.mutation<
            RemoveRushResponseType,
            RemoveRushRequestType
        >({
            query: (data) => ({
                url: 'request/removeRush',
                payload: data,
                method: 'POST'
            })
        }),
        getProviderRelationship: builder.query<
            GetProviderRelationshipResponseType,
            GetProviderRelationshipRequestType
        >({
            query: (data) => ({
                url: 'provider/getProviderRelationship',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.000001
        }),
        getProviderRelationshipWithOption: builder.query<
            GetProviderRelationshipWithOptionResponseType,
            GetProviderRelationshipWithOptionRequestType
        >({
            query: (data) => ({
                url: 'provider/getProviderRelationshipWithOption',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.000001
        }),
        getRecords: builder.query<
            GetRecordsResponseType,
            GetRecordsRequestType
        >({
            query: (data) => ({
                url: 'request/documentList',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.000001
        }),
        getRequestDocumentTypeListDropdown: builder.query<
            GetRequestDocumentTypeListDropdownResponseType,
            void
        >({
            query: (data) => ({
                url: 'request/requestDocumentTypeList',
                payload: data,
                method: 'POST'
            })
        }),
        getDocumentsStatusListDrodown: builder.query<
            GetDocumentsStatusListDropdownResponseType,
            void
        >({
            query: (data) => ({
                url: 'request/documentStatusList',
                payload: data,
                method: 'POST'
            })
        }),
        getProviderRelationshipWithRequest: builder.query<
            GetProviderRelationshipWithRequestResponseType,
            GetProviderRelationshipWithRequestType
        >({
            query: (data) => ({
                url: 'provider/getProviderRelationshipWithRequest',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.000001
        }),
        getHistories: builder.query<RequestHistoryProps[], any>({
            query: (data) => ({
                url: `requestHistory?_page=${
                    data.page && data.page > 0 ? data.page + 1 : 1
                }&_limit=${data.limit}&_sort=${data.sortField}&_order=${
                    data.sortOrder === 1 ? 'asc' : 'desc'
                }`,
                method: 'GET'
            })
        }),
        getChargeStatusList: builder.query<
            GetChargeStatusListResponseType,
            GetChargeStatusListRequestType
        >({
            query: (data) => ({
                url: 'request/chargeStatusList',
                payload: data,
                method: 'POST'
            })
        }),
        getChargeTypeList: builder.query<
            GetChargeTypeListResponseType,
            GetChargeTypeListRequestType
        >({
            query: (data) => ({
                url: 'request/chargeTypeList',
                payload: data,
                method: 'POST'
            })
        }),
        getPaymentTypeList: builder.query<
            GetPaymentTypeListResponseType,
            GetPaymentTypeListRequestType
        >({
            query: (data) => ({
                url: 'request/paymentTypeList',
                payload: data,
                method: 'POST'
            })
        }),
        editCharge: builder.mutation<
            EditChargeResponseType,
            EditChargeRequestType
        >({
            query: (data) => ({
                url: 'request/editCharge',
                payload: data,
                method: 'POST'
            })
        }),
        viewCharge: builder.query<
            ViewChargeResponseType,
            ViewChargeRequestType
        >({
            query: (data) => ({
                url: 'request/viewCharge',
                payload: data,
                method: 'POST'
            })
        }),
        editInvoice: builder.mutation<
            EditInvoiceResponseType,
            EditInvoiceRequestType
        >({
            query: (data) => ({
                url: 'request/editInvoice',
                payload: data,
                method: 'POST'
            })
        }),
        viewInvoice: builder.query<
            ViewInvoiceResponseType,
            ViewInvoiceRequestType
        >({
            query: (data) => ({
                url: 'request/viewInvoice',
                payload: data,
                method: 'POST'
            })
        }),
        filterStaff: builder.query<
            assingLfgStaffResponseType,
            assingLfgStaffRequestType
        >({
            query: (data) => ({
                url: 'request/filterFollowUpStaffList',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.0001
        }),
        addStaff: builder.mutation<addStaffResponseType, addStaffRequestType>({
            query: (data) => ({
                url: 'request/AssignLfgStaff',
                payload: data,
                method: 'POST'
            })
        }),
        viewLfgStaff: builder.query<
            viewLfgStaffResponseType,
            {
                request_id: string | undefined
            }
        >({
            query: (data) => ({
                url: 'request/viewLFGStaff',
                payload: data,
                method: 'POST'
            })
        }),
        filterAssignStaff: builder.query<
            FilterAssignStaffResponseType,
            FilterAssignStaffRequestType
        >({
            query: (data) => ({
                url: 'request/filterAssignStaff',
                payload: data,
                method: 'POSt'
            }),
            transformResponse: (response: FilterAssignStaffResponseType) => {
                if (typeof response === 'object') {
                    const newArray = response.response.raws.data.dataset
                    newArray.find((e, index) => {
                        if (e.status === 0) {
                            newArray.splice(index, 1, {
                                id: e.id,
                                first_name: e.first_name,
                                last_name: e.last_name,
                                status: e.status,
                                full_name: `${e.full_name} - (Inactive)`
                            })
                        }
                    })

                    return {
                        response: {
                            raws: {
                                success_message:
                                    response.response.raws.success_message,
                                data: {
                                    dataset: response.response.raws.data.dataset
                                },
                                publish: response.response.raws.publish
                            }
                        }
                    }
                }

                return response
            }
        }),
        createFollowUp: builder.mutation<
            CreateFollowUpRespondType,
            CreateFollowUpRequestType
        >({
            query: (data) => ({
                url: 'request/createFollowUp',
                payload: data,
                method: 'POST'
            })
        }),
        getDocumentsStatusList: builder.query<
            GetDocumentsStatusListResponseType,
            void
        >({
            query: (data) => ({
                url: 'request/documentStatusList',
                payload: data,
                method: 'POST'
            })
        }),
        changeRequestStatusChange: builder.mutation<
            ChangeRequestStatusResponseType,
            ChangeRequestStatusRequestType
        >({
            query: (data) => ({
                url: 'request/requestRelease',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['requestList']
        }),
        RequestStatus: builder.query<
            FilterRequestStatusResponse,
            FilterRequestStatusRequestType
        >({
            query: (data) => ({
                url: 'request/editRequestStatusList',
                payload: data,
                method: 'POST'
            })
        }),
        deleteRequestStatus: builder.mutation<
            DeleteRequestStatusResponseType,
            DeleteRequestStatusRequestType
        >({
            query: (data) => ({
                url: 'request/deleteRequest',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['requestList']
        }),
        releaseAllRequest: builder.mutation<
            ReleaseAllRequestResponseType,
            ReleaseAllReqRequestType
        >({
            query: (data) => ({
                url: 'request/releaseAllRequest',
                payload: data,
                method: 'POST'
            })
        }),
        documentList: builder.query<RecordResponseType, RecordResquestType>({
            query: (data) => ({
                url: 'request/documentList',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['recordList'],
            keepUnusedDataFor: 0.0001
        }),
        downloadRequestDocument: builder.mutation<
            DownloadResponseDocumentResponseType,
            DownloadRequestDocumentRequestType
        >({
            query: (data) => ({
                url: 'request/downloadRequestDocument',
                payload: data,
                method: 'POST'
            })
        }),
        viewRecordDownload: builder.mutation<
            ViewRecordDownloadResponseType,
            ViewRecordDownloadRequestType
        >({
            query: (data) => ({
                url: 'request/downloadLatestRecord',
                payload: data,
                method: 'POST'
            })
        }),
        createRecordLink: builder.mutation<
            CreateRecordLinkResponseType,
            CreateRecordLinkRequestType
        >({
            query: (data) => ({
                url: 'request/createRecordLink',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['requestList']
        }),
        viewRecordLink: builder.query<
            ViewRecordLinkResponseType,
            ViewRecordLinkRequestType
        >({
            query: (data) => ({
                url: 'request/viewRecordLink',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.00001
        }),
        removeRecordLink: builder.mutation<
            RemoveRecordLinkResponseType,
            RemoveRecordLinkRequestType
        >({
            query: (data) => ({
                url: 'request/removeLink',
                payload: data,
                method: 'POST'
            })
        }),
        createRequestDocLink: builder.mutation<
            CreateRequestDocLinkResponseType,
            CreateRequestDocLinkRequestType
        >({
            query: (data) => ({
                url: 'request/createDocumentRecordLink',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['recordList']
        }),
        viewRequestDocLink: builder.query<
            ViewRequestDocLinkResponseType,
            ViewRequestDocLinkRequestType
        >({
            query: (data) => ({
                url: 'request/viewDocumentRecordLink',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.00001
        }),
        removeRequestDocLink: builder.mutation<
            RemoveRequestDocLinkResponseType,
            RemoveRequestDocLinkRequestType
        >({
            query: (data) => ({
                url: 'request/removeDocumentLink',
                payload: data,
                method: 'POST'
            })
        }),
        filterActiveClientByActiveCaseId: builder.query<
            FilterActiveClientByActiveCaseIdResponseType,
            FilterActiveClientByActiveCaseIdRequestType
        >({
            query: (data) => ({
                url: 'case/filterActiveClientByActiveCaseId',
                payload: data,
                method: 'POST'
            })
        }),
        generateRequestsReport: builder.mutation<
            GenerateRequestsReportResponseType,
            GenerateRequestsReportRequestType
        >({
            query: (data) => ({
                url: 'request/generateRequestsReport',
                payload: data,
                method: 'POST'
            })
        })
    })
})

export default requestsApi
export const {
    useGetRequestsQuery,
    useEditRequestNoteMutation,
    useUpdateAlertMutation,
    useAddRequestMutation,
    useEditRequestMutation,
    useViewRequestQuery,
    useFilterFirmLocationQuery,
    useFilterCaseQuery,
    useFilterRequestTypeQuery,
    useFilterRequestStatusQuery,
    useFilterRequestStaffQuery,
    useRemoveRushMutation,
    useGetProviderRelationshipQuery,
    useGetProviderRelationshipWithOptionQuery,
    useGetRecordsQuery,
    useGetRequestDocumentTypeListDropdownQuery,
    useGetDocumentsStatusListDrodownQuery,
    useGetProviderRelationshipWithRequestQuery,
    useGetHistoriesQuery,
    useGetChargeStatusListQuery,
    useGetChargeTypeListQuery,
    useGetPaymentTypeListQuery,
    useEditChargeMutation,
    useViewChargeQuery,
    useEditInvoiceMutation,
    useViewInvoiceQuery,
    useDownloadRequestDocumentMutation,
    useFilterStaffQuery,
    useAddStaffMutation,
    useViewLfgStaffQuery,
    useFilterAssignStaffQuery,
    useCreateFollowUpMutation,
    useGetDocumentsStatusListQuery,
    useChangeRequestStatusChangeMutation,
    useRequestStatusQuery,
    useDeleteRequestStatusMutation,
    useReleaseAllRequestMutation,
    useDocumentListQuery,
    useViewRecordDownloadMutation,
    useCreateRecordLinkMutation,
    useViewRecordLinkQuery,
    useRemoveRecordLinkMutation,
    useCreateRequestDocLinkMutation,
    useViewRequestDocLinkQuery,
    useRemoveRequestDocLinkMutation,
    useFilterActiveClientByActiveCaseIdQuery,
    useUpdateRushNoteMutation,
    useGenerateRequestsReportMutation
} = requestsApi
