import { createSlice } from '@reduxjs/toolkit'
import { updateLogoutStatus } from '../../authentication/slices'

const initialState = {
    type: '',
    typeId: '',
    providerName: '',
    providerLocation: '',
    isTransferred: ''
}

const ProviderSlice = createSlice({
    name: 'providerReducer',
    initialState,
    reducers: {
        updateProviderInfo: (state, action) => {
            state.type = action.payload.type
            state.typeId = action.payload.typeId
            state.providerName = action.payload.providerName
            state.providerLocation = action.payload.providerLocation
            state.isTransferred = action.payload.isTransferred
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateLogoutStatus, () => initialState)
    }
})

export const { updateProviderInfo } = ProviderSlice.actions
export default ProviderSlice
