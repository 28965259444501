import { createApi } from '@reduxjs/toolkit/query/react'
import createBaseQuery from '../../../utilities/createBaseQuery'
import {
    loginRequestType,
    loginResponseType,
    resendPasswordResponseType,
    resetPasswordResponseType,
    verifyOtpResponseType,
    SendOtpRequestType,
    SendOtpResponseType,
    OtpVerificationResponseType,
    OtpVerificationRequestType
} from '../interfaces'

const userAuthentication = createApi({
    reducerPath: 'userAuthentication',
    baseQuery: createBaseQuery(),
    // tagTypes: ['login', 'logout'],
    endpoints: (builder) => ({
        login: builder.mutation<loginResponseType, loginRequestType>({
            query: (body) => ({
                url: 'login',
                payload: body,
                method: 'POST'
            })
        }),
        resendPassword: builder.mutation<
            resendPasswordResponseType,
            { email: string }
        >({
            query: (body) => ({
                url: 'sendResendOTP',
                payload: body,
                method: 'POST'
            })
        }),
        verifyOtp: builder.mutation<
            verifyOtpResponseType,
            { otp: string; email: string }
        >({
            query: (body) => ({
                url: 'verifyOtp',
                payload: body,
                method: 'POST'
            })
        }),
        resetPassword: builder.mutation<
            resetPasswordResponseType,
            { email: string; password: string; otp_token: string }
        >({
            query: (body) => ({
                url: 'resetPassword',
                payload: body,
                method: 'POST'
            })
        }),
        sendOtp: builder.mutation<SendOtpResponseType, SendOtpRequestType>({
            query: (data) => ({
                url: 'sendOTP',
                payload: data,
                method: 'POST'
            })
        }),
        otpVerification: builder.mutation<
            OtpVerificationResponseType,
            OtpVerificationRequestType
        >({
            query: (data) => ({
                url: 'otpVerification',
                payload: data,
                method: 'POST'
            })
        })
    })
})

export default userAuthentication
export const {
    useLoginMutation,
    useResendPasswordMutation,
    useVerifyOtpMutation,
    useResetPasswordMutation,
    useSendOtpMutation,
    useOtpVerificationMutation
} = userAuthentication
