import { createApi } from '@reduxjs/toolkit/dist/query/react'
import createBaseQuery from '../../../utilities/createBaseQuery'
import {
    AddLegalFirmRequestType,
    AddLegalFirmResponseType,
    LegalFirmListRequestType,
    LegalFirmListResponseType,
    LegalFirmManagerListResponseType,
    LocationListResponseType,
    LocationListRequestType,
    LegalFirmStaffListRequestType,
    LegalFirmStaffListResponseType,
    LocationDropdownListResponseType,
    viewLegalFirmResponseType,
    StateListResponseType,
    editLegalFirmRequestType,
    editLegalFirmResponseType,
    AddStaffResponseType,
    AddStaffRequestType,
    ConfigurationFormRequestType,
    LegalFirmHistoryRequestType,
    LegalFirmHistoryResponseType,
    AddLocationRequestType,
    AddLocationResponseType,
    EditLocationResponseType,
    EditLocationRequestType,
    ViewLocationResponseType,
    ViewLocationRequestType,
    DeleteLocationResponseType,
    DeleteLocationRequestType,
    ViewStaffRequestType,
    ViewStaffResponseType,
    EditStaffRequestType,
    EditStaffResponseType,
    ConfigurationFormResponseType,
    ViewConfigurationRequestType,
    ViewConfigurationResponseType,
    AgentAuthListRequestType,
    AgentAuthListResponseType,
    deleteAgentAuthRequestType,
    deleteAgentAuthResponseType,
    ClientAuthListResponseType,
    ClientAuthListRequestType,
    deleteClientAuthRequestType,
    deleteClientAuthResponseType,
    ActivateClientAuthResponseType,
    ActivateClientAuthRequestType,
    ActivateClientTemplateResponseType,
    ActivateClientTemplateRequestType,
    DownloadAuthRequestType,
    DownloadAuthResponseType,
    GetFirmAbbreviationResponseType,
    GetFirmAbbreviationRequestType,
    GetLocationTagsRequestType,
    GetLocationTagsResponseType,
    GetFieldListsResponseType,
    GetMergeTemplateReqType,
    GetMergeTemplateRespType,
    SetMergeTemplateReqType,
    SetMergeTemplateRespType,
    FilterStaffRequestType,
    FilterStaffResponseType
} from '../interfaces'

const legalfirmsApi = createApi({
    reducerPath: 'legalfirms',
    baseQuery: createBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: ['agentAuthList', 'clientAuthList'],
    endpoints: (builder) => ({
        getStates: builder.query<StateListResponseType, void>({
            query: () => ({
                url: 'stateList',
                method: 'POST'
            })
        }),
        getLegalFirmsStaffList: builder.query<
            LegalFirmStaffListResponseType,
            LegalFirmStaffListRequestType
        >({
            query: (data) => ({
                url: 'firm/staffList',
                payload: data,
                method: 'POST'
            })
        }),
        getLocationDropdownList: builder.query<
            LocationDropdownListResponseType,
            {
                firm_id: string | undefined
                search_by: string | undefined
            }
        >({
            query: (data) => ({
                url: 'firm/filterLocation',
                payload: data,
                method: 'POST'
            })
        }),
        getLegalFirmsHistory: builder.query<
            LegalFirmHistoryResponseType,
            LegalFirmHistoryRequestType
        >({
            query: (data) => ({
                url: 'firm/history',
                payload: data,
                method: 'POST'
            })
        }),
        getLegalFirmsList: builder.query<
            LegalFirmListResponseType,
            LegalFirmListRequestType
        >({
            query: (data) => ({
                url: `firm/list`,
                payload: data,
                method: 'POST'
            })
        }),
        getAccountManagerList: builder.query<
            LegalFirmManagerListResponseType,
            void
        >({
            query: () => ({
                url: `firm/accountManagerFilter`,
                method: 'POST'
            })
        }),

        getLegalFirmsLocations: builder.query<
            LocationListResponseType,
            LocationListRequestType
        >({
            query: (data) => ({
                url: `firm/locationList`,
                payload: data,
                method: 'POST'
            })
        }),
        getAuthorizationList: builder.query<
            AgentAuthListResponseType,
            AgentAuthListRequestType
        >({
            query: (data) => ({
                url: 'firm/agentAuthList',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.00001
            // providesTags: ['agentAuthList']
        }),
        deleteAgentAuthorization: builder.mutation<
            deleteAgentAuthResponseType,
            deleteAgentAuthRequestType
        >({
            query: (data) => ({
                url: `firm/deleteAgentAuth`,
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['agentAuthList']
        }),
        downloadAgentTemplate: builder.mutation<
            DownloadAuthResponseType,
            DownloadAuthRequestType
        >({
            query: (data) => ({
                url: 'firm/downloadAgentAuth',
                payload: data,
                method: 'POST'
            })
        }),
        addLegalFirm: builder.mutation<
            AddLegalFirmResponseType,
            AddLegalFirmRequestType
        >({
            query: (data) => ({
                url: 'firm/addFirm',
                payload: data,
                method: 'POST'
            })
        }),
        viewLegalFirm: builder.query<
            viewLegalFirmResponseType,
            {
                staff_firm_id: string
                staff_location_id: string
                firm_id: string
            }
        >({
            query: (data) => ({
                url: 'firm/viewFirm',
                payload: data,
                method: 'POST'
            })
        }),
        editLegalFirm: builder.mutation<
            editLegalFirmResponseType,
            editLegalFirmRequestType
        >({
            query: (data) => ({
                url: 'firm/editFirm',
                payload: data,
                method: 'POST'
            })
        }),
        addStaff: builder.mutation<AddStaffResponseType, AddStaffRequestType>({
            query: (data) => ({
                url: 'firm/addStaff',
                payload: data,
                method: 'POST'
            })
        }),
        viewStaff: builder.query<ViewStaffResponseType, ViewStaffRequestType>({
            query: (data) => ({
                url: 'firm/viewStaff',
                payload: data,
                method: 'POST'
            })
        }),
        editStaff: builder.mutation<
            EditStaffResponseType,
            EditStaffRequestType
        >({
            query: (data) => ({
                url: 'firm/editStaff',
                payload: data,
                method: 'POST'
            })
        }),
        addEditConfiguration: builder.mutation<
            ConfigurationFormResponseType,
            ConfigurationFormRequestType
        >({
            query: (data) => ({
                url: 'firm/addEditConfiguration',
                payload: data,
                method: 'POST'
            })
        }),
        viewConfiguration: builder.query<
            ViewConfigurationResponseType,
            ViewConfigurationRequestType
        >({
            query: (data) => ({
                url: 'firm/viewConfiguration',
                payload: data,
                method: 'POST'
            })
        }),
        addLocation: builder.mutation<
            AddLocationResponseType,
            AddLocationRequestType
        >({
            query: (data) => ({
                url: 'firm/addLocation',
                payload: data,
                method: 'POST'
            })
        }),
        editLocation: builder.mutation<
            EditLocationResponseType,
            EditLocationRequestType
        >({
            query: (data) => ({
                url: 'firm/editLocation',
                payload: data,
                method: 'POST'
            })
        }),
        viewLocation: builder.query<
            ViewLocationResponseType,
            ViewLocationRequestType
        >({
            query: (data) => ({
                url: 'firm/viewLocation',
                payload: data,
                method: 'POST'
            })
        }),
        deleteLocation: builder.mutation<
            DeleteLocationResponseType,
            DeleteLocationRequestType
        >({
            query: (data) => ({
                url: 'firm/deleteLocation',
                payload: data,
                method: 'POST'
            })
        }),
        setDefault: builder.mutation<
            DeleteLocationResponseType,
            DeleteLocationRequestType
        >({
            query: (data) => ({
                url: 'firm/setDefaultLocation',
                payload: data,
                method: 'POST'
            })
        }),
        getClientAuthorizationList: builder.query<
            ClientAuthListResponseType,
            ClientAuthListRequestType
        >({
            query: (data) => ({
                url: 'firm/clientAuthList',
                payload: data,
                method: 'POST'
            }),

            transformResponse: (response: ClientAuthListResponseType) => {
                if (typeof response === 'string') {
                    return {
                        response: {
                            raws: {
                                success_message: 'Data fetch successfully.',
                                data: {
                                    total_count: 0,
                                    dataset: []
                                },
                                publish: {
                                    version: '1_0_0',
                                    developer: 'www.massoftind.com'
                                }
                            }
                        }
                    }
                }

                return response
            },
            providesTags: ['clientAuthList']
        }),
        deleteClientAuthorization: builder.mutation<
            deleteClientAuthResponseType,
            deleteClientAuthRequestType
        >({
            query: (data) => ({
                url: 'firm/deleteClientAuth',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['clientAuthList']
        }),
        activateClientAuthorization: builder.mutation<
            ActivateClientAuthResponseType,
            ActivateClientAuthRequestType
        >({
            query: (data) => ({
                url: 'firm/activateClientAuth',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['clientAuthList']
        }),
        activateClientTemplate: builder.query<
            ActivateClientTemplateResponseType,
            ActivateClientTemplateRequestType
        >({
            query: (data) => ({
                url: 'firm/activateClientTemplate',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['clientAuthList']
        }),
        downloadClientTemplate: builder.mutation<
            DownloadAuthResponseType,
            DownloadAuthRequestType
        >({
            query: (data) => ({
                url: 'firm/downloadClientAuth',
                payload: data,
                method: 'POST'
            })
        }),
        getFirmAbbreviation: builder.query<
            GetFirmAbbreviationResponseType,
            GetFirmAbbreviationRequestType
        >({
            query: (data) => ({
                url: `firm/getFirmAbbreviation`,
                payload: data,
                method: 'POST'
            })
        }),
        getLocationTags: builder.query<
            GetLocationTagsResponseType,
            GetLocationTagsRequestType
        >({
            query: (data) => ({
                url: 'firm/getLocationTag',
                payload: data,
                method: 'POST'
            })
        }),
        getFieldLists: builder.query<GetFieldListsResponseType, null>({
            query: (data) => ({
                url: 'firm/authFieldList',
                payload: data,
                method: 'POST'
            })
        }),
        getMergeTemplate: builder.query<
            GetMergeTemplateRespType,
            GetMergeTemplateReqType
        >({
            query: (data) => ({
                url: 'firm/viewMergeTemplate',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 1
        }),
        setMergeTemplate: builder.mutation<
            SetMergeTemplateRespType,
            SetMergeTemplateReqType
        >({
            query: (data) => ({
                url: 'firm/mergeTemplate',
                payload: data,
                method: 'POST'
            })
        }),
        filterStaff: builder.query<
            FilterStaffResponseType,
            FilterStaffRequestType
        >({
            query: (data) => ({
                url: 'firm/filterStaff',
                payload: data,
                method: 'POST'
            })
        })
    })
})

export default legalfirmsApi

export const {
    useGetLegalFirmsStaffListQuery,
    useGetLocationDropdownListQuery,
    useGetLegalFirmsLocationsQuery,
    useGetLegalFirmsHistoryQuery,
    useGetAuthorizationListQuery,
    useGetLegalFirmsListQuery,
    useAddLegalFirmMutation,
    useGetAccountManagerListQuery,
    useGetStatesQuery,
    useViewLegalFirmQuery,
    useEditLegalFirmMutation,
    useAddStaffMutation,
    useViewStaffQuery,
    useEditStaffMutation,
    useAddEditConfigurationMutation,
    useAddLocationMutation,
    useEditLocationMutation,
    useViewLocationQuery,
    useDeleteLocationMutation,
    useSetDefaultMutation,
    useGetClientAuthorizationListQuery,
    useViewConfigurationQuery,
    useDeleteAgentAuthorizationMutation,
    useDeleteClientAuthorizationMutation,
    useActivateClientAuthorizationMutation,
    useActivateClientTemplateQuery,
    useDownloadAgentTemplateMutation,
    useDownloadClientTemplateMutation,
    useGetFirmAbbreviationQuery,
    useGetLocationTagsQuery,
    useGetFieldListsQuery,
    useGetMergeTemplateQuery,
    useSetMergeTemplateMutation,
    useFilterStaffQuery
} = legalfirmsApi
