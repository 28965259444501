import React from 'react'
import { Portal } from '@chakra-ui/react'

interface PropsSignature {
    portalId: React.MutableRefObject<HTMLDivElement>
    globalLayout: boolean
    children: React.ReactElement
}
const PortalForLayout: React.FC<PropsSignature> = (props) => {
    const { portalId, globalLayout, children } = props

    if (globalLayout) {
        return (
            <Portal containerRef={portalId}>
                {/* <SlideFade in={!!children} reverse unmountOnExit> */}
                {/* <motion.div
                    className='animated-portal'
                    initial={{ opacity: 0, y: 20 }}
                    animate={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.5 }
                    }}
                > */}
                {children}
                {/* </motion.div> */}
                {/* </SlideFade> */}
            </Portal>
        )
    }
    return (
        // <SlideFade in={!!children} reverse unmountOnExit>
        // <motion.div
        //     className='animated-portal'
        //     initial={{ opacity: 0, y: 20 }}
        //     animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        // >
        <div className='hasNoGlobalLayout'>{children}</div>
        // </motion.div>
        // <div>{children}</div>
        // </SlideFade>
    )
}

export default PortalForLayout
