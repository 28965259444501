import { createApi } from '@reduxjs/toolkit/dist/query/react'
import createBaseQuery from '../../../utilities/createBaseQuery'
import {
    GetClientAuthListRequestType,
    GetClientAuthListResponseType,
    DeleteClientAuthResponseType,
    DeleteClientAuthRequestType,
    GetActivatedTemplateDataResponseType,
    GetActivatedTemplateDataRequestType,
    ActivateClientAuthResponseType,
    ActivateClientAuthRequestType,
    DownloadClientAuthResponseType,
    DownloadClientAuthRequestType,
    GetFieldListsResponseType,
    GetMergeTemplateRespType,
    GetMergeTemplateReqType,
    SetMergeTemplateRespType,
    SetMergeTemplateReqType
} from '../interfaces'

const clientAuthApi = createApi({
    reducerPath: 'clientAuth',
    baseQuery: createBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: ['clientAuthList', 'activatedTemplateData'],
    endpoints: (builder) => ({
        getClientAuthList: builder.query<
            GetClientAuthListResponseType,
            GetClientAuthListRequestType
        >({
            query: (data) => ({
                url: 'firm/clientAuthList',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.0001,
            providesTags: ['clientAuthList']
        }),
        getActivatedTemplateData: builder.query<
            GetActivatedTemplateDataResponseType,
            GetActivatedTemplateDataRequestType
        >({
            query: (data) => ({
                url: 'firm/activateClientTemplate',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['activatedTemplateData']
        }),
        downloadClientAuth: builder.mutation<
            DownloadClientAuthResponseType,
            DownloadClientAuthRequestType
        >({
            query: (data) => ({
                url: 'firm/downloadClientAuth',
                payload: data,
                method: 'POST'
            })
        }),
        deleteClientAuth: builder.mutation<
            DeleteClientAuthResponseType,
            DeleteClientAuthRequestType
        >({
            query: (data) => ({
                url: 'firm/deleteClientAuth',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['clientAuthList']
        }),
        activateClientAuth: builder.mutation<
            ActivateClientAuthResponseType,
            ActivateClientAuthRequestType
        >({
            query: (data) => ({
                url: 'firm/activateClientAuth',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['clientAuthList', 'activatedTemplateData']
        }),
        getFieldLists: builder.query<GetFieldListsResponseType, null>({
            query: (data) => ({
                url: 'firm/authFieldList',
                payload: data,
                method: 'POST'
            })
        }),
        getMergeTemplate: builder.query<
            GetMergeTemplateRespType,
            GetMergeTemplateReqType
        >({
            query: (data) => {
                console.log(data, 'ppdd')
                return {
                    url: 'firm/viewMergeTemplate',
                    payload: data,
                    method: 'POST'
                }
            },
            keepUnusedDataFor: 1
        }),
        setMergeTemplate: builder.mutation<
            SetMergeTemplateRespType,
            SetMergeTemplateReqType
        >({
            query: (data) => ({
                url: 'firm/mergeTemplate',
                payload: data,
                method: 'POST'
            })
        })
    })
})

export default clientAuthApi

export const {
    useGetClientAuthListQuery,
    useGetActivatedTemplateDataQuery,
    useDownloadClientAuthMutation,
    useDeleteClientAuthMutation,
    useActivateClientAuthMutation,
    useGetFieldListsQuery,
    useGetMergeTemplateQuery,
    useSetMergeTemplateMutation
} = clientAuthApi
