import React, {
    useState,
    useRef,
    Suspense,
    lazy,
    useCallback,
    ComponentType,
    useEffect
} from 'react'

// eslint-disable-next-line camelcase
import { Provider, useDispatch, useSelector } from 'react-redux'
import { Container, Center, useToast } from '@chakra-ui/react'
import axios from 'axios'
import { AnimatePresence } from 'framer-motion'
import { PersistGate } from 'redux-persist/integration/react'
// import { createMemoryHistory } from 'history'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
    Navigate,
    useNavigate
} from 'react-router-dom'
import HashLoader from 'react-spinners/HashLoader'
import Store, { persistStorage, RootState } from '../store/store'
import PageTransition from '../modules/global/pageTransition'
import DetectOffline from '../modules/global/components/offline'
import AuthenticatedRoute from './authenticatedRoute'
import StrictRoute from './strictRoute'
import useAvailSw from '../modules/utilities/hooks/useAvailSw'
import PortalForLayout from '../modules/global/components/PortalForLayout'
import Layout from '../modules/global/components/layout'
import MetaWrapper from '../utilities/metaWrapper'
import Profile from '../modules/profile/components/profile'
import {
    updateLoginStatus,
    updateLogoutStatus,
    updateTokenTimeout
} from '../modules/authentication/slices'
import GlobalCss from '../modules/global/css/index'
import responseHandler, { axiosInterceptor } from '../utilities/responseHandler'

/**
 * Preload lazy loaded component, check below pattern
 * You can pass / export specific preload component function
 * to any component and can prefetch route stratigically
 */
interface PreloaderSignature {
    [preLoad: string]: () => Promise<{ default: ComponentType<any> }>
}
const Preloaders: PreloaderSignature = {}

const handleImport = (promise: Promise<any>) =>
    promise.catch(() => window.location.reload())

Preloaders.preloadRootPage = () =>
    handleImport(import('../modules/authentication/components/rootpage'))
const Rootpage = lazy(Preloaders.preloadRootPage)

Preloaders.preloadManageCases = () =>
    handleImport(import('../modules/cases/components/manageCases'))
const ManageCases = lazy(Preloaders.preloadManageCases)

Preloaders.preloadLogin = () =>
    handleImport(import('../modules/authentication/components/login'))
const Login = lazy(Preloaders.preloadLogin)

Preloaders.preloadDashboard = () =>
    handleImport(import('../modules/dashboard/components'))
const Dashboard = lazy(Preloaders.preloadDashboard)

Preloaders.preloadStaffs = () =>
    handleImport(import('../modules/staff/components'))
const StaffList = lazy(Preloaders.preloadStaffs)

Preloaders.preloadForgotPassword = () =>
    handleImport(import('../modules/authentication/components/forgotpassword'))
const ForgotPassword = lazy(Preloaders.preloadForgotPassword)

Preloaders.preloadVerifyOtp = () =>
    handleImport(import('../modules/authentication/components/verifyotp'))
const VerifyOtp = lazy(Preloaders.preloadVerifyOtp)

Preloaders.preloadResetPassword = () =>
    handleImport(import('../modules/authentication/components/resetpassword'))
const ResetPassword = lazy(Preloaders.preloadResetPassword)

Preloaders.preloadUpdateEmail = () =>
    handleImport(import('../modules/authentication/components/updateEmail'))
const UpdateEmail = lazy(Preloaders.preloadUpdateEmail)

Preloaders.preloadConfirmEmail = () =>
    handleImport(import('../modules/authentication/components/confirmEmail'))
const ConfirmEmail = lazy(Preloaders.preloadConfirmEmail)

Preloaders.preloadVerifyEmail = () =>
    handleImport(import('../modules/authentication/components/verifyEmail'))
const VerifyEmail = lazy(Preloaders.preloadVerifyEmail)

Preloaders.preloadChangePassword = () =>
    handleImport(import('../modules/authentication/components/changePassword'))
const ChangePassword = lazy(Preloaders.preloadChangePassword)

Preloaders.preloadClients = () =>
    handleImport(import('../modules/clients/components'))
const Clients = lazy(Preloaders.preloadClients)

Preloaders.preloadEditLegalFirm = () =>
    handleImport(import('../modules/legalfirms/components/editlegalfirm'))
const EditLegalFirm = lazy(Preloaders.preloadEditLegalFirm)

Preloaders.preloadAddClient = () =>
    handleImport(import('../modules/clients/components/addclient'))
const AddClient = lazy(Preloaders.preloadAddClient)

Preloaders.preloadManageLegalFirm = () =>
    handleImport(import('../modules/legalfirms/components/managelegalfirm'))
const ManageLegalFirm = lazy(Preloaders.preloadManageLegalFirm)

Preloaders.preloadAddEditStuff = () =>
    handleImport(import('../modules/staff/components/addEditStaff'))
const AddEditStaff = lazy(Preloaders.preloadAddEditStuff)

Preloaders.preloadAgentAuth = () =>
    handleImport(import('../modules/agentAuth/components/agentAuth'))
const AgentAuth = lazy(Preloaders.preloadAgentAuth)

Preloaders.preloadClientAuth = () =>
    handleImport(import('../modules/clientAuth/components/clientAuth'))
const ClientAuth = lazy(Preloaders.preloadClientAuth)

Preloaders.preloadManageClient = () =>
    handleImport(import('../modules/clients/components/manageclients'))
const ManageClients = lazy(Preloaders.preloadManageClient)

Preloaders.preloadPdfMaker = () =>
    handleImport(import('../modules/clientAuth/components/pdfMarker'))
const PdfMarker = lazy(Preloaders.preloadPdfMaker)

Preloaders.preloadCase = () =>
    handleImport(import('../modules/cases/components'))
const Cases = lazy(Preloaders.preloadCase)

Preloaders.preloadAddCase = () =>
    handleImport(import('../modules/cases/components/addcase'))
const AddCase = lazy(Preloaders.preloadAddCase)

Preloaders.preloadRequests = () =>
    handleImport(import('../modules/requests/components'))
const Requests = lazy(Preloaders.preloadRequests)

Preloaders.preloadAddNewRequest = () =>
    handleImport(import('../modules/requests/components/addrequest'))
const AddNewRequest = lazy(Preloaders.preloadAddNewRequest)

Preloaders.preloadManageRecord = () =>
    handleImport(import('../modules/requests/components/managerecord'))
const ManageRecord = lazy(Preloaders.preloadManageRecord)

Preloaders.preloadActivity = () =>
    handleImport(import('../modules/activity/components/activity'))
const Activity = lazy(Preloaders.preloadActivity)

const AllRoutes = () => {
    const { REACT_APP_VERSION, REACT_APP_API_BASE_URL } = process.env
    const locate = useLocation()
    const dispatch = useDispatch()
    const toast = useToast()
    const { SWI } = useAvailSw()
    const navigate = useNavigate()
    const timeToken: {
        current: ReturnType<typeof setTimeout> | null
    } = useRef(null)
    const authSliceData = useSelector((state: RootState) => state.auth)

    const logMeOut = useCallback(
        (path?: string) => {
            Store.dispatch(updateLogoutStatus())
            persistStorage.flush().then(() => {
                persistStorage.purge().then(() => {
                    localStorage.clear()
                    sessionStorage.clear()
                    if (path) {
                        navigate(path, { replace: true })
                    } else if (
                        process.env
                            .REACT_APP_AUTO_LOGOUT_ON_TOKEN_EXPIRE_SCREEN ===
                        'true'
                    ) {
                        // window.location.replace('/forcedLogout')
                        navigate('/forcedLogout', { replace: true })
                    } else {
                        navigate('/', { replace: true })
                    }
                })
            })
        },
        [navigate]
    )
    const isTokenStillValid = useRef<null | number>(null)

    /**
     * This useEffect handling the case i.e. if you close the browser without doing logout
     * then it will show you dashboard with stale data next time when you open it up and
     * will log you out with very first api call as the exsisting token is not valid one.
     * Its currently allowing the case where you are closing all the tabs and opening again
     * within the valid token time period.
     */
    useEffect(() => {
        if (authSliceData.token) {
            isTokenStillValid.current =
                authSliceData.initializationTime * 1000 +
                authSliceData.logoutTime -
                Date.now()
            if (isTokenStillValid.current < 1) {
                const params = new FormData()
                params.append('token', `Bearer ${authSliceData.token}`)
                params.append('appversion', REACT_APP_VERSION as string)
                params.append(
                    'time_zone',
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                )
                navigator.sendBeacon(`${REACT_APP_API_BASE_URL}logout`, params)
                logMeOut()
            }
        }
    }, [])

    /**
     * Auto Logout code here
     */
    useEffect(() => {
        if (authSliceData.token) {
            const timeVal =
                authSliceData.initializationTime * 1000 +
                authSliceData.logoutTime -
                Date.now()
            if (timeToken.current) {
                clearTimeout(timeToken.current)
            }
            timeToken.current = setTimeout(() => {
                const params = new FormData()
                params.append('token', `Bearer ${authSliceData.token}`)
                params.append('appversion', REACT_APP_VERSION as string)
                params.append(
                    'time_zone',
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                )
                navigator.sendBeacon(`${REACT_APP_API_BASE_URL}logout`, params)
                logMeOut()
            }, timeVal)
        }
    }, [authSliceData.isTokenRefresh])

    // After refresh token received to notify the other tabs which are not focused
    useEffect(() => {
        let payload = {}
        payload = {
            token: authSliceData.token,
            logoutTime: authSliceData.logoutTime,
            isTokenRefresh: authSliceData.isTokenRefresh,
            initializationTime: authSliceData.initializationTime,
            refreshDuration: authSliceData.refreshDuration
        }

        if (SWI) {
            SWI.ready.then((registration) => {
                if ('controller' in SWI && SWI.controller !== null) {
                    SWI.controller.postMessage({
                        type: 'tokenRefresh',
                        payload
                    })
                }
            })
        }
    }, [authSliceData.brodCastFlagVal])

    /**
     * Multiple tabs auto login / logout using serviceWorker
     */
    useEffect(() => {
        if (SWI !== null || SWI !== undefined) {
            SWI?.addEventListener('message', (payload) => {
                if (payload.data.type === 'shouldTokenRefresh') {
                    if (authSliceData.token !== payload.data.data.token) {
                        dispatch(
                            updateLoginStatus({
                                loggedIn: !!payload.data.data.token,
                                token: payload.data.data.token,
                                refreshDuration:
                                    payload.data.data.refreshDuration
                            })
                        )

                        dispatch(
                            updateTokenTimeout({
                                logoutTime: payload.data.data.logoutTime,
                                isTokenRefresh:
                                    payload.data.data.isTokenRefresh,
                                initializationTime:
                                    payload.data.data.initializationTime
                            })
                        )
                    }
                }
                if (payload.data.type === 'shouldLogOut') {
                    window.location.reload()
                }
                if (payload.data.type === 'shouldLogIn') {
                    window.location.reload()
                }
            })
        }
    }, [SWI])

    useEffect(() => {
        axios.interceptors.response.eject(axiosInterceptor as unknown as number)
        responseHandler({ logMeOut, toast, authSliceData })
    }, [])

    const [globalStaticFragmennt, setGlobalStaticFragmennt] = useState<boolean>(
        () => false
    )

    const newSetGlobalStaticFragmennt = useCallback((value: boolean) => {
        setGlobalStaticFragmennt(value)
    }, [])

    const commonPortalRef = useRef() as React.MutableRefObject<HTMLInputElement>

    return (
        <>
            {globalStaticFragmennt ? <Layout portalId={commonPortalRef} /> : ''}
            <AnimatePresence exitBeforeEnter>
                {/** * StrictRoute are those which is not Authenticated route and
                only accessible when you are NOT logged in / logged out state.
                Once you are logged in, you will be redirected to provided route
                on trying to access it. So this idea tends you toward
                possibility of having a route which is not strict and accessible
                whether you are logged in or not. If you insert a route which is
                not mean to be Authenticated and if you are not wrapping that
                route with StrictRoute, then you will be able to access it. * *
                MetaWrapper is the mandatory component to wrap all the routes
                individually. It consist of meta tags and title and it controls
                AXIOS interceptor to handle api response and error and we needed
                to use useNavigate and useDispatch hooks to handle redirection.
                As you may know hooks are only available into component, I had
                to keep the functionality into this component and I had no
                intension to add one more layer of abstraction. * *
                Authenticated routes are those which is accessible only when you
                are logged in. */}
                <Suspense
                    fallback={
                        <Center h='100vh'>
                            {/* <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='fvGreen.100'
                                size='xl'
                            /> */}
                            <HashLoader
                                color='#105380'
                                size={20}
                                speedMultiplier={1}
                            />
                        </Center>
                    }
                >
                    <Routes location={locate} key={locate.key}>
                        <Route
                            path='/'
                            element={
                                // <StrictRoute redirectTo='/dashboard'>
                                <MetaWrapper
                                    title='Login'
                                    meta={[
                                        {
                                            name: 'og:locale',
                                            content: 'en_US'
                                        }
                                    ]}
                                >
                                    <PortalForLayout
                                        globalLayout={globalStaticFragmennt}
                                        portalId={commonPortalRef}
                                    >
                                        <Rootpage
                                            globalLayout={
                                                setGlobalStaticFragmennt
                                            }
                                        />
                                    </PortalForLayout>
                                </MetaWrapper>
                                // </StrictRoute>
                            }
                        >
                            <Route
                                index
                                element={
                                    <StrictRoute>
                                        <MetaWrapper
                                            title='Login'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PageTransition>
                                                <Login />
                                            </PageTransition>
                                        </MetaWrapper>
                                    </StrictRoute>
                                }
                            />
                            <Route
                                path='forgot-password'
                                element={
                                    <StrictRoute>
                                        <MetaWrapper
                                            title='Forgot Password'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PageTransition>
                                                <ForgotPassword />
                                            </PageTransition>
                                        </MetaWrapper>
                                    </StrictRoute>
                                }
                            />
                            <Route
                                path='/verify-otp'
                                element={
                                    <StrictRoute>
                                        <MetaWrapper
                                            title='One Time Password'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <VerifyOtp />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </StrictRoute>
                                }
                            />
                            <Route
                                path='/reset-password'
                                element={
                                    <StrictRoute>
                                        <MetaWrapper
                                            title='Reset Password'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <ResetPassword />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </StrictRoute>
                                }
                            />
                            <Route
                                path='/update-email'
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Update Email'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <UpdateEmail />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path='/confirm-email'
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Confirm Email'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <ConfirmEmail />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path='/verify-email'
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Verify Email'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <VerifyEmail />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path='/change-password'
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Change Password'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <ChangePassword />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            />
                        </Route>
                        <Route
                            path='/dashboard'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Dashboard'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <Dashboard
                                                globalLayout={
                                                    setGlobalStaticFragmennt
                                                }
                                            />
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />

                        <Route
                            path='/cases'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Cases'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <PageTransition>
                                                <Cases
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PageTransition>
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path='/cases/add'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Add Case'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <PageTransition>
                                                <AddCase
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PageTransition>
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />
                        <Route path='/cases/:subModule/:action/:id'>
                            <Route
                                index
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Manage Cases'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <ManageCases
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path=':subAction'
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Manage Cases'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <ManageCases
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            >
                                <Route
                                    path=':subId'
                                    element={
                                        <AuthenticatedRoute>
                                            <MetaWrapper
                                                title='Manage Cases'
                                                meta={[
                                                    {
                                                        name: 'og:locale',
                                                        content: 'en_US'
                                                    }
                                                ]}
                                            >
                                                <PortalForLayout
                                                    globalLayout={
                                                        globalStaticFragmennt
                                                    }
                                                    portalId={commonPortalRef}
                                                >
                                                    <ManageCases
                                                        globalLayout={
                                                            newSetGlobalStaticFragmennt
                                                        }
                                                    />
                                                </PortalForLayout>
                                            </MetaWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />
                            </Route>
                        </Route>

                        <Route
                            path='/clients'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Clients'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <PageTransition>
                                                <Clients
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PageTransition>
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path='/clients/add'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Add Client'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <PageTransition>
                                                <AddClient
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PageTransition>
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />

                        <Route path='/clients/:subModule'>
                            <Route
                                index
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Manage Clients'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <ManageClients
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path=':action'
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Manage Clients'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <ManageClients
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            >
                                <Route
                                    path=':id'
                                    element={
                                        <AuthenticatedRoute>
                                            <MetaWrapper
                                                title='Manage Clients'
                                                meta={[
                                                    {
                                                        name: 'og:locale',
                                                        content: 'en_US'
                                                    }
                                                ]}
                                            >
                                                <PortalForLayout
                                                    globalLayout={
                                                        globalStaticFragmennt
                                                    }
                                                    portalId={commonPortalRef}
                                                >
                                                    <ManageClients
                                                        globalLayout={
                                                            newSetGlobalStaticFragmennt
                                                        }
                                                    />
                                                </PortalForLayout>
                                            </MetaWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />
                            </Route>
                        </Route>

                        <Route
                            path='/legal-firm-details/:action'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Legal Firms'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <PageTransition>
                                                <EditLegalFirm
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PageTransition>
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path='/legal-firms/add'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Add Legal Firm'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <PageTransition>
                                                <EditLegalFirm
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PageTransition>
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />

                        <Route path='/legal-firms/:subModule/:action/:id'>
                            <Route
                                index
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Manage Legal Firms'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <ManageLegalFirm
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path=':subAction'
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Manage Legal Firms'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <ManageLegalFirm
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            >
                                <Route
                                    path=':subId'
                                    element={
                                        <AuthenticatedRoute>
                                            <MetaWrapper
                                                title='Manage Legal Firms'
                                                meta={[
                                                    {
                                                        name: 'og:locale',
                                                        content: 'en_US'
                                                    }
                                                ]}
                                            >
                                                <PortalForLayout
                                                    globalLayout={
                                                        globalStaticFragmennt
                                                    }
                                                    portalId={commonPortalRef}
                                                >
                                                    <ManageLegalFirm
                                                        globalLayout={
                                                            newSetGlobalStaticFragmennt
                                                        }
                                                    />
                                                </PortalForLayout>
                                            </MetaWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />
                            </Route>
                        </Route>

                        <Route
                            path='/staff-management'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Staff Management'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <PageTransition>
                                                <StaffList
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PageTransition>
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />

                        <Route
                            path='/staff-management/add-staff'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Add New Staff'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <AddEditStaff
                                                isAdd
                                                globalLayout={
                                                    newSetGlobalStaticFragmennt
                                                }
                                            />
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />

                        <Route path='/staff-management/:action/:id'>
                            <Route
                                index
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Staff Management'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <PageTransition>
                                                    <AddEditStaff
                                                        globalLayout={
                                                            newSetGlobalStaticFragmennt
                                                        }
                                                    />
                                                </PageTransition>
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path=':action'
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Staff Management'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <AddEditStaff
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path=':subId'
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Staff Management'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <AddEditStaff
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            />
                        </Route>

                        <Route
                            path='/agent-authorization'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Agent Authorization'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <PageTransition>
                                                <AgentAuth
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PageTransition>
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path='/client-authorization/doc/:docId'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Manage Clients'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <PdfMarker
                                                globalLayout={
                                                    newSetGlobalStaticFragmennt
                                                }
                                            />
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path='/client-authorization'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Client Authorization'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <PageTransition>
                                                <ClientAuth
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PageTransition>
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />

                        <Route
                            path='/requests'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Requests'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <Requests
                                                globalLayout={
                                                    newSetGlobalStaticFragmennt
                                                }
                                            />
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />

                        <Route
                            path='/requests/add'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Add New Request'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <AddNewRequest
                                                isAdd
                                                globalLayout={
                                                    newSetGlobalStaticFragmennt
                                                }
                                            />
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />

                        <Route path='/requests/:subModule/:action/:id'>
                            <Route
                                index
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Manage Requests'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <ManageRecord
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            />
                            <Route
                                path=':subAction'
                                element={
                                    <AuthenticatedRoute>
                                        <MetaWrapper
                                            title='Manage Requests'
                                            meta={[
                                                {
                                                    name: 'og:locale',
                                                    content: 'en_US'
                                                }
                                            ]}
                                        >
                                            <PortalForLayout
                                                globalLayout={
                                                    globalStaticFragmennt
                                                }
                                                portalId={commonPortalRef}
                                            >
                                                <ManageRecord
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PortalForLayout>
                                        </MetaWrapper>
                                    </AuthenticatedRoute>
                                }
                            >
                                <Route
                                    path=':subId'
                                    element={
                                        <AuthenticatedRoute>
                                            <MetaWrapper
                                                title='Manage Requests'
                                                meta={[
                                                    {
                                                        name: 'og:locale',
                                                        content: 'en_US'
                                                    }
                                                ]}
                                            >
                                                <PortalForLayout
                                                    globalLayout={
                                                        globalStaticFragmennt
                                                    }
                                                    portalId={commonPortalRef}
                                                >
                                                    <ManageRecord
                                                        globalLayout={
                                                            newSetGlobalStaticFragmennt
                                                        }
                                                    />
                                                </PortalForLayout>
                                            </MetaWrapper>
                                        </AuthenticatedRoute>
                                    }
                                />
                            </Route>
                        </Route>
                        <Route
                            path='/recent-activity'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='activity'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <PageTransition>
                                                <Activity
                                                    globalLayout={
                                                        newSetGlobalStaticFragmennt
                                                    }
                                                />
                                            </PageTransition>
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path='/profile'
                            element={
                                <AuthenticatedRoute>
                                    <MetaWrapper
                                        title='Profile'
                                        meta={[
                                            {
                                                name: 'og:locale',
                                                content: 'en_US'
                                            }
                                        ]}
                                    >
                                        <PortalForLayout
                                            globalLayout={globalStaticFragmennt}
                                            portalId={commonPortalRef}
                                        >
                                            <Profile
                                                globalLayout={
                                                    newSetGlobalStaticFragmennt
                                                }
                                            />
                                        </PortalForLayout>
                                    </MetaWrapper>
                                </AuthenticatedRoute>
                            }
                        />
                        <Route path='*' element={<Navigate to='/' />} />
                    </Routes>
                </Suspense>
            </AnimatePresence>
        </>
    )
}

const RouteHolder = () => (
    <Provider store={Store}>
        <PersistGate persistor={persistStorage}>
            <GlobalCss />
            <Container
                display='flex'
                flexDirection='row'
                justifyContent='center'
                className='wrapper'
                p={0}
                maxW='100vw'
                minH='100vh'
                color='grey.100'
            >
                <DetectOffline showOverlay={false} showToast />

                <Router>
                    <AllRoutes />
                </Router>
            </Container>
        </PersistGate>
    </Provider>
)

export default RouteHolder
export { Preloaders }
