import { createApi } from '@reduxjs/toolkit/dist/query/react'
import createBaseQuery from '../../../utilities/createBaseQuery'
import {
    PaymentTypeListResponseType,
    PaymentTypeListRequestType,
    AddPaymentTypeResponseType,
    AddPaymentTypeRequestType,
    ViewPaymentTypeResponseType,
    ViewPaymentTypeRequestType,
    EditPaymentTypeRequestType,
    EditPaymentTypeResponseType,
    ChargeTypeListResponseType,
    ChargeTypeListRequestType,
    AddChargeTypeResponseType,
    AddChargeTypeRequestType,
    EditChargeTypeResponseType,
    EditChargeTypeRequestType,
    ViewChargeTypeResponseType,
    ViewChargeTypeRequestType,
    PaymentTypeOptionsResponseType,
    ChargeStatusListResponseType,
    ChargeStatusListRequestType,
    AddChargeStatusResponseType,
    AddChargeStatusRequestType,
    EditChargeStatusResponseType,
    EditChargeStatusRequestType,
    ViewChargeStatusResponseType,
    ViewChargeStatusRequestType
} from '../interfaces'

const othersApi = createApi({
    reducerPath: 'others',
    baseQuery: createBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: [
        'getPaymentTypeList',
        'getChargeTypeList',
        'getChargeStatusList'
    ],
    endpoints: (builder) => ({
        getPaymentTypeList: builder.query<
            PaymentTypeListResponseType,
            PaymentTypeListRequestType
        >({
            query: (data) => ({
                url: 'payment-type/list',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['getPaymentTypeList']
        }),
        getPaymentTypeOptions: builder.query<
            PaymentTypeOptionsResponseType,
            void
        >({
            query: (data) => ({
                url: 'payment-type/type',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['getPaymentTypeList']
        }),
        addPaymentType: builder.mutation<
            AddPaymentTypeResponseType,
            AddPaymentTypeRequestType
        >({
            query: (data) => ({
                url: 'payment-type/add',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['getPaymentTypeList']
        }),
        viewPaymentType: builder.query<
            ViewPaymentTypeResponseType,
            ViewPaymentTypeRequestType
        >({
            query: (data) => ({
                url: 'payment-type/view',
                payload: data,
                method: 'POST'
            })
        }),
        editPaymentType: builder.mutation<
            EditPaymentTypeResponseType,
            EditPaymentTypeRequestType
        >({
            query: (data) => ({
                url: 'payment-type/edit',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['getPaymentTypeList']
        }),

        getChargeTypeList: builder.query<
            ChargeTypeListResponseType,
            ChargeTypeListRequestType
        >({
            query: (data) => ({
                url: 'charge-type/list',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['getChargeTypeList']
        }),
        addChargeType: builder.mutation<
            AddChargeTypeResponseType,
            AddChargeTypeRequestType
        >({
            query: (data) => ({
                url: 'charge-type/add',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['getChargeTypeList']
        }),
        editChargeType: builder.mutation<
            EditChargeTypeResponseType,
            EditChargeTypeRequestType
        >({
            query: (data) => ({
                url: 'charge-type/edit',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['getChargeTypeList']
        }),
        viewChargeType: builder.query<
            ViewChargeTypeResponseType,
            ViewChargeTypeRequestType
        >({
            query: (data) => ({
                url: 'charge-type/view',
                payload: data,
                method: 'POST'
            })
        }),

        getChargeStatusList: builder.query<
            ChargeStatusListResponseType,
            ChargeStatusListRequestType
        >({
            query: (data) => ({
                url: 'charge-status/list',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['getChargeStatusList']
        }),
        addChargeStatus: builder.mutation<
            AddChargeStatusResponseType,
            AddChargeStatusRequestType
        >({
            query: (data) => ({
                url: 'charge-status/add',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['getChargeStatusList']
        }),
        editChargeStatus: builder.mutation<
            EditChargeStatusResponseType,
            EditChargeStatusRequestType
        >({
            query: (data) => ({
                url: 'charge-status/edit',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['getChargeStatusList']
        }),
        viewChargeStatus: builder.query<
            ViewChargeStatusResponseType,
            ViewChargeStatusRequestType
        >({
            query: (data) => ({
                url: 'charge-status/view',
                payload: data,
                method: 'POST'
            })
        })
    })
})

export default othersApi
export const {
    useGetPaymentTypeListQuery,
    useGetChargeTypeListQuery,
    useGetChargeStatusListQuery,
    useAddPaymentTypeMutation,
    useViewPaymentTypeQuery,
    useEditPaymentTypeMutation,
    useAddChargeTypeMutation,
    useViewChargeTypeQuery,
    useEditChargeTypeMutation,
    useGetPaymentTypeOptionsQuery,
    useAddChargeStatusMutation,
    useViewChargeStatusQuery,
    useEditChargeStatusMutation
} = othersApi
