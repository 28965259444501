/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AddIcon } from '@chakra-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
    Flex,
    Wrap,
    Box,
    Image,
    Heading,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    useBoolean,
    Progress,
    Text,
    keyframes,
    Button,
    Switch,
    FormControl,
    Link as Anchor
} from '@chakra-ui/react'

import { RootState } from '../../../store/store'
import Navigation from './navigation'
import AsideProfileMenu from './asideprofilemenu'
import { setDarkMode } from '../slices'

const CssWrapper = styled.div`
    .nav-collapse .sidebar nav {
        padding: 20px 13px 20px 8px;
    }

    .nav-collapse li.nav-item svg {
        margin: 0 auto;
        display: inline-block;
    }
    .nav-collapse li.nav-item:hover > a {
        background: var(--chakra-colors-lfgMainBlue);
    }

    .nav-collapse li.nav-item:hover span {
        visibility: visible;
        opacity: 1;
    }
    .nav-collapse .logo-img {
        clip: rect(0px, 46px, 45px, 0px);
    }

    .nav-collapse li.nav-item a[aria-current='page'] {
        border-radius: 8px;
    }

    .nav-collapse li.nav-item button[aria-expanded='false'] {
        width: 48px;
        border-radius: 8px;
        position: relative;
    }

    .nav-collapse li.nav-item button strong {
        text-align: center;
    }
    .chakra-accordion__panel {
        padding: 0px;
    }
    .nav-collapse li.nav-item .chakra-accordion__panel {
        padding: 5px 0px;
    }
    .nav-collapse .chakra-wrap__list .chakra-wrap__listitem > a > span {
        display: none;
    }
    .nav-collapse .chakra-accordion__button {
        width: 100%;
        flex-direction: column;
        align-items: start;
    }
    .nav-collapse .chakra-accordion__button > div {
        padding: 10px 15px 0px;
    }
    .nav-collapse .chakra-accordion__button > a {
        padding: 10px 15px 0px;
        border-right: none !important;
        border-bottom: 1px solid;
        border-color: #f1f7fd;
    }
    .nav-collapse .chakra-accordion__button span {
        display: none;
        transition: all 0.4s ease-in-out;
        opacity: 0;
    }

    .nav-collapse .chakra-wrap__listitem .chakra-accordion button + svg {
        position: absolute;
        right: 15px;
        top: 28px;
        // transition: opacity 0.4s ease 0s;
    }
    .nav-collapse
        .chakra-collapse
        .chakra-accordion__panel
        .chakra-wrap__listitem {
        width: 100%;
    }
    .nav-collapse .chakra-collapse .chakra-accordion__panel {
        padding: 0px;
    }
    .nav-collapse
        .chakra-collapse
        .chakra-accordion__panel
        .chakra-wrap__listitem
        > a
        > span {
        display: none;
    }
    .nav-collapse .chakra-accordion__button[aria-expanded='true'] {
        width: 100%;
    }
    .nav-collapse .navHolder .chakra-wrap__list .chakra-wrap__listitem {
        width: 243px;
    }
    .chakra-collapse
        .chakra-wrap__listitem
        .chakra-collapse
        .chakra-accordion__panel
        .chakra-wrap__list
        > a
        > span {
        display: none;
    }
    .navigation .sidebar .chakra-button .bottom-menu {
        display: block;
        transition: 0.4s ease all;
    }
    .nav-collapse .navigation .sidebar .chakra-button .bottom-menu {
        display: none;
        transition: 0.4s ease all;
    }
    .chakra-wrap__listitem
        .chakra-accordion__button
        .chakra-icon.chakra-accordion__icon {
        width: 19px;
        height: 19px;
        margin: 0 auto;
    }
    .chakra-wrap__listitem .chakra-accordion__button a + svg {
        width: 22px !important;
        height: 19px !important;
    }

    @media only screen and (max-width: 1023px) {
        .navigation {
            z-index: 111;
        }
        .logo-img {
            transition: 0.4s ease all;
        }

        .nav-collapse li.nav-item button[aria-expanded='true'] {
            width: 100%;
        }
        .nav-collapse li.nav-item button[aria-expanded='false'] {
            width: 100%;
        }
        .nav-collapse li.nav-item a {
            width: 100%;
        }
        .nav-collapse li.nav-item span {
            opacity: 1;
        }
        .nav-collapse li.nav-item button strong > svg.on-expanded {
            opacity: 1;
            visibility: visible;
        }
        .nav-collapse li.nav-item button strong > svg.on-collapse {
            opacity: 0;
            visibility: hidden;
            bottom: 0px;
        }
        .nav-collapse .bottom-menu {
            opacity: 1;
        }
        .nav-collapse .logo-img {
            clip: unset;
        }

        .nav-collapse .chakra-wrap__list .chakra-wrap__listitem > a > span {
            display: block;
        }
        .nav-collapse .chakra-accordion__button {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .nav-collapse .chakra-accordion__button span {
            display: block;
            transition: all 0.4s ease-in-out;
            opacity: 1;
        }
        .nav-collapse
            .chakra-collapse
            .chakra-accordion__panel
            .chakra-wrap__listitem
            > a
            > span {
            display: block;
        }
        .nav-collapse .chakra-wrap__listitem .chakra-accordion button + svg {
            position: absolute;
            right: 15px;
            top: 15px;
        }
        .chakra-collapse
            .chakra-wrap__listitem
            .chakra-collapse
            .chakra-accordion__panel
            .chakra-wrap__list
            > a
            > span {
            display: block;
        }
        .nav-collapse .chakra-accordion__button > div {
            padding: 10px 15px;
        }
        button.chakra-button.responsive_Btn {
            height: 30px;
            width: 30px;
            padding: 0;
        }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
    }
    @media (min-width: 980px) and (max-width: 1023px) {
    }
`

interface Signature {
    portalId: React.MutableRefObject<HTMLDivElement>
}

const Layout: React.FC<Signature> = ({ portalId }) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const globalState = useSelector((state: RootState) => state.GlobalLoader)
    const { REACT_APP_TIMEOUT } = process.env
    const breadcrumbOption = pathname.split(/\//).filter(Boolean) || []
    const remains = breadcrumbOption.splice(1, breadcrumbOption.length)
    const combined = [...breadcrumbOption, remains.join('/')]
    const [menuCollapse, setmenuCollapse] = useBoolean(false)
    const [showLOader, setShowLoader] = useState(() => false)
    //
    const timeout: { current: ReturnType<typeof setTimeout> | undefined } =
        useRef()

    const { apiPending, breadCrumbs } = useSelector(
        (state: RootState) => state.GlobalLoader
    )

    const bellshake = keyframes`
    0% { transform: rotate(0); }
    25% { transform: rotate(8deg); }
    50% { transform: rotate(-8deg); }
    45% { transform: rotate(6deg); }
    60% { transform: rotate(-6deg); }
    75% { transform: rotate(4deg); }
    85% { transform: rotate(-4deg); }
    92% { transform: rotate(2deg); }
    100% { transform: rotate(0); }
`

    const animation = `${bellshake} .5s cubic-bezier(.36,.07,.19,.97) both`

    const darkModeEnable = () => {
        dispatch(setDarkMode(!globalState.darkMode))
    }

    useEffect(() => {
        if (apiPending === false) {
            setShowLoader(true)
            if (timeout.current) {
                clearTimeout(timeout.current)
            }
            timeout.current = setTimeout(() => {
                setShowLoader(false)
            }, parseInt(REACT_APP_TIMEOUT as string, 10))
        }
        return () => {
            timeout.current && clearTimeout(timeout.current)
        }
    }, [pathname])

    useEffect(() => {
        if (
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
            dispatch(setDarkMode(true))
        }

        window
            .matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', (event) => {
                dispatch(setDarkMode(event.matches))
            })
    }, [])

    return (
        <CssWrapper>
            <Flex
                flexDirection='row'
                w='100vw'
                minH='100vh'
                h={pathname === `/scheduling` ? '100%' : '100vh'}
                justify='left'
                align='top'
                className={`commonContainer ${
                    menuCollapse ? 'nav-collapse' : ''
                }`}
                alignSelf='flex-start'
                position='relative'
                bg={
                    globalState.darkMode
                        ? 'mainDarkModeBackgroundColor'
                        : 'mainLightModeBackgroundColor'
                }
            >
                <Box
                    position='fixed'
                    bg='boxShadowDeepBlackColor'
                    height='100vh'
                    width='100vw'
                    opacity={menuCollapse ? '1' : '0'}
                    display={{
                        base: `${menuCollapse ? 'block' : 'none'}`,
                        lg: 'none'
                    }}
                    onClick={setmenuCollapse.toggle}
                    // transition={{ base: '0.5s ease', lg: 'unset' }}
                    zIndex='111'
                >
                    Overlay
                </Box>

                <Box
                    className='navigation'
                    // w={menuCollapse ? '70px' : '270px'}
                    w={{
                        base: `${menuCollapse ? '270px' : '0px'}`,
                        lg: `${menuCollapse ? '70px' : '270px'}`
                    }}
                    h='100vh'
                    pos='fixed'
                    left='0'
                    zIndex={6}
                    background='lfgLightBlue'
                    // transition='width 0.6s ease'
                    overflow={menuCollapse ? 'visible' : 'hidden'}
                    transition='0.5s ease all'
                    borderRight={`1px solid ${
                        globalState.darkMode
                            ? 'var(--chakra-colors-darkModeBordercolor)'
                            : 'var(--chakra-colors-veryLightBlueColor)'
                    }`}
                >
                    <Box
                        pos='relative'
                        as='aside'
                        className='sidebar'
                        w={['100%', '100%', '100%']}
                        h='100%'
                        display='flex'
                        flexDirection='column'
                        boxShadow={{
                            base: 'rgb(0 0 0 / 15%) 7.4px 7.4px 7.2px',
                            lg: 'none'
                        }}
                        bg={
                            globalState.darkMode
                                ? 'mainDarkModeDeepBackgroundColor'
                                : 'mainLightModeBackgroundColor'
                        }
                    >
                        <Box
                            h={{ base: '55px', lg: '70px' }}
                            boxShadow='-3px 3px 16px var(--chakra-colors-boxShadowLightBlackColor)'
                            bg={
                                globalState.darkMode
                                    ? 'mainDarkModeDeepBackgroundColor'
                                    : 'mainLightModeBackgroundColor'
                            }
                            borderBottom={`1px solid ${
                                globalState.darkMode
                                    ? 'var(--chakra-colors-darkModeBordercolor)'
                                    : 'var(--chakra-colors-veryLightBlueColor)'
                            }`}
                        >
                            <Box
                                className='logo'
                                p={{
                                    base: '42px 12px 12px 15px',
                                    lg: '57px 12px 12px 15px'
                                }}
                                transition='all 0.4s ease-in-out'
                                pos='relative'
                            >
                                <Image
                                    srcSet={
                                        globalState.darkMode
                                            ? '/images/lfg_logo_dark.svg, lfg_logo_dark.png'
                                            : '/images/lfg_logo_light.svg, lfg_logo_light.png'
                                    }
                                    alt='logo'
                                    alignSelf='center'
                                    pos='absolute'
                                    w='116px'
                                    h={{ base: '34px', lg: '45px' }}
                                    className='logo-img'
                                    maxWidth='unset'
                                    top='12px'
                                />
                            </Box>
                        </Box>
                        <Wrap
                            display='block'
                            flexDirection='row'
                            fontWeight='500'
                            fontSize={{ base: '1.4rem', lg: '1.6rem' }}
                            color={
                                globalState.darkMode
                                    ? 'white'
                                    : 'leftPanelFontColor'
                            }
                            p='20px 10px'
                            h={{
                                base: 'calc(100vh - 100px)',
                                lg: 'calc(100vh - 200px)'
                            }}
                            className='navHolder'
                            transition='all 0.4s ease-in-out'
                            sx={{
                                '&.navHolder': {
                                    overflow: 'hidden'
                                },
                                '&.navHolder:hover': {
                                    paddingRight: '9px',
                                    overflowY: 'scroll'
                                },
                                '&:hover::-webkit-scrollbar': {
                                    width: '4px',
                                    background: 'var(--chakra-colors-gray-200)'
                                },

                                '&:hover::-webkit-scrollbar-thumb': {
                                    background: 'var(--chakra-colors-gray-300)',
                                    borderRadius: '4px',
                                    transition: 'all 0.3s ease-in-out'
                                },
                                '& .chakra-wrap__list .chakra-wrap__listitem': {
                                    width: '243px'
                                },
                                '& .chakra-collapse .chakra-wrap__list .chakra-wrap__listitem':
                                    {
                                        width: '100%'
                                    },

                                '.chakra-wrap__listitem': {
                                    borderRadius: '8px',
                                    transition: 'width 0.3s ease'
                                },
                                '.chakra-wrap__listitem > a': {
                                    textDecoration: 'none'
                                },
                                '.chakra-wrap__listitem > a:hover': {
                                    bg: 'var(--chakra-colors-lfgMainBlue)',
                                    color: 'var(--chakra-colors-white)'
                                },
                                '.chakra-wrap__listitem > a.active': {
                                    bg: 'var(--chakra-colors-lfgMainBlue)',
                                    color: 'var(--chakra-colors-white)'
                                },
                                '.chakra-wrap__listitem > a > i > svg:hover': {
                                    color: 'var(--chakra-colors-white)'
                                },
                                '.chakra-wrap__listitem > a[aria-current=page]>i>svg':
                                    {
                                        color: 'var(--chakra-colors-white)'
                                    },
                                '.chakra-accordion__button': {
                                    width: '243px',
                                    borderRadius: '8px'
                                },
                                '.chakra-accordion__button:hover': {
                                    background: 'rgb(215, 229, 244)',
                                    color: 'leftPanelFontColor'
                                },
                                '.chakra-accordion__button:hover > a ': {
                                    borderRight: '1px solid',
                                    borderColor: '#f1f7fd',
                                    transition: '0.5s ease all'
                                },
                                '.chakra-collapse .chakra-wrap__listitem .chakra-accordion__button':
                                    {
                                        width: '100%',
                                        borderRadius: '8px'
                                    },
                                '.chakra-collapse .chakra-wrap__listitem .chakra-accordion__button:hover':
                                    {
                                        background: 'rgb(215, 229, 244)'
                                    },
                                '.chakra-accordion__button > a': {
                                    textDecoration: 'none'
                                },
                                '.chakra-collapse .chakra-wrap__listitem .chakra-collapse .chakra-accordion__panel .chakra-wrap__list > a':
                                    {
                                        textDecoration: 'none'
                                    },
                                '.chakra-collapse .chakra-wrap__listitem .chakra-collapse .chakra-accordion__panel .chakra-wrap__list > a >span':
                                    {
                                        textDecoration: 'none',
                                        fontSize: '1.4rem'
                                    },
                                '.chakra-collapse .chakra-wrap__listitem .chakra-collapse .chakra-accordion__panel .chakra-wrap__list > a:hover ':
                                    {
                                        background:
                                            'var(--chakra-colors-lfgMainBlue)',
                                        color: 'white !important'
                                    },
                                '.chakra-accordion__button[aria-expanded="true"]':
                                    {
                                        width: '243px',
                                        bg: '#D7E5F4',
                                        borderRadius: '10px 10px 0px 0px',
                                        color: `${
                                            globalState.darkMode
                                                ? 'leftPanelFontColor'
                                                : 'leftPanelFontColor'
                                        }`
                                    },
                                '.chakra-accordion__item': {
                                    border: 'none'
                                },
                                '.chakra-wrap__listitem .chakra-accordion button + svg':
                                    {
                                        position: 'absolute',
                                        right: '10px',
                                        transition: 'opacity 0.4s ease 0s',
                                        opacity: '0.5'
                                    },
                                '& .chakra-accordion .chakra-accordion__item .chakra-collapse .chakra-accordion__panel .chakra-wrap .chakra-wrap__list .chakra-wrap__listitem':
                                    {
                                        width: '90%',
                                        margin: '0 auto'
                                    },
                                '& .chakra-accordion .chakra-accordion__item .chakra-collapse .chakra-accordion__panel .chakra-wrap .chakra-wrap__list .chakra-wrap__listitem > a':
                                    {
                                        mb: '5px',
                                        color: `${
                                            globalState.darkMode
                                                ? 'whileftPanelFontColorte'
                                                : 'leftPanelFontColor'
                                        }`
                                    },
                                '& .chakra-accordion .chakra-accordion__item .chakra-collapse .chakra-accordion__panel .chakra-wrap .chakra-wrap__list .chakra-wrap__listitem > a:hover':
                                    {
                                        color: `${
                                            globalState.darkMode
                                                ? 'white'
                                                : 'white'
                                        }`
                                    },
                                '& .chakra-accordion .chakra-accordion__item .chakra-collapse .chakra-accordion__panel .chakra-wrap .chakra-wrap__list .chakra-wrap__listitem > a.active':
                                    {
                                        color: `${
                                            globalState.darkMode
                                                ? 'white'
                                                : 'white'
                                        }`
                                    },
                                '@media screen and (max-width: 1023px)': {
                                    '&.navHolder': {
                                        overflowY: 'auto',
                                        overflowX: 'hidden'
                                    }
                                }
                            }}
                        >
                            <Navigation
                                menuCollapse={menuCollapse}
                                setmenuCollapse={setmenuCollapse}
                            />
                        </Wrap>
                        <Box
                            display={{ base: 'none', lg: 'flex' }}
                            alignItems='flex-end'
                            height='60px'
                            position='fixed'
                            bottom='0'
                            pl={menuCollapse ? '15px' : '30px'}
                            pb='20px'
                            
                            transition='padding 0.5s ease'
                        >
                            <AsideProfileMenu />
                        </Box>
                    </Box>
                </Box>
                <Box
                    ml={{
                        base: `${menuCollapse ? '0px' : '0px'}`,
                        lg: `${menuCollapse ? '70px' : '270px'}`
                    }}
                    width='100%'
                    transition='margin 0.6s ease'
                >
                    <Box
                        className='header'
                        h={{ base: '55px', lg: '70px' }}
                        pos={pathname === `/scheduling` ? 'sticky' : 'fixed'}
                        top='0'
                        zIndex='99'
                        left={{
                            base: `${menuCollapse ? '0px' : '0px'}`,
                            lg: `${menuCollapse ? '70px' : '270px'}`
                        }}
                        transition='all 0.6s ease'
                        width={{
                            base: `${menuCollapse ? '100%' : '100%'}`,
                            lg: `${
                                menuCollapse
                                    ? 'calc(100% - 70px)'
                                    : `${
                                          pathname === `/scheduling`
                                              ? 'calc(100% - 10px)'
                                              : 'calc(100% - 270px)'
                                      }`
                            }`
                        }}
                    >
                        <Box
                            pos='relative'
                            h={{ base: '55px', lg: '70px' }}
                            bg={
                                globalState.darkMode
                                    ? 'mainDarkModeDeepBackgroundColor'
                                    : 'mainLightModeBackgroundColor'
                            }
                            p={{ base: '7px 20px', lg: '15px 20px' }}
                            borderBottom={`1px solid ${
                                globalState.darkMode
                                    ? 'var(--chakra-colors-darkModeBordercolor)'
                                    : 'var(--chakra-colors-veryLightBlueColor)'
                            }`}
                            boxShadow={
                                globalState.darkMode
                                    ? 'none'
                                    : '0px 0px 20px var(--chakra-colors-gray-200)'
                            }
                            display='flex'
                            justifyContent='space-between'
                            sx={{
                                '& > .progressBar': {
                                    bg: `${
                                        globalState.darkMode
                                            ? 'breadcamDarkModeBgColor'
                                            : 'var(--chakra-colors-gray-100)'
                                    }`
                                },
                                '& > .progressBar .css-h5ends': {}
                            }}
                        >
                            <Box
                                pos='absolute'
                                left='20px'
                                top={{ base: '17px', lg: '25px' }}
                                cursor='pointer'
                                w='38px'
                                h='38px'
                                textAlign='center'
                                color={
                                    globalState.darkMode
                                        ? 'lfgMainLightBlue'
                                        : 'lfgMainBlue'
                                }
                                onClick={setmenuCollapse.toggle}
                            >
                                <svg
                                    className='icon'
                                    width='20px'
                                    height='21px'
                                    display='inline-block'
                                >
                                    <use href='/images/icons.svg#icon-navmenu-collapse' />
                                </svg>
                            </Box>
                            <Box
                                p='0 0 0 38px'
                                display={{ base: 'flex', lg: 'none' }}
                                alignItems='center'
                            >
                                {breadCrumbs.length > 1 ? (
                                    <svg
                                        className='icon'
                                        width='13px'
                                        height='22px'
                                        display='inline-block'
                                        onClick={() => navigate(-1)}
                                    >
                                        <use href='/images/icons.svg#back-button' />
                                    </svg>
                                ) : (
                                    <Image
                                        srcSet='/images/logo-collapse.png'
                                        height={{ base: '30px', lg: '40px' }}
                                        width={{ base: '30px', lg: '40px' }}
                                        onClick={() => navigate('/')}
                                    />
                                )}
                            </Box>
                            <Box
                                p='0 0 0 38px'
                                display={{ base: 'none', lg: 'block' }}
                            >
                                <Heading
                                    as='h1'
                                    fontSize='2rem'
                                    fontWeight='600'
                                    color={
                                        globalState.darkMode
                                            ? 'lfgMainLightBlue'
                                            : 'lfgMainBlue'
                                    }
                                    textTransform='capitalize'
                                >
                                    {pathname
                                        .split('/')[1]
                                        .split('-')
                                        .join(' ')
                                        .trim()}
                                </Heading>
                                <Breadcrumb
                                    color='var(--chakra-colors-breadCrumbTextColor)'
                                    fontSize='1.3rem'
                                    fontWeight='500'
                                    w={{ base: '100%', lg: '100%' }}
                                    separator='/'
                                >
                                    {breadCrumbs.map(
                                        (el: {
                                            name: string
                                            link: string
                                        }) => (
                                            <BreadcrumbItem
                                                isCurrentPage={!el.link}
                                                key={`${el.link}-${el.name}`}
                                                mb='10px'
                                                wordBreak='keep-all'
                                            >
                                                <BreadcrumbLink
                                                    as={Link}
                                                    to={el.link}
                                                >
                                                    {el.name}
                                                </BreadcrumbLink>
                                            </BreadcrumbItem>
                                        )
                                    )}
                                </Breadcrumb>
                            </Box>

                            <Box
                                width='auto'
                                display={{ base: 'inline-block', lg: 'none' }}
                                position='absolute'
                                right={{ base: '15px', lg: '10px' }}
                                top={{ base: '20px', lg: '32px' }}
                            >
                                <AsideProfileMenu />
                            </Box>

                            <Box display={{ base: 'block', lg: 'none' }}>
                                {pathname === `/cases` && (
                                    <Link to='/cases/add'>
                                        <Button
                                            className='save_btn responsive_Btn'
                                            borderRadius='50px !important'
                                            pos='absolute'
                                            right='155px'
                                            top={{ base: '13px', lg: 'unset' }}
                                        >
                                            <AddIcon />
                                        </Button>
                                    </Link>
                                )}

                                {pathname === `/clients` && (
                                    <Link to='/clients/add'>
                                        <Button
                                            className='save_btn responsive_Btn'
                                            borderRadius='50px !important'
                                            pos='absolute'
                                            right='155px'
                                            top={{ base: '13px', lg: 'unset' }}
                                        >
                                            <AddIcon />
                                        </Button>
                                    </Link>
                                )}

                                {pathname === `/legal-firms` && (
                                    <Link to='/legal-firms/add'>
                                        <Button
                                            className='save_btn responsive_Btn'
                                            borderRadius='50px !important'
                                            pos='absolute'
                                            right='155px'
                                            top={{ base: '13px', lg: 'unset' }}
                                        >
                                            <AddIcon />
                                        </Button>
                                    </Link>
                                )}

                                {pathname === `/staff-management` && (
                                    <Link to='/staff-management/add-staff'>
                                        <Button
                                            className='save_btn responsive_Btn'
                                            borderRadius='50px !important'
                                            pos='absolute'
                                            right='155px'
                                            top={{ base: '13px', lg: 'unset' }}
                                        >
                                            <AddIcon />
                                        </Button>
                                    </Link>
                                )}

                                {pathname === `/requests` && (
                                    <Link to='/requests/add'>
                                        <Button
                                            className='save_btn responsive_Btn'
                                            borderRadius='50px !important'
                                            pos='absolute'
                                            right='155px'
                                            top={{ base: '13px', lg: 'unset' }}
                                        >
                                            <AddIcon />
                                        </Button>
                                    </Link>
                                )}
                            </Box>

                            <Box
                                className='icon_notification'
                                pos='absolute'
                                right={{ base: '70px', lg: '60px' }}
                                bottom={{ base: '16px', lg: '25px' }}
                                cursor='pointer'
                            >
                                <FormControl display='flex' alignItems='center'>
                                    <Switch
                                        id='darkModeEnable'
                                        onChange={darkModeEnable}
                                        size='lg'
                                        isChecked={globalState.darkMode}
                                    />
                                </FormControl>
                            </Box>

                            <Box
                                className='icon_notification'
                                pos='absolute'
                                color={
                                    globalState.darkMode
                                        ? 'white'
                                        : 'var(--chakra-colors-bodyFontColor)'
                                }
                                right={{ base: '120px', lg: '20px' }}
                                bottom={{ base: '15px', lg: '25px' }}
                                cursor='pointer'
                                _hover={{
                                    animation: `${animation}`,
                                    transformOrigin: 'top'
                                }}
                            >
                                <svg
                                    className='icon'
                                    width='20px'
                                    height='21px'
                                >
                                    <use href='/images/icons.svg#icon-notification' />
                                </svg>
                            </Box>

                            {(apiPending || showLOader) && (
                                <Progress
                                    size='xs'
                                    isIndeterminate
                                    className='progressBar'
                                    w='calc(100% + 20px)'
                                    h='1px'
                                    colorScheme='blue'
                                    position='absolute'
                                    left='0'
                                    bottom='0'
                                />
                            )}
                        </Box>
                    </Box>
                    <Box
                        ref={portalId}
                        w='100%'
                        p={
                            pathname === `/scheduling`
                                ? '30px 20px 40px'
                                : {
                                      base: '70px 15px 40px',
                                      lg: '100px 20px 20px'
                                  }
                        }
                        bg={
                            globalState.darkMode
                                ? 'mainDarkModeBackgroundColor'
                                : 'mainLightModeBackgroundColor'
                        }
                    />

                    <Flex
                        display={{ base: 'none', lg: 'flex' }}
                        left={{
                            base: `${menuCollapse ? '0px' : '0px'}`,
                            lg: `${menuCollapse ? '70px' : '270px'}`
                        }}
                        transition='all 0.6s ease'
                        width={{
                            base: `${menuCollapse ? '100%' : '100%'}`,
                            lg: `${
                                menuCollapse
                                    ? 'calc(100% - 70px)'
                                    : `${
                                          pathname === `/scheduling`
                                              ? 'calc(100% - 10px)'
                                              : 'calc(100% - 270px)'
                                      }`
                            }`
                        }}
                        bg={
                            globalState.darkMode
                                ? 'mainDarkModeDeepBackgroundColor'
                                : 'mainLightModeBackgroundColor'
                        }
                        pos={pathname === `/scheduling` ? 'sticky' : 'fixed'}
                        bottom='0'
                        height='33px'
                        lineHeight='3.3rem'
                        boxShadow={
                            globalState.darkMode
                                ? 'none'
                                : '0px 0px 20px var(--chakra-colors-gray-200)'
                        }
                        justifyContent='space-between'
                        p='0 20px'
                        zIndex='99'
                    >
                        <Box
                            color={
                                globalState.darkMode
                                    ? 'white'
                                    : 'var(--chakra-colors-lfgGreycolor)'
                            }
                            fontSize='1.3rem'
                            fontWeight='400'
                            flex='0 0 50%'
                        >
                            &copy; Copyright {new Date().getFullYear()}{' '}
                            <Text
                                as='strong'
                                color='lfgMainBlue'
                                fontWeight='500'
                            >
                                Legal Fulfillment Group.
                            </Text>{' '}
                            All Rights Reserved.
                        </Box>

                        <Box
                            flex='0 0 50%'
                            textAlign='right'
                            color={
                                globalState.darkMode ? 'white' : 'bodyFontColor'
                            }
                            fontSize='1rem'
                            sx={{
                                '& span': {
                                    color: 'lfgMainBlue',
                                    fontWeight: '500',
                                    fontSize: '1.1rem'
                                }
                            }}
                        >
                            Powered by{' '}
                            <Anchor
                                href='https://massoftind.com/'
                                isExternal
                                textDecoration='none'
                                color='lfgMainBlue'
                                fontWeight='500'
                            >
                                Mass Software Solutions
                            </Anchor>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </CssWrapper>
    )
}

export default Layout
