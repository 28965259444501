import { createApi } from '@reduxjs/toolkit/dist/query/react'
import createBaseQuery from '../../../utilities/createBaseQuery'
import { ActivityRequestType, ActivityResponseType } from '../interfaces'

const activityApi = createApi({
    reducerPath: 'recent-activity',
    baseQuery: createBaseQuery(),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getActivity: builder.query<ActivityResponseType, ActivityRequestType>({
            query: (data) => ({
                url: `activity/list`,
                payload: data,
                method: 'POST'
            })
        })
    })
})

export default activityApi
export const { useGetActivityQuery } = activityApi
