import { createSlice } from '@reduxjs/toolkit'
import { updateLogoutStatus } from '../../authentication/slices'

const initialState = {
    name: '',
    type: '',
    clientName: '',
    providerName: '',
    isAddRushClicked: {}
}
const RequestSlice = createSlice({
    name: 'requestReducer',
    initialState,
    reducers: {
        updateName: (state, action) => {
            state.name = action.payload.name ? action.payload.name : state.name
            state.type = action.payload.type ? action.payload.type : state.type
            state.clientName = action.payload.clientName
                ? action.payload.clientName
                : state.clientName
            state.providerName = action.payload.providerName
                ? action.payload.providerName
                : state.providerName
        },
        updateAddRushRequest: (state, action) => {
            state.isAddRushClicked = {
                ...state.isAddRushClicked,
                [action.payload.id]: action.payload.value
            }
            return state
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateLogoutStatus, () => initialState)
    }
})

export const { updateName ,updateAddRushRequest } = RequestSlice.actions
export default RequestSlice
