import { createApi } from '@reduxjs/toolkit/dist/query/react'
import createBaseQuery from '../../../utilities/createBaseQuery'

import {
    GetAgentAuthResponseType,
    GetAgentAuthListRequestType,
    DownloadAgentAuthResponseType,
    DownloadAgentAuthRequestType,
    DeleteAgentAuthResponseType,
    DeleteAgentAuthRequestType,
    ToggleAgentAuthStatusResponseType,
    ToggleAgentAuthStatusRequestType,
    SetDefaultAgentAuthResponseType,
    SetDefaultAgentAuthRequestType
} from '../interfaces/index'

const agentAuthApi = createApi({
    reducerPath: 'agentAuth',
    baseQuery: createBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: ['agentAuthList'],
    endpoints: (builder) => ({
        getAgentAuthList: builder.query<
            GetAgentAuthResponseType,
            GetAgentAuthListRequestType
        >({
            query: (data) => ({
                url: 'firm/agentAuthList',
                payload: data,
                method: 'POST'
            }),
            keepUnusedDataFor: 0.0001,
            providesTags: ['agentAuthList']
        }),
        downloadAgentAuth: builder.mutation<
            DownloadAgentAuthResponseType,
            DownloadAgentAuthRequestType
        >({
            query: (data) => ({
                url: 'firm/downloadAgentAuth',
                payload: data,
                method: 'POST'
            })
        }),
        deleteAgentAuth: builder.mutation<
            DeleteAgentAuthResponseType,
            DeleteAgentAuthRequestType
        >({
            query: (data) => ({
                url: 'firm/deleteAgentAuth',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['agentAuthList']
        }),
        toggleAgentAuthStatus: builder.mutation<
            ToggleAgentAuthStatusResponseType,
            ToggleAgentAuthStatusRequestType
        >({
            query: (data) => ({
                url: 'firm/agentAuthStatusChange',
                payload: data,
                method: 'POST'
            })
        }),
        setDefaultAgentAuth: builder.mutation<
            SetDefaultAgentAuthResponseType,
            SetDefaultAgentAuthRequestType
        >({
            query: (data) => ({
                url: 'firm/makeDefaultAgentAuth',
                payload: data,
                method: 'POST'
            })
        })
    })
})

export default agentAuthApi

export const {
    useGetAgentAuthListQuery,
    useDownloadAgentAuthMutation,
    useDeleteAgentAuthMutation,
    useToggleAgentAuthStatusMutation,
    useSetDefaultAgentAuthMutation
} = agentAuthApi
