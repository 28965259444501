import { createApi } from '@reduxjs/toolkit/dist/query/react'
import createBaseQuery from '../../../utilities/createBaseQuery'
import {
    AddProviderRequestType,
    AddProviderResponseType,
    DeleteProviderRequestType,
    DeleteProviderResponseType,
    EditNotesRequestType,
    EditNotesResponseType,
    EditProviderRequestType,
    EditProviderResponseType,
    GetCopyServiceDropdownRequestType,
    GetCopyServiceDropdownResponseType,
    GetFilterTypeDropdownRequestType,
    GetFilterTypeDropdownResponseType,
    GetLocationTagDropdownRequestType,
    GetLocationTagDropdownResponseType,
    GetMethodDropdownRequestType,
    GetMethodDropdownResponseType,
    GetProviderListRequestType,
    GetProviderListResponseType,
    ViewProviderRequestType,
    ViewProviderResponseType,
    GetParentProviderRequestType,
    GetParentProviderResponseType,
    EditRelationshipNotesRequestType,
    EditRelationshipNotesResponseType,
    AddParentRelationshipRequestType,
    AddParentRelationshipResponseType,
    ViewParentRelationshipRequestType,
    ViewParentRelationshipResponseType,
    EditParentRelationshipRequestType,
    EditParentRelationshipResponseType,
    FilterProviderRequestType,
    FilterProviderResponseType,
    FilterProviderLocationByIdRequestType,
    FilterProviderLocationByIdResponseType,
    GetChildProviderRequestType,
    GetChildProviderResponseType,
    AddChildRelationshipRequestType,
    AddChildRelationshipResponseType,
    ViewChildRelationshipRequestType,
    ViewChildRelationshipResponseType,
    EditChildRelationshipRequestType,
    EditChildRelationshipResponseType,
    TransferLocationRequestType,
    TransferLocationResponseType,
    FilterProviderTypeWithAddressResponseType,
    FilterProviderTypeWithAddressRequestType
} from '../interfaces'

const providersApi = createApi({
    reducerPath: 'providers',
    baseQuery: createBaseQuery(),
    refetchOnMountOrArgChange: true,
    tagTypes: ['providers', 'parentProviders', 'childProviders'],
    endpoints: (builder) => ({
        getProviders: builder.query<
            GetProviderListResponseType,
            GetProviderListRequestType
        >({
            query: (data) => ({
                url: 'provider/list',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['providers']
        }),
        deleteProvider: builder.mutation<
            DeleteProviderResponseType,
            DeleteProviderRequestType
        >({
            query: (data) => ({
                url: 'provider/delete',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['providers']
        }),
        editNotes: builder.mutation<
            EditNotesResponseType,
            EditNotesRequestType
        >({
            query: (data) => ({
                url: 'provider/editNote',
                payload: data,
                method: 'POST'
            })
        }),
        getFilterTypeDropdown: builder.query<
            GetFilterTypeDropdownResponseType,
            GetFilterTypeDropdownRequestType
        >({
            query: (data) => ({
                url: 'provider/filterRequestType',
                payload: data,
                method: 'POST'
            })
        }),
        getLocationTagDropdown: builder.query<
            GetLocationTagDropdownResponseType,
            GetLocationTagDropdownRequestType
        >({
            query: (data) => ({
                url: 'provider/locationTag',
                payload: data,
                method: 'POST'
            })
        }),
        getCopyServiceDropdown: builder.query<
            GetCopyServiceDropdownResponseType,
            GetCopyServiceDropdownRequestType
        >({
            query: (data) => ({
                url: 'copy-service/filter',
                payload: data,
                method: 'POST'
            })
        }),
        getMethodDropdown: builder.query<
            GetMethodDropdownResponseType,
            GetMethodDropdownRequestType
        >({
            query: (data) => ({
                url: 'provider/filterDefaultMethod',
                payload: data,
                method: 'POST'
            })
        }),
        addProvider: builder.mutation<
            AddProviderResponseType,
            AddProviderRequestType
        >({
            query: (data) => ({
                url: 'provider/add',
                payload: data,
                method: 'POST'
            }),
            invalidatesTags: ['providers']
        }),
        viewProvider: builder.query<
            ViewProviderResponseType,
            ViewProviderRequestType
        >({
            query: (data) => ({
                url: 'provider/view',
                payload: data,
                method: 'POST'
            })
        }),
        editProvider: builder.mutation<
            EditProviderResponseType,
            EditProviderRequestType
        >({
            query: (data) => ({
                url: 'provider/edit',
                payload: data,
                method: 'POST'
            })
        }),
        getParentProviders: builder.query<
            GetParentProviderResponseType,
            GetParentProviderRequestType
        >({
            query: (data) => ({
                url: 'provider/parentProviderList',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['parentProviders']
        }),
        addParentRelationship: builder.mutation<
            AddParentRelationshipResponseType,
            AddParentRelationshipRequestType
        >({
            query: (data) => ({
                url: 'provider/addParentRelationship',
                payload: data,
                method: 'POST'
            })
        }),
        viewParentProvider: builder.query<
            ViewParentRelationshipResponseType,
            ViewParentRelationshipRequestType
        >({
            query: (data) => ({
                url: 'provider/viewParentRelationship',
                payload: data,
                method: 'POST'
            })
        }),
        editParentRelationship: builder.mutation<
            EditParentRelationshipResponseType,
            EditParentRelationshipRequestType
        >({
            query: (data) => ({
                url: 'provider/editParentRelationship',
                payload: data,
                method: 'POST'
            })
        }),
        filterProvider: builder.query<
            FilterProviderResponseType,
            FilterProviderRequestType
        >({
            query: (data) => ({
                url: 'provider/filter',
                payload: data,
                method: 'POST'
            })
        }),
        filterProviderLocationById: builder.query<
            FilterProviderLocationByIdResponseType,
            FilterProviderLocationByIdRequestType
        >({
            query: (data) => ({
                url: 'provider/filterProviderLocationByID',
                payload: data,
                method: 'POST'
            })
        }),
        getChildProviders: builder.query<
            GetChildProviderResponseType,
            GetChildProviderRequestType
        >({
            query: (data) => ({
                url: 'provider/childProviderList',
                payload: data,
                method: 'POST'
            }),
            providesTags: ['childProviders']
        }),
        addChildRelationship: builder.mutation<
            AddChildRelationshipResponseType,
            AddChildRelationshipRequestType
        >({
            query: (data) => ({
                url: 'provider/addChildRelationship',
                payload: data,
                method: 'POST'
            })
        }),
        viewChildProvider: builder.query<
            ViewChildRelationshipResponseType,
            ViewChildRelationshipRequestType
        >({
            query: (data) => ({
                url: 'provider/viewChildRelationship',
                payload: data,
                method: 'POST'
            })
        }),
        editChildRelationship: builder.mutation<
            EditChildRelationshipResponseType,
            EditChildRelationshipRequestType
        >({
            query: (data) => ({
                url: 'provider/editChildRelationship',
                payload: data,
                method: 'POST'
            })
        }),
        editRelationshipNotes: builder.mutation<
            EditRelationshipNotesResponseType,
            EditRelationshipNotesRequestType
        >({
            query: (data) => ({
                url: 'provider/editRelationshipNote',
                payload: data,
                method: 'POST'
            })
        }),
        transferLocation: builder.query<
            TransferLocationResponseType,
            TransferLocationRequestType
        >({
            query: (data) => ({
                url: 'provider/transferLocation',
                payload: data,
                method: 'POST'
            })
        }),
        filterProviderTypeWithAddress: builder.query<
            FilterProviderTypeWithAddressResponseType,
            FilterProviderTypeWithAddressRequestType
        >({
            query: (data) => ({
                url: 'provider/filterProviderTypeWithAddress',
                payload: data,
                method: 'POST'
            })
        })
    })
})

export default providersApi
export const {
    useGetProvidersQuery,
    useDeleteProviderMutation,
    useEditNotesMutation,
    useGetFilterTypeDropdownQuery,
    useGetLocationTagDropdownQuery,
    useGetCopyServiceDropdownQuery,
    useGetMethodDropdownQuery,
    useAddProviderMutation,
    useViewProviderQuery,
    useEditProviderMutation,
    useGetParentProvidersQuery,
    useGetChildProvidersQuery,
    useEditRelationshipNotesMutation,
    useAddParentRelationshipMutation,
    useViewParentProviderQuery,
    useEditParentRelationshipMutation,
    useFilterProviderQuery,
    useFilterProviderLocationByIdQuery,
    useAddChildRelationshipMutation,
    useViewChildProviderQuery,
    useEditChildRelationshipMutation,
    useTransferLocationQuery,
    useFilterProviderTypeWithAddressQuery
} = providersApi
